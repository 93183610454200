/*eslint-disable*/ 
import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import zh_CN from 'antd/es/locale/zh_CN';
import en_US from 'antd/es/locale/en_US';
import intl from 'react-intl-universal';
import { ConfigProvider, message, notification, Button } from 'antd';
import {emit} from './emit.js';
import store from 'store';
import memoryUtils from './utils/memoryUtils';
import MainRouter from './mainRouter';

import './App.css';
import { getChangeRate } from './api/ajaxMain.js';
import Loading from './components/mainCommon/loading.js';

// locale data
const locales = {
  "en-US": require('./locales/en-US.json'),
  "zh-CN": require('./locales/zh-CN.json'),
};

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      antdLang: zh_CN,
      first: true
    }
  }

  componentDidMount() {
    emit.on('change_language', lang => this.loadLocales(lang)); // 监听语言改变事件
    this.loadLocales(); // 初始化语言
    this.loadMode();
    this.loadCurrency();
    this.loadChangeRate();
    // this.loadPwa();
    message.config({
      maxCount: 1
    });
  }

  loadPwa () {
    message.success('loadPwa');
    let deferredPrompt;

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPrompt = e;

      const key = `open${Date.now()}`;
      const btn = (
        <Button 
          type="primary" 
          size="small" 
          onClick={() => { notification.close(key); deferredPrompt.prompt();}}>
          添加
        </Button>
      );
      notification.open({
        message: '添加网站到桌面',
        description:
          '点击按钮将在桌面添加 Skypool 的快捷方式',
        btn,
        key,
        onClose: close,
      });
    })

    window.addEventListener('appinstalled', (evt) => {
    })
  }

  async loadMode () {
    const mode = await store.get('mode');
    let root = document.documentElement;
    if (mode) {
      if (mode === 'DARK') {
        memoryUtils.mode = 'DARK';
        return root.className = 'night';
      }
      if (mode === 'LIGHT') {
        memoryUtils.mode = 'LIGHT';
        return root.className = 'light';
      } 
      // mode === 'AUTO'
      if ((window.matchMedia('(prefers-color-scheme: light)').matches) ||
          (window.matchMedia('(prefers-color-scheme: no-preference)').matches)) {
        // 当前系统主题为白天模式
        memoryUtils.mode = 'LIGHT';
        return root.className = 'light';
      }
      memoryUtils.mode = 'DARK';
      return root.className = 'night';
    }
    if ((window.matchMedia('(prefers-color-scheme: light)').matches) ||
        (window.matchMedia('(prefers-color-scheme: no-preference)').matches)) {
      // 当前系统主题为白天模式
      memoryUtils.mode = 'LIGHT';
      root.className = 'light';
    } else {
      // 当前系统主题为夜间模式
      memoryUtils.mode = 'DARK';
      root.className = 'night';
    }
    store.set('mode', 'AUTO');
  }

  async loadCurrency () {
    let currency = await store.get('currency');
    
    if (currency) {
      if (currency !== 'USD' && currency !== 'CNY') {
        currency = 'CNY';
      }
      return memoryUtils.currency = currency;
    }
    currency = 'CNY';
    store.set('currency', currency);
  }

  async getChangeRateFunc () {
    try {
      const rate = (await getChangeRate()).data.data || 6.7;
      return rate;
    } catch {
      message.error('获取汇率失败');
    }
  }

  async loadChangeRate () {
    const rate = await this.getChangeRateFunc();
    memoryUtils.USD_CNY = rate;
  }

  async loadLocales (lang) {
    if (!lang) {
      const storeSkypool = await store.get('skypool');
      if (storeSkypool && storeSkypool.lang) {
        lang = storeSkypool.lang;
      } else {
        const originalLang = window.navigator.language;
        if (originalLang === 'zh-CN') {
          lang = 'zh-CN';
        } else {
          lang = 'en-US';
        }
      }
    }
    await store.set('skypool', { 'lang': lang });
    intl.init({
      currentLocale: lang,  // 设置初始语音
      locales,
    }).then(() => {
      this.setState({
        antdLang: lang === 'zh-CN' ? 'zh-CN': 'en-US',
        first: false
      });
    });
  }

  render () {
    
    if (this.state.first) {
      // return <Loading />
      return <></>
    }

    return (
      <div className='app'>      
        <ConfigProvider locale={this.state.antdLang}>
          <BrowserRouter>
            <Switch>
              <Route path='/' component={MainRouter} />
            </Switch>
          </BrowserRouter>
        </ConfigProvider>
      </div>
    )
  }
}
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import store from 'store';
import { CommonUtils } from '../../utils/commonUtils';
import { message, AutoComplete, Modal } from 'antd';
import nimiqLogo from '../../assets/nimiq-nim-logo.svg';
import rvnLogo from '../../assets/ravencoin-rvn-logo.svg';
import ergLogo from '../../assets/erg.jpeg';
import xmrLogo from '../../assets/monero-xmr-logo.svg';
import etcLogo from '../../assets/etc.png';
import '../../styles/searchPage/searchPage.css';
import {
  RestOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
const { confirm } = Modal;
/*
0x48c164c3b8530ba24101d472652363890fdcb30a27cffe03c63e271dcd13c2a6
4DSQMNzzq46N1z2pZWAVdeA6JvUL9TCB2bnBiA3ZzoqEdYJnMydt5akCa3vtmapeDsbVKGPFdNkzqTcJS8M8oyK7WGivRjPA8cgDLiMzQT
NQ51 Q0AG EV0X 6259 9MSD L3FF PVFG 284A 78VU
NQ38 K2NG DYCQ 5CQQ SRGX T76M 8R94 8RP8 PGDM
RHuZjRbxddzyfCpk7ZZFTnA8hXfjVrs1M8
86Y8TKU2gojcbjkauA3pAe6kB5G2vrq6YCZAe84Lip6iQPnzwfo5UZCjW3CDNe7N6HT5XCHT1qnqyapafMjagEgyLBbLkoB
42VDN3eTdQcas7dwGn3on7Mc1wwzJA6hdiAgzXJApMT43GkNgf5fnDRYMcg9vmU6hJdpVKot34LYcieMXK79RG8VBFfEdyW
0x1a7b96c0997bdd96774e93b5ee47daa70c13b5cbc76d8f4df833576555791467
85RSV9p2gocHMRR7Xe2HPwWimB88mLqPH1nqBePiFNQyQ3T7BYdUwj6YBP8BesrzdT9UoWsfzG4BsTQngXMvx7uGB6AuC75
9ecVhFXG3dnPX1coLxJEZqe62W7weHanavi9axnaNmtBNFZkAiQ
*/

function SearchBar ({ type, history }) {

  /* 保存的搜索记录 */
  const [ options, setOptions ] = useState([{ value: '' }]);

  /* 展示确认删除的弹出框 */
  const showConfirm = () => {
    confirm({
      title: intl.get('CONFIRM_TO_DELETE_HISTORY'),
      icon: <ExclamationCircleOutlined />,
      content: intl.get('CONFIRM_TO_DELETE_HISTORY_CONTENT'),
      onOk: onRemove,
      onCancel: () => {},
      okText: intl.get('CONFIRM'),
      cancelText: intl.get('CANCEL')
    });
  }

  const onRemove = async () => {
    if (type === 'main') {
      /* 如果是主页, 清空所有 */
      await store.set('addrs', []);
      return setOptions([]);
    }
    /* 如果是币种的页面, 只清空该币种的记录 */
    const addrStore = (await store.get('addrs'));
    const addrs = addrStore || [];
    const options = addrs.filter(item => type.toUpperCase() !== item.type && type.toUpperCase() !== item.coin);
    await store.set('addrs', options);
    setOptions([]);
  }

  /* 每个币种地址对应的校验规则 */
  const validationMap = {
    'nim': CommonUtils.parseNimAddrStrict,
    'rvn': CommonUtils.parseRvnAddrStrict,
    'xch': CommonUtils.parseXchAddrStrict,
    'erg': CommonUtils.parseErgAddrStrict,
    'xmr': CommonUtils.parseXmrAddrStrict,
    'etc': CommonUtils.parseEtcAddrStrict,
  }

  /* 主页点击搜索的回调 */
  const searchHome = async (f) => {
    /* 获取输入的地址 */
    const addrInput = document.getElementById('addr-input');
    const value = addrInput.value;
    if (!value) {
      return message.warning(intl.get('PLEASE_INPUT_ADDRESS'));
    }
    /* 获取搜索的记录 */
    const addrStore = await store.get('addrs');
    const addrs = addrStore || [];
    /* 检查是否是已知的币种 */
    const names = ['nim', 'rvn', 'xch', 'xmr', 'erg', 'etc'];
    for (const name of names) {
      const checkAddress = validationMap[name];
      const check = checkAddress(value);
      /* 如果地址符合规则 */
      if (check !== false) {
        /* 如果不存在于搜索记录中, 则添加 */
        if (!addrs.find(el => el.addr === check)) {
          addrs.push({ addr: check, type: name.toUpperCase() });
          store.set('addrs', addrs);
        } 
        /* 如果是 xmr, 路由名称应该叫 cpu */
        if (name === 'xmr') {
          return history.push(`/search/cpu#${check}`);
        }
        /* 跳转 */
        return history.push(`/search/${name}#${check}`);
      }
    }
    if (f === 'sp') message.warning(intl.get('INVALID_ADDRESS_2'));
    return;
  }

  /* 币种页面点击搜索的回调 */
  const searchCommon = async (f) => {
    /* 获取输入的地址 */
    const addrInput = document.getElementById('addr-input');
    const value = addrInput.value;
    if (!value) {
      return message.warning(intl.get('PLEASE_INPUT_ADDRESS'));
    }
    /* 获取搜索的记录 */
    const addrStore = await store.get('addrs');
    const addrs = addrStore || [];
    /* 获取地址校验函数 */
    const checkAddress = validationMap[type];
    const check = checkAddress(value);
    /* 如果地址符合规则 */
    if (check !== false) {
      /* 如果不存在于搜索记录中, 则添加 */
      if (!addrs.find(el => el.addr === check)) {
        addrs.push({ addr: check, type: type.toUpperCase() });
        store.set('addrs', addrs);
      } 
      /* 如果是 xmr, 路由名称应该叫 cpu */
      if (type === 'xmr') {
        return history.push(`/search/cpu#${check}`);
      }
      /* 跳转 */
      return history.push(`/search/${type}#${check}`);
    }
    if (f === 'sp') message.warning(intl.get('INVALID_ADDRESS_2'));
    return;
  }

  const logoMap = {
    'NIM': nimiqLogo,
    'RVN': rvnLogo,
    'ERG': ergLogo,
    'XMR': xmrLogo,
    'ETC': etcLogo,
  }

  /* 渲染单条搜索记录 */
  const renderItem = (addr, type) => ({
    value: addr === 'remove' ? '' : addr,
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        { type !== 'remove' &&
          <img  alt='logo' style={{height: '18px', margin: '0 4px'}} src={logoMap[type]} />
        }
        { type === 'remove' &&
          <RestOutlined style={{fontSize: '16px', height: 'min-content', margin: '0px 5px'}} />
        }
        { type !== 'remove' && type + ': '}
        { addr === 'remove' ? intl.get('CLEAR_HISTORY') : addr }
      </div>
    ),
  });

  /* 将 store 中存储的记录填充到 options 中 */
  const processData = async () => {
    const addrStore = (await store.get('addrs'));
    const addrs = addrStore || [];
    const options = [];
    let count = 0;
    /* 如果是主页, 全部加进 options */
    if (type === 'main') {
      for (const addrItem of addrs) {
        options.push(renderItem(addrItem.addr, addrItem.type || addrItem.coin));
        count += 1;
      }
    } else {
      for (const addrItem of addrs) {
        if (type.toUpperCase() === addrItem.type || type.toUpperCase() === addrItem.coin) {
          options.push(renderItem(addrItem.addr, addrItem.type || addrItem.coin));
          count += 1;
        }
      }
    }
    /* options 不为空就加上清除记录的选项 */
    if (count > 0) options.push(renderItem('remove', 'remove'));
    setOptions(options);
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  const search = type === 'main' ? searchHome : searchCommon;

  return (
    <div 
      style={type === 'main' ? {} : {marginBottom: '40px'}} 
      className='main-panel-search'
    >
      <div className='search-bar'>
        <AutoComplete
          id='addr-input'
          className='autocomplete'
          options={options}
          onChange={(e, obj) => {
            if (obj.value === '') return showConfirm();
            if (obj.value) {
              const addrInput = document.getElementById('addr-input');
              addrInput.value = obj.value;
              search();
            } else {
              search();
            }
          }}
        >
          <input placeholder={intl.get('INPUT_ADDRESS_TO_VIEW_REWARDS')} />
        </AutoComplete>
      </div>
      <div onClick={() => search('sp')} className='search-btn'>{intl.get('VIEW')}</div>
    </div>
  )

} 

export default withRouter(SearchBar);
import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin
} from 'antd';
import intl from 'react-intl-universal';
import {CommonUtils} from '../../utils/commonUtils';
import memoryUtils from '../../utils/memoryUtils';

/*eslint-disable*/ 
export default function DataBoard ({nimGlobalData, priceData, change}) {

  const [loaded, setLoaded] = useState(false);
  const [globalData, setGlobalData] = useState({});
  const [poolData, setPoolData] = useState({});

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  const processData = () => {
    setGlobalData({
      hashrate: CommonUtils.toHashrate(nimGlobalData.globalHashrate),
      lastTime: (new Date().getTime() - nimGlobalData.timestamp) / 1000,
      height: nimGlobalData.height,
      difficulty: nimGlobalData.difficulty.toFixed(4),
      lastReward: (nimGlobalData.reward / 1e5).toFixed(2),
      price: priceData['NIM'].CNY,
      change: priceData['NIM'].CNY_CH,
      rate: 'PPLNS 1%',
      algo: intl.get('GRAPHICS_CARD')
    });
    setPoolData({
      hashrate: CommonUtils.toHashrate(nimGlobalData.hashrate),
      lastTime: (new Date().getTime() - nimGlobalData.poolTimestamp) / 1000,
      miner: nimGlobalData.addressNumber,
      machine: nimGlobalData.machineNumber,
      reward: (nimGlobalData.miningRate).toFixed(2),
    });
  }

  useEffect(() => {
    if (change === 0) processData();
    if (change === 0) setLoaded(true);
  }, [change]);

  if (!loaded) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  return (
    <>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('GLOBAL_DATA')} 
      </div>
      <div 
        className='data-board'
      >
        <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('GLOBAL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                {globalData.hashrate.slice(0, -4)} 
                  <span className='unit'> 
                  {globalData.hashrate.slice(-4)} 
                  </span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('LAST_BLOCK')}
                </div>
                <div className='data-board-item-figure'>
                {intl.options.currentLocale === 'en-US' ?
                CommonUtils.toUpdateTimeEn(globalData.lastTime):
                CommonUtils.toUpdateTime(globalData.lastTime)}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('DIFFICULTY')}
                </div>
                <div className='data-board-item-figure'>
                {globalData.difficulty}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('HEIGHT')}
                </div>
                <div className='data-board-item-figure'>
                {globalData.height}
                </div>
              </div>
            </Col>

          </Row>
        </div>
        <div className='divider-line sm-hide'></div>
        <div className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('BLOCK_REWARDS')}
                </div>
                <div className='data-board-item-figure'>
                  {globalData.lastReward}<span className='unit'> NIM</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('DAILY_REWARD')}
                </div>
                <div className='data-board-item-figure'>
                  {poolData.reward} <span className='unit'> NIM/K</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('PRICE')}{globalData.change >= 0 ? 
                  <span style={{color: 'green', marginLeft: '3px'}}>
                    {`+${globalData.change}`}%</span> 
                    : <span style={{color: 'red', marginLeft: '3px'}}>{globalData.change}%</span>}
                </div>
                <div className='data-board-item-figure'>
                { crySign + (parseFloat(globalData.price) / exchange).toFixed(3) }
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('HARDWARE')}
                </div>
                <div className='data-board-item-figure'>
                  {intl.get('GRAPHICS_CARD')}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('POOL_DATA')}
      </div>
      <div 
        className='data-board'
      >
        <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
        <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('POOL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                {poolData.hashrate.slice(0, -4)} 
                <span className='unit'> 
                {poolData.hashrate.slice(-4)}
                </span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('REWARD_UPDATE')}
                </div>
                <div className='data-board-item-figure'>
                {intl.options.currentLocale === 'en-US' ?
                CommonUtils.toUpdateTimeEn(poolData.lastTime):
                CommonUtils.toUpdateTime(poolData.lastTime)}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('ONLINE_MINERS')}
                </div>
                <div className='data-board-item-figure'>
                {poolData.miner}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('ONLINE_MACHINES')}
                </div>
                <div className='data-board-item-figure'>
                {poolData.machine}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='divider-line sm-hide'></div>
        <div className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('TRANSFER_TIME')}
                </div>
                <div className='data-board-item-figure'>
                  {intl.get('PER_SIX_HOURS')}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('PAY_THRESHOLD')}
                </div>
                <div className='data-board-item-figure'>
                10<span className='unit'> NIM</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('FEE_RATE')}
                </div>
                <div className='data-board-item-figure'>
                  <span className='unit'>PPLNS </span>
                  1%
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  )
}
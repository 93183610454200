import { message, Spin, Menu, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import {CommonUtils} from '../../utils/commonUtils';
import { Area } from '@ant-design/charts';
import {
  CloseOutlined,
  CheckOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import {
  getEtcBlocks,
  getEtcHistory
} from '../../api/ajaxMain';
import intl from 'react-intl-universal';

export default function GraphBoard () {

  const [currChart, setCurrChart] = useState('block');
  const [blockList, setBlockList] = useState([]);
  const [hashrateData, setHashrateData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [minerData, setMinerData] = useState([]);

  const changeChart = (e) => {
    setCurrChart(e.key);
  }

  const getResBlockList = async () => {
    try {
      const res = await getEtcBlocks();
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_BLOCK_RECORDS'));
      return {
        candidates: [],
        matured: [],
      }
    }
  }

  const getResChartData = async () => {
    try {
      const res = await getEtcHistory();
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GRAPH'));
      return [];
    }
  }

  const processBlockData = async () => {
    const resBlockList = await getResBlockList();
    var candidates = resBlockList.candidates || [];
    var immature = resBlockList.immature || [];
    var matured = resBlockList.matured || [];
    const blockList = [];
    let count = 0;
    for (const block of candidates) {
      if (count >= 30) break;
      blockList.push({
        height: block.height,
        hash: block.hash,
        time: block.timestamp,
        shares: block.shares,
        difficulty: block.difficulty,
        effort: block.shares / block.difficulty * 100,
        reward: (parseInt(block.reward || 0) / 1e18).toFixed(6),
        valid: true,
        unlock: false
      });
      count += 1;
    }
    for (const block of immature) {
      if (count >= 30) break;
      blockList.push({
        height: block.height,
        hash: block.hash,
        time: block.timestamp,
        shares: block.shares,
        difficulty: block.difficulty,
        effort: block.shares / block.difficulty * 100,
        reward: (parseInt(block.reward || 0) / 1e18).toFixed(6),
        valid: !block.orphan,
        unlock: false
      });
      count += 1;
    }
    for (const block of matured) {
      if (count >= 30) break;
      blockList.push({
        height: block.height,
        hash: block.hash,
        time: block.timestamp,
        shares: block.shares,
        difficulty: block.difficulty,
        effort: block.shares / block.difficulty * 100,
        reward: (parseInt(block.reward || 0) / 1e18).toFixed(6),
        valid: !block.orphan,
        unlock: true
      });
      count += 1;
    }
    setBlockList(blockList);
  }

  const processChartData = async () => {
    const resChartData = await getResChartData();
    const hashrateData = [];
    const minerData = [];
    const machineData = [];
    for (const item of resChartData) {
      var items = item.split(":")
      var time = CommonUtils.formatDateShort(new Date(items[0] * 1000));
      hashrateData.push({
        label: intl.get('HASHRATE'),
        time,
        value: parseFloat(CommonUtils.toHashrate(items[1]).slice(0, -5))
      });
      minerData.push({
        label: intl.get('ONLINE_MINERS'),
        time,
        value: parseInt(items[2])
      });
      machineData.push({
        label: intl.get('ONLINE_MACHINES'),
        time,
        value: parseInt(items[3])
      });
    }
    setHashrateData(hashrateData);
    setMinerData(minerData);
    setMachineData(machineData);
  }

  useEffect(() => {
    processBlockData();
    processChartData();
    // eslint-disable-next-line
  }, []);

  const loaded = blockList.length && hashrateData.length 
    && machineData.length && minerData.length;
  if (!loaded) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  const configHashrate = {
    data: hashrateData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value} GH/s` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} GH/s`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  const configMachine = {
    data: machineData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value}` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} ${intl.get('TAI')}`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  const configMiner = {
    data: minerData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value}` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} ${intl.get('GE')}`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  return (
    <>
    <section style={{marginBottom: '1.5rem'}} className='etc-btns-sec'>
      <Menu onClick={changeChart} selectedKeys={[currChart]} mode="horizontal">
        <Menu.Item key="block" >
          {intl.get('BLOCK')}
        </Menu.Item>
        <Menu.Item key="hashrate" >
          {intl.get('HASHRATE')}
        </Menu.Item>
        <Menu.Item key="machine">
          {intl.get('MACHINE')}
        </Menu.Item>
        <Menu.Item key="miner">
          {intl.get('MINER')}
        </Menu.Item>
      </Menu>
    </section>
    <section className='graph-sec'>
      {currChart === 'block' &&
        <div style={{ padding: '1rem 0', background: 'white' }} className='main-panel-table'>
          <table style={{width: '90%', margin: '0 auto'}}>
            <thead style={{backgroundColor: 'white', color: 'black'}} className='panel-table-thead'>
              <tr>
                <th className='sites-sec-th'>{intl.get('HEIGHT')}</th>
                <th className='sites-sec-th'>{intl.get('TIME')}</th>
                <th className='sites-sec-th'>{intl.get('SHARES')}</th>
                <th className='sites-sec-th'>{intl.get('DAMOUNT')}</th>
                <th className='sites-sec-th'>{intl.get('VALID')}</th>
                <th className='sites-sec-th'>{intl.get('INFO_HASH')}</th>
              </tr>
            </thead>
            <tbody>
              {
                blockList.map((item, key) => {
                  return (
                    <tr 
                      key={key}
                    >
                      <td className='addr sites-sec-td'>
                        {item.height}
                      </td>
                      <td className='addr sites-sec-td'>
                        <Tooltip 
                          placement="bottom" 
                          title={`${CommonUtils.formatDate(new Date(item.time * 1000))}`}
                        >
                          { intl.options.currentLocale === 'en-US' ?
                            CommonUtils.toUpdateTimeEn(new Date(Date.now() - item.time * 1000) / 1000) :
                            CommonUtils.toUpdateTime(new Date(Date.now() - item.time * 1000) / 1000)
                          }
                        </Tooltip>
                      </td>
                      <td className='addr sites-sec-td'>
                        <Tooltip 
                          placement="bottom" 
                          title={`${item.shares} / ${item.difficulty}`}
                        >
                          {item.effort > 100 && <span style={{color: 'red'}}>{item.effort.toFixed(2)}%</span>}
                          {item.effort <= 100 && <span style={{color: 'green'}}>{item.effort.toFixed(2)}%</span>}
                        </Tooltip>
                      </td>
                      <td className='addr sites-sec-td'>
                        {item.reward}
                      </td>
                      <td className='addr sites-sec-td'>
                       {item.unlock === false && <HourglassOutlined style={{color: '#afaf08'}} />}
                       {item.unlock && item.valid && <CheckOutlined style={{color: 'green'}} />}
                       {item.unlock && !item.valid && <CloseOutlined style={{color: 'red'}} />}
                      </td>
                      <td className='addr sites-sec-td'>
                        <a 
                          target='_blank'
                          rel="noreferrer" 
                          href={`https://blockscout.com/etc/mainnet/block/${item.hash}/transactions`}
                        >
                          {item.hash.length > 10 ? `${item.hash.slice(0, 5)}...${item.hash.slice(-12)}` : ''}
                        </a>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      }

      { 
        currChart !== 'block' &&
        <div style={{margin: '0'}} className='graph-panel'>
        <div className='graph-title'>
          {
            currChart === 'hashrate' &&
            intl.get('HASHRATE_VS_TIME')
          }
          {
            currChart === 'machine' &&
            intl.get('MACHINE_VS_TIME')
          }
          {
            currChart === 'miner' &&
            intl.get('MINER_VS_TIME')
          }
        </div>
        <div className='graph-con'>

          {
            currChart === 'hashrate' &&
            <>
            <Area
              {...configHashrate}
              className='sm-hide' 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />    
            <Area
              {...configHashrate} 
              className='sm-show'
              style={{padding: '5px 20px 10px', height: '22rem'}}  
            /> 
            </>
          }
          {
            currChart === 'machine' &&
            <>
            <Area
              {...configMachine}
              className='sm-hide' 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />  
            <Area
              {...configMachine} 
              className='sm-show'
              style={{padding: '5px 20px 10px', height: '22rem'}}  
            /> 
            </>
          }
          {
            currChart === 'miner' &&
            <>
            <Area 
              {...configMiner} 
              className='sm-hide'
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />    
            <Area
              {...configMiner} 
              className='sm-show'
              style={{padding: '5px 20px 10px', height: '22rem'}}  
            /> 
            </>
          }
        </div>
      </div>
      }
    </section>
    </>
  )
}
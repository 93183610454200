import { useState, useEffect } from 'react';
import { CommonUtils } from '../../utils/commonUtils';
import { Table, Menu } from 'antd';
import intl from 'react-intl-universal';

export default function AccountTableBoard ({account}) {

  const [ workerTable, setWorkerTable ] = useState('online');
  const [ onlineList, setOnlineList ] = useState([]);
  const [ offlineList, setOfflineList ] = useState([]);
  const [ transList, setTransList ] = useState([]);

  const changeWorkerTable = (e) => {
    setWorkerTable(e.key);
  }

  const processData = () => {
    let onlineList = [];
    let offlineList = [];
    for (const worker in account.workers) {
      const item = account.workers[worker];
      if (item.offline) {
        offlineList.push({
          key: worker,
          name: worker,
          hashrate: item.hr,
          hashrate24: item.hr2,
          time: Math.round(Date.now() / 1000 - item.lastBeat),
        });
      } else {
        onlineList.push({
          key: worker,
          name: worker,
          hashrate: item.hr,
          hashrate24: item.hr2,
          time: Math.round(Date.now() / 1000 - item.lastBeat),
        });
      } 
    }
    setOnlineList(onlineList);
    setOfflineList(offlineList);
    setTransList(account.payments || []);
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, [])

  const onlineColumns = [
    {
      title: intl.get('MACHINE'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name)
      },
      width: '80px'
    },
    {
      title: intl.get('CURRENT_HASHRATE'),
      dataIndex: 'hashrate',
      sorter: {
        compare: (a, b) => a.hashrate - b.hashrate
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(Math.floor(record.hashrate))
      },
    },
    {
      title: intl.get('4_HASHRATE'),
      dataIndex: 'hashrate24',
      sorter: {
        compare: (a, b) => a.hashrate24 - b.hashrate24
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(Math.floor(record.hashrate24))
      }
    },
    {
      title: intl.get('LAST_UPDATE'),
      dataIndex: 'time',
      sorter: {
        compare: (a, b) => b.time - a.time
      },
      render: (text, record, index) => {
        return CommonUtils.toUpdateTime(record.time)
      }
    },
  ];

  const offlineColumns = [
    {
      title: intl.get('MACHINE'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name)
      },
      width: '80px'
    },
    {
      title: intl.get('CURRENT_HASHRATE'),
      dataIndex: 'hashrate',
      sorter: {
        compare: (a, b) => a.hashrate - b.hashrate
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(Math.floor(record.hashrate))
      },
    },
    {
      title: intl.get('4_HASHRATE'),
      dataIndex: 'hashrate24',
      sorter: {
        compare: (a, b) => a.hashrate24 - b.hashrate24
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(Math.floor(record.hashrate24))
      }
    },
    {
      title: intl.get('LAST_UPDATE'),
      dataIndex: 'time',
      sorter: {
        compare: (a, b) => b.time - a.time
      },
      render: (text, record, index) => {
        return CommonUtils.toUpdateTime(record.time / 1000 / 60 / 60)
      }
    },
  ];

  const transactionColumns = [
    {
      title: intl.get('TIME'),
      dataIndex: 'time',
      sorter: {
        compare: (a, b) => a.timestamp - b.timestamp
      },
      render: (text, record, index) => {
        return CommonUtils.formatDate(new Date(parseInt(record.timestamp) * 1000))
      }
    },
    {
      title: intl.get('VALUE'),
      dataIndex: 'value',
      sorter: {
        compare: (a, b) => a.amount - b.amount
      },
      render: (text, record, index) => {
        return `${(record.amount / 1e9).toFixed(6)} ETC`
      }
    },
    {
      title: intl.get('TXHASH'),
      dataIndex: 'txid',
      render: (text, record, index) => {
        return <a rel="noreferrer" target='_blank' href={`https://blockscout.com/etc/mainnet/tx/${record.tx}`}>
          {`${record.tx.slice(0, 12)}...${record.tx.slice(-12)}`}
        </a>
      },
    },
  ]
  
  const onlineCount = onlineList.length;
  const offlineCount = offlineList.length;

  return (
    <>
    <section className='etc-btns-sec'>
      <Menu onClick={changeWorkerTable} selectedKeys={[workerTable]} mode="horizontal">
        <Menu.Item key="online" >
          {intl.get('ONLINE')}({onlineCount && onlineCount})
        </Menu.Item>
        <Menu.Item key="offline" >
          {intl.get('OFFLINE')}({offlineCount && offlineCount})
        </Menu.Item>
        <Menu.Item key="transaction">
          {intl.get('TX_LIST')}
        </Menu.Item>
      </Menu>
    </section>
    <section className='table-sec'>
      <div className='table-panel'>
        <div style={{marginTop: '0'}} className='main-panel-table'>
          {
            workerTable === 'online' &&
            <Table columns={onlineColumns} dataSource={onlineList} pagination={false} />
          }
          {
            workerTable === 'offline' &&
            <Table columns={offlineColumns} dataSource={offlineList} pagination={false} />
          }
          {
            workerTable === 'transaction' &&
            <Table columns={transactionColumns} dataSource={transList} pagination={false} rowKey="txid"  />
          }
        </div>              
      </div>
    </section>
    </>
  )
}
import { message } from 'antd';
import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import intl from 'react-intl-universal';
import { CommonUtils } from '../../utils/commonUtils';
import { getErgPorts } from '../../api/ajaxMain';
import {
  CopyOutlined
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';

export default function ServerList () {

  const [ nodeList, setNodeList ] = useState([]);
  const [ resPorts, setResPorts ] = useState({});

  const getResPorts = async () => {
    try {
      let resPorts = (await getErgPorts()).data;
      return resPorts;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_SERVERLIST'));
    }
  }

  const processData = async () => {
    const resPorts = (await getResPorts()) || {};
    const resPortsList = resPorts.global || [];
    const nodeList = [];
    for (const port of resPortsList) {
      nodeList.push({
        server: port.port === 9010 ? '【SSL】矿机节点 SSL Machine Validator	' : '矿机节点 Machine Validator',
        port: port.port,
        difficulty: port.difficulty/1e9,
        miners: port.miners,
        height: port.host.blockID,
        time: intl.options.currentLocale === 'en-US' ? CommonUtils.toUpdateTimeEn(new Date(Date.now() / 1000 - port.host.blockIDTime)): CommonUtils.toUpdateTime(new Date(Date.now() / 1000 - port.host.blockIDTime)),
        desc: port.description.startsWith("stratum") ? port.description : 'stratum+tcp://' + port.description,
      })
    }
    setNodeList(nodeList);
    setResPorts(resPorts);

  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  if (nodeList.length === 0 || Object.keys(resPorts) === 0) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  return (
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('SERVERLIST')}
      </div>
      <div 
        style={{
          padding: '10px 30px',
          background: 'white'
        }}
        className='main-panel-table'
      >
        <table style={{width: '100%'}}>
          <thead style={{backgroundColor: 'white', color: 'black'}} className='panel-table-thead'>
            <tr>
              <th className='sites-sec-th'>{intl.get('SERVER')}</th>
              <th className='sites-sec-th'>{intl.get('PORT')}</th>
              <th className='sites-sec-th'>{intl.get('DIFFICULTY')}</th>
              <th className='sites-sec-th'>{intl.get('MACHINE')}</th>
              <th className='sites-sec-th'>{intl.get('CURRENT_HEIGHT')}</th>
              <th className='sites-sec-th'>{intl.get('DESC')}</th>
            </tr>
          </thead>
          <tbody>
            {
              nodeList.map((item, key) => {
                return (
                  <tr 
                    key={key}
                  >
                    <td className='addr sites-sec-td'>
                      {item.server}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.port}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.difficulty} G
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.miners}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.height} ({item.time})
                    </td>
                    <td style={{width: '30%'}} className='addr sites-sec-td'>
                      {item.desc}
                      { <CopyOutlined 
                          style={{marginLeft: '10px', cursor: 'pointer'}} 
                          onClick={() => {copy(item.desc); message.success(intl.get('COPIED_TO_CLIPBOARD'))}}
                        /> }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

      </div>
    </div>
  )
}
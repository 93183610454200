/*eslint-disable*/ 
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import store from 'store';
import memoryUtils from './utils/memoryUtils';
import { useState, useEffect } from 'react';
import { checkLoginState } from './api/ajaxAuth';

import HomePage from './pages/homePage/homePage';
import NimiqPage from './pages/nimiq/nimiqPage';
import RvnPage from './pages/rvn/rvnPage';
import ErgPage from './pages/erg/ergPage';
import EtcPage from './pages/etc/etcPage';
import CpuPage from './pages/cpu/cpuPage.js';
import UserCenter from './pages/userCenter/userCenter.js';
import LoginPage from './pages/auth/loginPage.js';
import RegisterPage from './pages/auth/registerPage.js';
import ForgetPwd from './pages/auth/forgetPwd';
import ChangePwd from './pages/auth/changePwd';
import Tutorial from './pages/tutorial/tutorial';
import { message } from 'antd';
import './App.css';
import { setLogIn, setNotLogIn } from './utils/memory';
import Loading from './components/mainCommon/loading';

export default function MainRouter () {

  const [ hasChecked, setHasChecked ] = useState(false);

  const checkLoginStateWrap = async () => {
    try {
      const res = await checkLoginState();
      return res.data;
    } catch (e) {
      message.warning('验证身份信息失败');
    }
  }

  const checkState = async () => {
    const userMain = await store.get('userMain');
    /* 如果 local storage 中没有 userMain */
    if (!userMain) {
      /* 发送 check login state 请求 */
      const res = (await checkLoginStateWrap()) || {};
      /* 如果不是登录态 */
      if (!res.success) {
        await setNotLogIn();
        return setHasChecked(true);
      }
      /* 如果是登录态 */
      const user = res.data.email || res.data.phone;
      await setLogIn(user);
      return setHasChecked(true);
    }
    /* 如果已有 userMain */
    const isLogInRightFormat = typeof userMain.isLogIn === 'boolean';
    const userRightFormat = typeof userMain.user === 'string' || userMain.user === null;
    /* 如果 userMain 不是正确的格式 */
    if (!isLogInRightFormat || !userRightFormat) {
      await setNotLogIn();
      return setHasChecked(true);
    }
    /* 如果是正确的格式 */
    memoryUtils.userMain = userMain;
    setHasChecked(true);
  }

  useEffect(() => {
    checkState()
  }, []);

  if (!hasChecked) {
    // return <Loading />
    return <></>
  }

  return ( 
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={HomePage} />
        <Route path='/tutorial' exact component={Tutorial} />
        <Route path='/search/nim' exact component={NimiqPage} />
        <Route path='/search/rvn' exact component={RvnPage} />
        <Route path='/search/erg' exact component={ErgPage} />
        <Route path='/search/etc' exact component={EtcPage} />
        <Route path='/search/cpu' exact component={CpuPage} />
        <Route path='/userCenter' exact component={UserCenter} />
        <Route path='/login' exact component={LoginPage} />
        <Route path='/register' exact component={RegisterPage} /> 
        <Route path='/forgetpwd' exact component={ForgetPwd} />  
        <Route path='/changepwd' exact component={ChangePwd} />
      </Switch>   
    </BrowserRouter>)
}
import { useState } from 'react';
import { Menu, Table } from 'antd';
import { CommonUtils } from '../../utils/commonUtils';
import intl from 'react-intl-universal';

export default function AccountTableBoard ({ onlineCount, offlineCount, onlineList, offlineList, transList }) {

  const [ currTable, setCurrTable ] = useState('online');

  const onlineColumns = [
    {
      title: intl.get('MACHINE'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name)
      },
    },
    {
      title: intl.get('CURRENT_HASHRATE'),
      dataIndex: 'power',
      sorter: {
        compare: (a, b) => a.power - b.power
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.power)
      }
    },
    {
      title: intl.get('1_HASHRATE'),
      dataIndex: 'power1',
      sorter: {
        compare: (a, b) => a.power1 - b.power1
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.power1)
      }
    },
    {
      title: intl.get('24_HASHRATE'),
      dataIndex: 'power24',
      sorter: {
        compare: (a, b) => a.power24 - b.power24
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.power24)
      }
    },
    {
      title: intl.get('LAST_UPDATE'),
      dataIndex: 'update',
      sorter: {
        compare: (a, b) => b.update - a.update
      },
      render: (text, record, index) => {
        return intl.options.currentLocale === 'en-US' ?
          CommonUtils.toUpdateTimeEn(record.update) :
          CommonUtils.toUpdateTime(record.update)
      }
    },
  ];

  const offlineColumns = [
    {
      title: intl.get('MACHINE'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name)
      },
    },
    {
      title: intl.get('CURRENT_HASHRATE'),
      dataIndex: 'power',
      sorter: {
        compare: (a, b) => a.power - b.power
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.power)
      }
    },
    {
      title: intl.get('1_HASHRATE'),
      dataIndex: 'power1',
      sorter: {
        compare: (a, b) => a.power1 - b.power1
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.power1)
      }
    },
    {
      title: intl.get('24_HASHRATE'),
      dataIndex: 'power24',
      sorter: {
        compare: (a, b) => a.power24 - b.power24
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.power24)
      }
    },
    {
      title: intl.get('LAST_UPDATE'),
      dataIndex: 'update',
      sorter: {
        compare: (a, b) => b.update - a.update
      },
      render: (text, record, index) => {
        return intl.options.currentLocale === 'en-US' ?
          CommonUtils.toUpdateTimeEn(record.update) :
          CommonUtils.toUpdateTime(record.update)
      }
    },
  ];

  const transactionColumns = [
    {
      title: intl.get('TIME'),
      dataIndex: 'ts',
      sorter: {
        compare: (a, b) => a.ts - b.ts
      },
      render: (text, record, index) => {
        return intl.options.currentLocale === 'en-US' ?
          CommonUtils.toUpdateTimeEn(Date.now() / 1000 - record.ts) :
          CommonUtils.toUpdateTime(Date.now() / 1000 - record.ts)
      }
    },
    {
      title: intl.get('VALUE'),
      dataIndex: 'amount',
      sorter: {
        compare: (a, b) => a.amount - b.amount
      },
      render: (text, record, index) => {
        return `${(record.amount / 1e12).toFixed(8)} XMR`
      }
    },
    {
      title: intl.get('TXHASH'),
      dataIndex: 'txnHash',
      render: (text, record, index) => {
        return (
        <a rel="noreferrer" target='_blank' href={`https://xmrchain.net/tx/${record.txnHash}`}>
          {`${record.txnHash.slice(0, 8)}...${record.txnHash.slice(-8)}`}
        </a>)
      }
    },
    {
      title: 'mixin',
      dataIndex: 'mixin',
    }
  ]

  return (
    <>
    <section className='etc-btns-sec'>
      <Menu onClick={(e) => setCurrTable(e.key)} selectedKeys={[currTable]} mode="horizontal">
        <Menu.Item key="online" >
          {intl.get('ONLINE')}({onlineCount && onlineCount})
        </Menu.Item>
        <Menu.Item key="offline" >
          {intl.get('OFFLINE')}({offlineCount && offlineCount})
        </Menu.Item>
        <Menu.Item key="transaction">
          {intl.get('TX_LIST')}
        </Menu.Item>
      </Menu>
    </section>
    <section className='table-sec'>
      <div className='table-panel'>
        <div style={{marginTop: '0'}} className='main-panel-table'>
          {
            currTable === 'online' &&
            <Table rowKey="name" columns={onlineColumns} dataSource={onlineList} pagination={false} />
          }
          {
            currTable === 'offline' &&
            <Table rowKey="name" columns={offlineColumns} dataSource={offlineList} pagination={false} />
          }
          {
            currTable === 'transaction' &&
            <Table rowKey="txnHash" columns={transactionColumns} dataSource={transList} pagination={false} />
          }
        </div>              
      </div>
    </section>
    </>
  )
}
import { message, Table } from 'antd';
import { useState, useEffect } from 'react';
import { getEtcPayments } from '../../api/ajaxMain';
import { CommonUtils } from '../../utils/commonUtils';
import intl from 'react-intl-universal';

export default function TxBoard () {

  const [ txnList, setTxnList ] = useState([]);

  const getEtcPaymentsData = async () => {
    try {
      const payments = (await getEtcPayments()).data.payments;
      return payments;
    } catch (e) {
      message.error(intl.get("FAIL_TO_GET_TX_LIST"));
      return [];
    }
  }

  const processData = async () => {
    var payments = await getEtcPaymentsData();
    const txnList = [];
    for (const index in payments) {
      const txn = payments[index];
      txnList.push({
        time: intl.options.currentLocale === 'en-US' ? CommonUtils.toUpdateTimeEn(Date.now() / 1000 - txn.timestamp) : CommonUtils.toUpdateTime(Date.now() / 1000 - txn.timestamp),
        hash: txn.tx,
        amount: `${(txn.amount / 1e9).toFixed(6)} ETC`,
      })
    }
    setTxnList(txnList);
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  const txnColumns = [
    {
      title: intl.get('TIME'),
      dataIndex: 'time',
    },
    {
      title: intl.get('TXHASH2'),
      dataIndex: 'hash',
      render: (text, record, index) => {
        return (
        <a rel="noreferrer" target='_blank' href={`https://blockscout.com/etc/mainnet/tx/${record.hash}`}>
          {`${record.hash.slice(0, 8)}...${record.hash.slice(-8)}`}
        </a>)
      },
    },
    {
      title: intl.get('TDHASH'),
      dataIndex: 'amount',
    }
  ];

  return (
    <>
    <div style={{paddingBottom: '30px'}} className='sites-sec-title'>
      {intl.get('TXN_LIST')}
    </div>
    <section className='coin-table-sec'>
      <Table 
        rowKey="hash" 
        columns={txnColumns} 
        dataSource={txnList} 
      />
    </section>
    </>
  )
}
import { message, Table } from 'antd';
import { useState, useEffect } from 'react';
import { getErgTxns } from '../../api/ajaxMain';
import { CommonUtils } from '../../utils/commonUtils';
import intl from 'react-intl-universal';

export default function TxBoard ({ poolStats }) {

  const [ txnList, setTxnList ] = useState([]);
  // eslint-disable-next-line
  const [ currPage, setCurrPage ] = useState(0);
  const [ perPage ] = useState(15);

  const getTxnRes = async (cp) => {
    try {
      const txnRes = (await getErgTxns(cp, perPage)).data;
      return txnRes;
    } catch (e) {
      message.error(intl.get("FAIL_TO_GET_TX_LIST"));
    }
  }

  const processData = (txnRes) => {
    const txnList = [];
    for (const txn of txnRes) {
      txnList.push({
        time: intl.options.currentLocale === 'en-US' ? CommonUtils.toUpdateTimeEn((Date.now() - txn.ts) / 1000) : CommonUtils.toUpdateTime((Date.now() - txn.ts) / 1000),
        hash: txn.hash,
        amount: `${txn.value / 1e9} ERG`,
        receiver: txn.payees
      })
    }
    setTxnList(txnList);
  }

  const onTxnTableChange = async (pagination) => {
    setCurrPage(pagination.current - 1);
    const txnRes = (await getTxnRes(pagination.current - 1)) || [];
    processData(txnRes);
  }

  const getDataAtFirst = async () => {
    const txnRes = (await getTxnRes(0)) || [];
    processData(txnRes);
  }

  useEffect(() => {
    getDataAtFirst();
    // eslint-disable-next-line
  }, []);

  const txnColumns = [
    {
      title: intl.get('TIME'),
      dataIndex: 'time',
    },
    {
      title: intl.get('TXHASH2'),
      dataIndex: 'hash',
      render: (text, record, index) => {
        return (
        <a rel="noreferrer" target='_blank' href={`https://explorer.ergoplatform.com/en/transactions/${record.hash}`}>
          {`${record.hash.slice(0, 8)}...${record.hash.slice(-8)}`}
        </a>)
      },
    },
    {
      title: intl.get('TDHASH'),
      dataIndex: 'amount',
    },
    {
      title: intl.get('RECEIVER'),
      dataIndex: 'receiver',
      width: '110px',
    }
  ];

  return (
    <>
    <div style={{paddingBottom: '30px'}} className='sites-sec-title'>
      {intl.get('TXN_LIST')}
    </div>
    <section className='coin-table-sec'>
      <Table 
        rowKey="hash" 
        columns={txnColumns} 
        dataSource={txnList} 
        pagination={{
          pageSize: 15,
          total: poolStats ? poolStats['totalPayments'] : 0,
          showSizeChanger: false
        }} 
        onChange={onTxnTableChange}
      />
    </section>
    </>
  )
}
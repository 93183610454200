import memoryUtils from './memoryUtils';
import store from 'store';

export const setNotLogIn = async () => {
  memoryUtils.userMain = { isLogIn: false, user: null };
  await store.set('userMain', { isLogIn: false, user: null });
}

export const setLogIn = async (user) => {
  memoryUtils.userMain = { isLogIn: true, user };
  await store.set('userMain', { isLogIn: true, user });
}
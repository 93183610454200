import { useState, useEffect } from 'react';
import { message, Spin, Badge } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  getRvnGlobalData,
  getRvnTransactions,
  getRvnWorkers,
  getPrice
} from '../../api/ajaxMain';
import intl from 'react-intl-universal';
import copy from 'copy-to-clipboard';
import FreshBtn from '../searchCommon/freshBtn';
import {
  CopyOutlined
} from '@ant-design/icons';

import AccountDataBoard from './accountDataBoard';
import AccountGraphBoard from './accountGraphBoard';
import AccountTableBoard from './accountTableBoard';

import rvnLogo from '../../assets/ravencoin-rvn-logo.svg';

function AccountInfo ({address, history}) {
  
  const [ workersRes, setWorkersRes ] = useState({});
  const [ globalRes, setGlobalRes ] = useState({});
  const [ txRes, setTxRes ] = useState(['before initialized']);
  const [ priceData, setPriceData ] = useState({});
  const [ freshNum, setFreshNum ] = useState(-1);

  const getWorkers = async () => {
    try {
      const res = (await getRvnWorkers(address)).data;
      setWorkersRes(res);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_MACHINE_LIST'));
    }
  }

  const getGlobal = async () => {
    try {
      const res = await getRvnGlobalData();
      setGlobalRes(res.data.pools.ravencoin);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getTx = async () => {
    try {
      const resTx = (await getRvnTransactions(address)).data.slice(0, 30);
      setTxRes(resTx);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_TX_LIST'));
    }
  }

  const getPriceData = async () => {
    try {
      const res = await getPrice();
      setPriceData(res.data.data);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
    }
  }

  const refresh = (freshNum) => {
    setFreshNum(freshNum);
  }

  const getAllData = async () => {
    await getWorkers();
    await getGlobal();
    await getTx();
    await getPriceData();
    message.success(intl.get('SUCCESS_TO_GET_ACCOUNT_DATA'));
  }

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [freshNum]);

  const dataBoardLoaded = Object.keys(workersRes).length !== 0 && Object.keys(globalRes).length !== 0 && Object.keys(priceData).length !== 0;
  const graphBoardLoaded = Object.keys(workersRes).length !== 0;
  const tableBoardLoaded = Object.keys(workersRes).length !== 0 && txRes[0] !== 'before initialized';

  return (
    <>
    <div className='coin-type-box'>
      <img onClick={() => {history.push('/search/rvn'); window.location.reload();}} alt='rvn' src={rvnLogo} />
      <div onClick={() => {history.push('/search/rvn'); window.location.reload();}} className='coin-type-box-title'>
        RVN
      </div>
      <FreshBtn refresh={refresh} />
    </div>
    <section className='account-info'>
      <Badge status='success'/> {address}            
      <CopyOutlined 
        style={{marginLeft: '1rem', cursor: 'pointer'}} 
        onClick={() => { copy(address); message.success(intl.get('COPIED_TO_CLIPBOARD')) }}
      />
    </section>
    { dataBoardLoaded ? 
      <AccountDataBoard res={workersRes} resGlobal={globalRes} priceData={priceData} /> : 
      <div className='spin-box'><Spin /></div> }
    { graphBoardLoaded ?
      <AccountGraphBoard res={workersRes} /> :
      <div className='spin-box'><Spin /></div> }
    { tableBoardLoaded ?
      <AccountTableBoard res={workersRes} resTx={txRes} /> :
      <div className='spin-box'><Spin /></div> }
    </>
  )
}

export default withRouter(AccountInfo);
import { useState, useEffect } from 'react';
import { message } from 'antd';
import intl from 'react-intl-universal';
import { CommonUtils } from '../../utils/commonUtils';
import { 
  getXmrBlocks,
  getAltBlocks,
  getArqBlocks, 
  getQrlBlocks,
  getZephBlocks,
  getXhvBlocks 
} from '../../api/ajaxMain';
import BlockSingleTable from './blockSingleTable';

export default function BlockTable ({ coin, poolStats, config, network }) {

  const [ blockList, setBlockList ] = useState([]);
  const [ currPage, setCurrPage ] = useState(0);
  const [ total, setTotal ] = useState(0);
  const [ perPage ] = useState(15);
  const [ totalShares, setTotalShares ] = useState(0);

  const getBlocks = async (page, limit) => {
    try {
      let res;
      if (coin === 'xmr') {
        res = ((await getXmrBlocks(page, limit)).data) || [];
      } else if (coin === 'alt') {
        res = ((await getAltBlocks(page, limit)).data) || [];
      } else if (coin === 'arq') {
        res = ((await getArqBlocks(page, limit)).data) || [];
      } else if (coin === 'qrl') {
        res = ((await getQrlBlocks(page, limit)).data) || [];
      } else if (coin === 'zeph') {
        res = ((await getZephBlocks(page, limit)).data) || [];
      } else {
        res = ((await getXhvBlocks(page, limit)).data) || [];
      }
      return res;
    } catch (e) {
      message.error(intl.get('FAILED_TO_GET_BLOCKS'));
    }
  }

  const onTableChange = async (pagination) => {
    setCurrPage(pagination.current - 1);
    const res = (await getBlocks(pagination.current - 1, perPage)) || [];
    processData(res, pagination.current - 1);
  }

  const processData = (res, cp) => {
    if (coin === 'xmr') {
      processXmrData(res, cp);
    } else if (coin === 'alt') {
      processAltData(res, cp);
    } else if (coin === 'arq') {
      processArqData(res, cp);
    } else if (coin === 'qrl') {
      processQrlData(res, cp);
    } else if (coin === 'zeph') {
      processZephData(res, cp);
    } else {
      processXhvData(res, cp);
    }
  }

  const processXmrData = (res, cp) => {
    const totalBlocksFound = poolStats['totalBlocksFound'];
    const xmrList = [];
    let index = 0;
    let pageShares = 0;
    let pageDiff = 0;
    for (const block of res) {
      pageShares += block.shares;
      pageDiff += block.diff;
      xmrList.push({
        id: totalBlocksFound - index - 1 - cp * perPage,
        name: 'xmr',
        time: block.ts,
        shares: `${(block.shares / block.diff * 100).toFixed(2)}%`,
        oriShares: block.shares,
        oriDiff: block.diff,
        amount: (block.value / 1e12).toFixed(6),
        height: block.height,
        valid: block.valid,
        unlocked: block.unlocked,
        mature: config.maturity_depth - (network['18081'].height - block.height),
        hash: block.hash
      })
      index += 1;
    }
    const totalShares = `${(pageShares / pageDiff * 100).toFixed(2)}%`;
    setTotalShares(totalShares);
    setBlockList(xmrList);
    const total = poolStats ? poolStats['totalBlocksFound'] : 0;
    setTotal(total);
  }

  const processAltData = (res, cp) => {
    const altList = [];
    let index = 0;
    let pageShares = 0;
    let pageDiff = 0;
    for (const block of res) {
      pageShares += block.shares;
      pageDiff += block.diff;
      altList.push({
        id: poolStats['totalAltBlocksFound'] - index - 1 - cp * perPage,
        name: 'alt',
        coin: CommonUtils.toCoinName(block.port),
        time: block.ts,
        payValue: block.pay_value,
        shares: `${(block.shares / block.diff * 100).toFixed(2)}%`,
        amount: (CommonUtils.updateValue(block.port, block.value) / 1e12).toFixed(4),
        oriShares: block.shares,
        oriDiff: block.diff,
        height: block.height,
        valid: block.valid,
        unlocked: block.unlocked,
        mature: config.maturity_depth - (network[block.port.toString()].height - block.height),
        hash: block.hash,
        link: CommonUtils.hashToLink(block.hash, 'block', block.port, block.height)
      })
      index += 1;
    }
    const totalShares = `${(pageShares / pageDiff * 100).toFixed(2)}%`;
    setTotalShares(totalShares);
    setBlockList(altList);
    const total = poolStats ? poolStats['totalAltBlocksFound'] : 0;
    setTotal(total);
  }

  const processArqData = (res, cp) => {
    const arqList = [];
    let index = 0;
    let pageShares = 0;
    let pageDiff = 0;
    for (const block of res) {
      pageShares += block.shares;
      pageDiff += block.diff;
      arqList.push({
        id: poolStats['altBlocksFound']['19994'] - index - 1 - cp * perPage,
        name: 'alt',
        time: block.ts,
        payValue: block.pay_value,
        shares: `${(block.shares / block.diff * 100).toFixed(2)}%`,
        amount: (CommonUtils.updateValue(block.port, block.value) / 1e12).toFixed(4),
        oriShares: block.shares,
        oriDiff: block.diff,
        height: block.height,
        valid: block.valid,
        unlocked: block.unlocked,
        mature: config.maturity_depth - (network[block.port.toString()].height - block.height),
        hash: block.hash,
        link: CommonUtils.hashToLink(block.hash, 'block', block.port, block.height)
      })
      index += 1;
    }
    const totalShares = `${(pageShares / pageDiff * 100).toFixed(2)}%`;
    setTotalShares(totalShares);
    setBlockList(arqList);
    const total = poolStats ? poolStats['altBlocksFound']['19994'] : 0;
    setTotal(total);
  }

  const processQrlData = (res, cp) => {
    const qrlList = [];
    let index = 0;
    let pageShares = 0;
    let pageDiff = 0;
    for (const block of res) {
      pageShares += block.shares;
      pageDiff += block.diff;
      qrlList.push({
        id: poolStats['altBlocksFound']['18090'] - index - 1 - cp * perPage,
        name: 'alt',
        time: block.ts,
        payValue: block.pay_value,
        shares: `${(block.shares / block.diff * 100).toFixed(2)}%`,
        amount: (CommonUtils.updateValue(block.port, block.value) / 1e12).toFixed(4),
        oriShares: block.shares,
        oriDiff: block.diff,
        height: block.height,
        valid: block.valid,
        unlocked: block.unlocked,
        mature: config.maturity_depth - (network[block.port.toString()].height - block.height),
        hash: block.hash,
        link: CommonUtils.hashToLink(block.hash, 'block', block.port, block.height)
      })
      index += 1;
    }
    const totalShares = `${(pageShares / pageDiff * 100).toFixed(2)}%`;
    setTotalShares(totalShares);
    setBlockList(qrlList);
    const total = poolStats ? poolStats['altBlocksFound']['18090'] : 0;
    setTotal(total);
  }

  const processZephData = (res, cp) => {
    const zephList = [];
    let index = 0;
    let pageShares = 0;
    let pageDiff = 0;
    for (const block of res) {
      pageShares += block.shares;
      pageDiff += block.diff;
      zephList.push({
        id: poolStats['altBlocksFound']['17767'] - index - 1 - cp * perPage,
        name: 'alt',
        time: block.ts,
        payValue: block.pay_value,
        shares: `${(block.shares / block.diff * 100).toFixed(2)}%`,
        amount: (CommonUtils.updateValue(block.port, block.value) / 1e12).toFixed(4),
        oriShares: block.shares,
        oriDiff: block.diff,
        height: block.height,
        valid: block.valid,
        unlocked: block.unlocked,
        mature: config.maturity_depth - (network[block.port.toString()].height - block.height),
        hash: block.hash,
        link: CommonUtils.hashToLink(block.hash, 'block', block.port, block.height)
      })
      index += 1;
    }
    const totalShares = `${(pageShares / pageDiff * 100).toFixed(2)}%`;
    setTotalShares(totalShares);
    setBlockList(zephList);
    const total = poolStats ? poolStats['altBlocksFound']['17767'] : 0;
    setTotal(total);
  }

  const processXhvData = (res, cp) => {
    const xhvList = [];
    let index = 0;
    let pageShares = 0;
    let pageDiff = 0;
    for (const block of res) {
      pageShares += block.shares;
      pageDiff += block.diff;
      xhvList.push({
        id: poolStats['altBlocksFound']['17750'] - index - 1 - cp * perPage,
        name: 'alt',
        time: block.ts,
        payValue: block.pay_value,
        shares: `${(block.shares / block.diff * 100).toFixed(2)}%`,
        amount: (CommonUtils.updateValue(block.port, block.value) / 1e12).toFixed(4),
        oriShares: block.shares,
        oriDiff: block.diff,
        height: block.height,
        valid: block.valid,
        unlocked: block.unlocked,
        mature: config.maturity_depth - (network[block.port.toString()].height - block.height),
        hash: block.hash,
        link: CommonUtils.hashToLink(block.hash, 'block', block.port, block.height)
      })
      index += 1;
    }
    const totalShares = `${(pageShares / pageDiff * 100).toFixed(2)}%`;
    setTotalShares(totalShares);
    setBlockList(xhvList);
    const total = poolStats ? poolStats['altBlocksFound']['17750'] : 0;
    setTotal(total);
  }

  const getDataAtFirst = async () => {
    setCurrPage(0);
    const res = (await getBlocks(0, perPage)) || [];
    processData(res, 0);
  }

  useEffect(() => {
    getDataAtFirst();
    // eslint-disable-next-line
  }, [coin]);

  return (
    <>
    <div className='blockTable-subtitle'>
    {intl.get('PAGE_EFFORTS')}: {` `}
      <span style={parseFloat(totalShares) < 100 ? {color: 'green'} : {color: 'red'}}>
        { totalShares }
      </span>
    </div>
    <BlockSingleTable 
      coin={coin} 
      blockList={blockList} 
      total={total} 
      onTableChange={onTableChange} 
      currPage={currPage + 1}
    />
    </>
  )
}
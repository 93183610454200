const config = {
  mainApi: process.env.NODE_ENV === 'development' ? 'https://api.main.skypool.xyz': 'https://api.main.skypool.xyz', // 矿池通用接口
  skypoolApi: 'https://api.skypool.xyz',      // 机枪池
  nimiqApi: 'https://api.nimiq.skypool.xyz',  // NIM 池
  rvnApi: 'https://api.rvn.skypool.xyz',      // RVN 池
  ergApi: 'https://api.erg.skypool.xyz',      // ERG 池
  // etcApi: 'http://192.168.42.128:3000',      // ETC 池
  etcApi: 'https://api.etc.skypool.xyz',      // ETC 池
  tempApi: 'http://127.0.0.1:7999'
}

export default config;
import { useState, useEffect } from 'react';
import { message, Menu } from 'antd';
import intl from 'react-intl-universal';
import { getPrice, getReward, getHashrate } from '../../api/ajaxMain';
import ethLogo from '../../assets/eth.png';
import nimiqLogo from '../../assets/nimiq-nim-logo.svg';
import SearchBar from '../searchBar/searchBar';
import PowTable from '../../components/homePage/powTable';
import PosTable from '../../components/homePage/posTable';
import homeUtils from '../../utils/homeUtils';
import '../../styles/homePage/homePage.css';
import { CommonUtils } from '../../utils/commonUtils';
import dataListInitial from './dataListInitial';

export default function MainTable ({ change, setLoaded }) {

  const posDataListInitial = [
    {
      key: 'ETH 2.0',
      type: 'ETH 2.0',
      logo: ethLogo,
      addr: 'eth-pool.skypool.xyz',
      power: '28.17 T',
      totalPower: '631 T',
      price: '1',
      yield: '~ 6%',
      yieldCNY: '0.024',
      rate1: '',
      rate2: intl.get('CONTACT_US'),
      action: 'eth',
      goto: 'https://bar.skypool.xyz',
      algo: intl.get('GRAPHICS_CARD')
    },
    {
      key: 'NIM 2.0',
      type: 'NIM 2.0',
      logo: nimiqLogo,
      addr: 'eth-pool.skypool.xyz',
      power: '28.17 T',
      totalPower: '631 T',
      price: '1',
      yield: intl.get('COMING_SOON'),
      yieldCNY: '0.024',
      rate1: '',
      rate2: intl.get('CONTACT_US'),
      action: 'eth',
      algo: intl.get('GRAPHICS_CARD')
    }
  ]

  const [ dataList, setDataList ] = useState(dataListInitial);
  const [ posDataList ] = useState(posDataListInitial);
  const [ done, setDone ] = useState(false);

  const getPriceFunc = async () => {
    try {
      const response = await getPrice();
      return response.data.data;
    } catch (e) {
      message.error('获取价格失败');
      return {};
    }
  }

  const getRewardFunc = async () => {
    try {
      const response = await getReward();
      return response.data.data;
    } catch (e) {
      message.error('获取收益失败');
      return {};
    }
  }

  const getHashrateFunc = async () => {
    try {
      const response = await getHashrate();
      return response.data.data;
    } catch (e) {
      message.error('获取算力失败');
      return {};
    }
  }

  const processData = async () => {
    var res = await Promise.all([getHashrateFunc(), getPriceFunc(), getRewardFunc()]);
    const powerRes = res[0];
    const priceData = res[1];
    const rewardData = res[2];

    const dataList = [];
    // 公开矿池
    for (const name of ['ETC', 'XMR', 'ARQ', 'QRL', 'ZEPH', 'XHV']) {
      let hash = powerRes[name].HASH;
      hash = typeof hash === 'number' ? CommonUtils.toHashrate(hash) : hash;
      let gHash = powerRes[name].GHASH;
      gHash = typeof gHash === 'number' ? CommonUtils.toHashrate(gHash) : gHash;
      dataList.push({
        type: name,
        logo: homeUtils.getLogo(name),
        hash: hash,
        gHash: gHash,
        reward: rewardData[name] ? (rewardData[name].reward || rewardData[name].share) : '-',
        rewardCny: rewardData[name] ? rewardData[name].rewardCny : '',
        price: priceData[name] ? priceData[name].CNY : '',
        change: priceData[name] ? priceData[name].CNY_CH: '',
        rate1: homeUtils.getRate1(name),
        rate2: homeUtils.getRate2(name),
        goto: homeUtils.getGoto(name),
        algo: homeUtils.getAlgo(name)
      })
    }


    // 网吧版
    for (const name of ['ETC', 'CFX']) {
      let hash = intl.get('SKYPOOL_BAR_MINING');
      hash = typeof hash === 'number' ? CommonUtils.toHashrate(hash) : hash;
      let gHash = intl.get('ONLY_IN_CHINA');
      gHash = typeof gHash === 'number' ? CommonUtils.toHashrate(gHash) : gHash;
      dataList.push({
        type: name,
        logo: homeUtils.getLogo(name),
        hash: hash,
        gHash: gHash,
        reward: '-',
        rewardCny: '',
        price: priceData[name] ? priceData[name].CNY : '',
        change: priceData[name] ? priceData[name].CNY_CH: '',
        rate1: homeUtils.getRate1('bar'),
        rate2: homeUtils.getRate2('bar'),
        goto: homeUtils.getGoto('bar'),
        algo: homeUtils.getAlgo(name)
      })
    }

    setDataList(dataList);
    setLoaded(true);
    setDone(true);
  }

  useEffect(() => {
    if (change === 0) processData();
    // eslint-disable-next-line
  }, [change]);

  if (!done) return <></>;

  return (
    <section className='homePage-main-panel'>
      <div className='container'>
        <SearchBar type='main' />
        <div className='table-title'>
          <Menu onClick={() => {}} selectedKeys={["pow"]} mode="horizontal" style={{justifyContent: 'center'}}>
            <Menu.Item key="pow" >
              PoW {intl.get('SECTION')}
            </Menu.Item>
          </Menu>
        </div>
        <PowTable dataList={dataList} />
        {/* <div className='table-title'>
          <Menu onClick={() => {}} selectedKeys={["pos"]} mode="horizontal">
            <Menu.Item key="pos" >
              PoS {intl.get('SECTION')}
            </Menu.Item>
          </Menu>
        </div>
        <PosTable posDataList={posDataList} /> */}
      </div> 
    </section>
  )
}
import { Tooltip, Table } from 'antd';
import {
  UnlockOutlined,
  CloseOutlined,
  CheckOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import intl from 'react-intl-universal';
import { CommonUtils } from '../../utils/commonUtils';

export default function BlockSingleTable ({coin, blockList, total, onTableChange, currPage}) {

  const coinColumns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: intl.get('TIME'),
      dataIndex: 'time',
      render: (text, record, index) => {
        return (
        <Tooltip 
          placement="bottom" 
          title={`${CommonUtils.formatDate(new Date(record.time))}`}
        >
          { intl.options.currentLocale === 'en-US' ?
            CommonUtils.toUpdateTimeEn(new Date((Date.now() - record.time) / 1000)) :
            CommonUtils.toUpdateTime(new Date((Date.now() - record.time) / 1000))
          }
        </Tooltip>)
      }
    },
    {
      title: intl.get('SHARES'),
      dataIndex: 'shares',
      render: (text, record, index) => {
        const sharesFloat = parseFloat(record.shares);
        if (sharesFloat > 100) {
          return <Tooltip 
            placement="bottom" 
            title={`${record.oriShares} / ${record.oriDiff}`}
          >
            <span style={{color: 'red'}}>{record.shares}</span>
          </Tooltip>
        } else {
          return <Tooltip 
            placement="bottom" 
            title={`${record.oriShares} / ${record.oriDiff}`}
          >
            <span style={{color: 'green'}}>{record.shares}</span>
          </Tooltip>
        }

      }
    },
    {
      title: intl.get('DAMOUNT'),
      dataIndex: 'amount',
    },
    {
      title: intl.get('HEIGHT'),
      dataIndex: 'height',
    },
    {
      title: intl.get('VALID'),
      dataIndex: 'valid',
      render: (text, record, index) => {
        if (record.unlocked !== undefined && !record.unlocked) {
          return <HourglassOutlined style={{color: '#afaf08'}} />
        }
        if (record.valid) {
          return <CheckOutlined style={{color: 'green'}} />
        } else {
          return <CloseOutlined style={{color: 'red'}} />
        }
      }
    },
    {
      title: intl.get('MATURE'),
      dataIndex: 'mature',
      render: (text, record, index) => {
        if (record.mature <=0 && record.valid && record.amount > 0) {
          return (
          <Tooltip placement="bottom" title={`${-record.mature}${intl.get('BLOCKS_AGO')}`}>
            <UnlockOutlined /> {intl.get('PAID')}
          </Tooltip>)
        }
        if (record.mature <= 0 && (!record.valid || record.amount === 0)) {
          return (
            <Tooltip placement="bottom" title={`${-record.mature}${intl.get('BLOCKS_AGO')}`}>
              <UnlockOutlined />
            </Tooltip>)
        }
        if (record.mature > 0) {
          return (
            <Tooltip placement="bottom" title={`${record.mature}${intl.get('BLOCKS_LEFT')}`}>
            </Tooltip>)
        }
      }
    },
    {
      title: intl.get('HASH'),
      dataIndex: 'hash',
      render: (text, record, index) => {
        return (
          <a 
            rel="noreferrer"
            target='_blank' 
            href={`https://xmrchain.net/block/${record.hash}`}
          >
            {`${record.hash.slice(0, 8)}...${record.hash.slice(-8)}`}
          </a>
        );
      }
    },
  ];

  const altCoinColumns = [
    {
      title: '#',
      dataIndex: 'id'
    },
    {
      title: intl.get('TIME'),
      dataIndex: 'time',
      render: (text, record, index) => {
        return (
        <Tooltip 
          placement="bottom" 
          title={`${CommonUtils.formatDate(new Date(record.time))}`}
        >
          { intl.options.currentLocale === 'en-US' ?
            CommonUtils.toUpdateTimeEn(new Date((Date.now() - record.time) / 1000)) :
            CommonUtils.toUpdateTime(new Date((Date.now() - record.time) / 1000))
          }
        </Tooltip>)
      }
    },
    {
      title: intl.get('COIN'),
      dataIndex: 'coin'
    },
    {
      title: intl.get('SHARES'),
      dataIndex: 'shares',
      render: (text, record, index) => {
        const sharesFloat = parseFloat(record.shares);
        if (sharesFloat > 100) {
          return <Tooltip 
            placement="bottom" 
            title={`${record.oriShares} / ${record.oriDiff}`}
          >
            <span style={{color: 'red'}}>{record.shares}</span>
          </Tooltip>
        } else {
          return <Tooltip 
            placement="bottom" 
            title={`${record.oriShares} / ${record.oriDiff}`}
          >
            <span style={{color: 'green'}}>{record.shares}</span>
          </Tooltip>
        }
      }
    },
    {
      title: intl.get('DAMOUNT'),
      dataIndex: 'amount',
    },
    {
      title: intl.get('XMR_PAID'),
      dataIndex: 'payValue',
      render: (text, record, index) => {
        if (record.payValue > 0 && record.valid) {
          return <>{(record.payValue / 1e12).toFixed(6)}</>
        } else if (record.payValue === 0 && record.valid) {
          return <>{intl.get('VALIDATING')}</>
        } else {
          return <>{intl.get('INVALID')}</>
        }
      }
    },
    {
      title: intl.get('HEIGHT'),
      dataIndex: 'height',
    },
    {
      title: intl.get('VALID'),
      dataIndex: 'valid',
      render: (text, record, index) => {
        if (record.unlocked !== undefined && !record.unlocked) {
          return <HourglassOutlined style={{color: '#afaf08'}} />
        }
        if (record.valid) {
          return <CheckOutlined style={{color: 'green'}} />
        } else {
          return <CloseOutlined style={{color: 'red'}} />
        }
      }
    },
    {
      title: intl.get('MATURE'),
      dataIndex: 'mature',
      render: (text, record, index) => {
        if (record.mature <=0 && record.valid && record.payValue > 0) {
          return (
          <Tooltip placement="bottom" title={`${-record.mature}${intl.get('BLOCKS_AGO')}`}>
            <UnlockOutlined /> {intl.get('PAID')}
          </Tooltip>)
        }
        if (record.mature <= 0 && (!record.valid || record.payValue === 0)) {
          return (
            <Tooltip placement="bottom" title={`${-record.mature}${intl.get('BLOCKS_AGO')}`}>
              <UnlockOutlined />
            </Tooltip>)
        }
        if (record.mature > 0) {
          return (
            <Tooltip placement="bottom" title={`${record.mature}${intl.get('BLOCKS_LEFT')}`}>
              {record.mature} {intl.get('BLOCKS_LEFT')}
            </Tooltip>)
        }
      }
    },
    {
      title: intl.get('HASH'),
      dataIndex: 'hash',
      render: (text, record, index) => {
        return (
          <a 
            rel="noreferrer"
            target='_blank' 
            href={record.link}
          >
            {`${record.hash.slice(0, 8)}...${record.hash.slice(-8)}`}
          </a>
        );
      }
    },
  ];

  const otherCoinColumns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: intl.get('TIME'),
      dataIndex: 'time',
      render: (text, record, index) => {
        return (
        <Tooltip 
          placement="bottom" 
          title={`${CommonUtils.formatDate(new Date(record.time))}`}
        >
          { intl.options.currentLocale === 'en-US' ?
            CommonUtils.toUpdateTimeEn(new Date((Date.now() - record.time) / 1000)) :
            CommonUtils.toUpdateTime(new Date((Date.now() - record.time) / 1000))
          }
        </Tooltip>)
      }
    },
    {
      title: intl.get('SHARES'),
      dataIndex: 'shares',
      render: (text, record, index) => {
        const sharesFloat = parseFloat(record.shares);
        if (sharesFloat > 100) {
          return <Tooltip 
            placement="bottom" 
            title={`${record.oriShares} / ${record.oriDiff}`}
          >
            <span style={{color: 'red'}}>{record.shares}</span>
          </Tooltip>
        } else {
          return <Tooltip 
            placement="bottom" 
            title={`${record.oriShares} / ${record.oriDiff}`}
          >
            <span style={{color: 'green'}}>{record.shares}</span>
          </Tooltip>
        }
      }
    },
    {
      title: intl.get('DAMOUNT'),
      dataIndex: 'amount',
    },
    {
      title: intl.get('XMR_PAID'),
      dataIndex: 'payValue',
      render: (text, record, index) => {
        if (record.payValue > 0 && record.valid) {
          return <>{(record.payValue / 1e12).toFixed(6)}</>
        } else if (record.payValue === 0 && record.valid) {
          return <>{intl.get('VALIDATING')}</>
        } else {
          return <>{intl.get('INVALID')}</>
        }
      }
    },
    {
      title: intl.get('HEIGHT'),
      dataIndex: 'height',
    },
    {
      title: intl.get('VALID'),
      dataIndex: 'valid',
      render: (text, record, index) => {
        if (record.unlocked !== undefined && !record.unlocked) {
          return <HourglassOutlined style={{color: '#afaf08'}} />
        }
        if (record.valid) {
          return <CheckOutlined style={{color: 'green'}} />
        } else {
          return <CloseOutlined style={{color: 'red'}} />
        }
      }
    },
    {
      title: intl.get('MATURE'),
      dataIndex: 'mature',
      render: (text, record, index) => {
        if (record.mature <=0 && record.valid && record.payValue > 0) {
          return (
          <Tooltip placement="bottom" title={`${-record.mature}${intl.get('BLOCKS_AGO')}`}>
            <UnlockOutlined /> {intl.get('PAID')}
          </Tooltip>)
        }
        if (record.mature <= 0 && (!record.valid || record.payValue === 0)) {
          return (
            <Tooltip placement="bottom" title={`${-record.mature}${intl.get('BLOCKS_AGO')}`}>
              <UnlockOutlined />
            </Tooltip>)
        }
        if (record.mature > 0) {
          return (
            <Tooltip placement="bottom" title={`${record.mature}${intl.get('BLOCKS_AGO')}`}>
              {record.mature} {intl.get('BLOCKS_AGO')}
            </Tooltip>)
        }
      }
    },
    {
      title: intl.get('HASH'),
      dataIndex: 'hash',
      render: (text, record, index) => {
        return (
          <a 
            rel="noreferrer"
            target='_blank' 
            href={record.link}
          >
            {`${record.hash.slice(0, 8)}...${record.hash.slice(-8)}`}
          </a>
        );
      }
    },
  ];

  let columns;
  if (coin === 'xmr') {
    columns = coinColumns;
  } else if (coin === 'alt') {
    columns = altCoinColumns;
  } else {
    columns = otherCoinColumns;
  }

  return (
    <Table 
      rowKey="id" 
      columns={columns} 
      dataSource={blockList} 
      pagination={{
        pageSize: 15,
        total,
        current: currPage,
        showSizeChanger: false,
      }} 
      onChange={onTableChange}
    />
  )
}
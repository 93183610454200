import { Button, message } from 'antd';
import { Modal, Input } from 'antd';
import { reqChangepwd } from '../../api/ajaxAuth';
import intl from 'react-intl-universal';

import './bindModal.css';

export default function PwdModal ({ visible, onCancel }) {

  const postChangePwd = async (pwd) => {
    try {
      const res = await reqChangepwd('', pwd);
      return res.data;
    } catch (e) {
      message.error(intl.get('NETWORK_ERROR'));
    }
  }

  const toChangePwd = async () => {
    const pwd = document.getElementById('pwd-input').value;
    const pwd2 = document.getElementById('pwd2-input').value;

    if (!pwd) return message.warning(intl.get('PLEASE_INPUT_NEW_PASSWORD'));
    if (!pwd2) return message.warning(intl.get('PLEASE_CONFIRM_THE_NEW_PASSWORD'));
    if (pwd !== pwd2) return message.warning(intl.get('PASSWORDS_DO_NOT_MATCH'));

    // ajax
    const res = await postChangePwd(pwd);
    
    if (!res.success) return message.warning(intl.get('FAILED_TO_CHANGE'));

    onCancel();
    return message.success(intl.get('CHANGED'));
  }

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className='pwdModal'
      title={intl.get('CHANGE_PASSWORD')}
      onCancel={onCancel}
      style={{ top: '30vh' }}
    >
      <div className='bindModal'>
        <div className='modal-input-title'>
          {intl.get('NEW_PASSWORD')}
        </div>
        <div className='modal-input-box'>
          <Input allowClear={true} type='password' id='pwd-input' className='modal-input-class' />
        </div>
        <div style={{paddingTop: '20px'}} className='modal-input-title'>
          {intl.get('CONFIRM_NEW_PASSWORD')}
        </div>
        <div className='modal-input-box'>
          <Input allowClear={true} type='password' id='pwd2-input' className='modal-input-class' />
        </div>
        <Button 
          onClick={toChangePwd} 
          type='primary' 
          className='modal-input-class' 
          style={{ width: '100%', marginTop: '35px' }}
        >{intl.get('CHANGE_PASSWORD')}</Button>
      </div>
    </Modal>
  )
}
import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import announceHelpers from '../../components/announce/announce.jsx';
import CoinInfo from '../../components/searchErg/coinInfo';
import AccountInfo from '../../components/searchErg/accountInfo';

import '../../styles/searchPage/searchPage.css';

export default function ErgPage ({location}) {

  const [ announceList, setAnnounceList ] = useState([]);
  const [ change, setChange ] = useState(0);

  const changeCry = () => setChange(change + 1);

  useEffect(() => {    
    if (change !== 0) return;
    const en = intl.options.currentLocale === 'en-US';
    announceHelpers.getAnnounce(en, 'erg', setAnnounceList);
    // eslint-disable-next-line
  }, [])

  return (
    <div className='search-page'> 
      <CommonHeader changeCry={changeCry} />
      { announceHelpers.showAnnounce(announceList) }
      <div className='search-container'>
        {location.hash && 
        <AccountInfo change={change} address={location.hash.slice(1)} />}
        {!location.hash &&
        <CoinInfo change={change} />}
      </div>
      <CommonFooter />
    </div>
  )
}
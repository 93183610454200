import homeUtils from "../../utils/homeUtils";

const dataListInitial = [{
  algo: "显卡",
  change: "00.00",
  gHash: "00.00 GH/s",
  goto: "/search/nim",
  hash: "000.00 MH/s",
  logo: homeUtils.getLogo('NIM'),
  price: "0.0000",
  rate1: "PPLNS",
  rate2: "1%",
  reward: "0.00",
  rewardCny: "0.00",
  type: "NIM"
}, {
  algo: "显卡",
  change: "00.00",
  gHash: "0.00 TH/s",
  goto: "/search/rvn",
  hash: "000.00 MH/s",
  logo: homeUtils.getLogo('RVN'),
  price: "0.0000",
  rate1: "PPS+",
  rate2: "1.5%",
  reward: "0.00",
  rewardCny: "0.00",
  type: "RVN"
}, {
  algo: "显卡",
  change: "00.00",
  gHash: "0.00 TH/s",
  goto: "/search/erg",
  hash: "000.00 MH/s",
  logo: homeUtils.getLogo('ERG'),
  price: "0.0000",
  rate1: "PPS+",
  rate2: "1.5%",
  reward: "0.00",
  rewardCny: "0.00",
  type: "ERG"
}, {
  algo: "CPU",
  change: "0.00",
  gHash: "0.00 GH/s",
  goto: "/search/cpu",
  hash: "00.00 MH/s",
  logo: "/static/media/monero-xmr-logo.7cf5a54a.svg",
  price: "0000.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: 0.00,
  rewardCny: 0.00,
  type: "XMR"
}, {
  algo: "CPU",
  change: "0.00",
  gHash: "00.00 MH/s",
  goto: "/search/cpu",
  hash: "00.00 MH/s",
  logo: homeUtils.getLogo('ARQ'),
  price: "0.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "0.0000",
  type: "ARQ"
}, {
  algo: "CPU",
  change: "0.00",
  gHash: "00.00 MH/s",
  goto: "/search/cpu",
  hash: "0.00 H/s",
  logo: "",
  price: "0.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "0.0000",
  type: "QRL"
}, {
  algo: "CPU",
  change: "0.00",
  gHash: "00.00 MH/s",
  goto: "/search/cpu",
  hash: "0.00 H/s",
  logo: "",
  price: "0.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "0.0000",
  type: "ZEPH"
}, {
  algo: "CPU / 显卡",
  change: "0.00",
  gHash: "00.00 MH/s",
  goto: "/search/cpu",
  hash: "0.00 H/s",
  logo: "",
  price: "00.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "0.0000",
  type: "XHV"
}, {
  algo: "显卡",
  change: "0.00",
  gHash: "仅限中国地区",
  goto: "https://bar.skypool.xyz",
  hash: "天池网吧版",
  logo: "",
  price: "00000.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "-",
  rewardCny: "",
  type: "ETH"
}, {
  algo: "显卡",
  change: "0.00",
  gHash: "仅限中国地区",
  goto: "https://bar.skypool.xyz",
  hash: "天池网吧版",
  logo: "",
  price: "000.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "-",
  rewardCny: "",
  type: "ETC"
}, {
  algo: "显卡",
  change: "0.00",
  gHash: "仅限中国地区",
  goto: "https://bar.skypool.xyz",
  hash: "天池网吧版",
  logo: "",
  price: "000.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "-",
  rewardCny: "",
  type: "CFX"
}, {
  algo: "显卡",
  change: "0.00",
  gHash: "仅限中国地区",
  goto: "https://bar.skypool.xyz",
  hash: "天池网吧版",
  logo: "",
  price: "000.0000",
  rate1: "PPS+",
  rate2: "1%",
  reward: "-",
  rewardCny: "",
  type: "KAS"
}]

export default dataListInitial;

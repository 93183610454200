import intl from 'react-intl-universal';
import {
  PlusOutlined,
  MinusOutlined
} from '@ant-design/icons'

export default function TransferRange ({ minusOne, addOne, threshold }) {
  return (
    <div className='transfer-range'>
      <div className='transfer-range-label'>{intl.get('PAY_THRESHOLD')}</div>
      <div className='transfer-range-minus-btn-con'>
        <div onClick={minusOne} className='transfer-range-btn'>
          <MinusOutlined style={{fontSize: '15px'}} />
        </div>
      </div>
      <div className='transfer-range-figure'>{ threshold && parseFloat(threshold).toFixed(2) }</div>
      <div className='transfer-range-add-btn-con'>
        <div onClick={addOne} className='transfer-range-btn'>
          <PlusOutlined style={{fontSize: '15px'}} />
        </div>
      </div>
    </div> 
  )
}
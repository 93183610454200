import { useEffect } from 'react';
import { Row, Col } from 'antd';
import memoryUtils from '../../utils/memoryUtils';
import intl from 'react-intl-universal';

export default function DataBoard ({ global, change }) {

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  useEffect(() => {

  }, [change]);

  return (
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('GLOBAL_POOL_DATA')}
      </div>
      <div 
        className='data-board'
        style={{padding: '20px 0 15px'}}
      >
        <div className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:5}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('GLOBAL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                  {global.hashrate}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:4}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                {intl.get('POOL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                  {global.poolHashrate}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:5}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('ONLINE_MINERS')}
                </div>
                <div className='data-board-item-figure'>
                  {global.miners}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:5}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                {intl.get('ONLINE_MACHINES')}
                </div>
                <div className='data-board-item-figure'>
                  {global.machines}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:5}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                {intl.get('PRICE')}                               
                {
                  global.change >= 0 ? 
                  <span style={{marginLeft: '3px', color: 'green'}}>
                    {`+${global.change}`}%
                  </span> : 
                  <span style={{marginLeft: '3px', color: 'red'}}>
                    {global.change}%
                  </span>
                }
                </div>
                <div className='data-board-item-figure'>
                  {crySign + (parseFloat(global.price) / exchange).toFixed(3)}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
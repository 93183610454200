import { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import CoinIntro from '../searchCommon/coinIntro';
import ergLogo from '../../assets/erg.jpeg';
import intl from 'react-intl-universal';
import {
  getErgPorts,
  getErgPoolStats,
  getErgNetwork,
  getErgConfig,
  getPrice,
  getReward
} from '../../api/ajaxMain';
import { CommonUtils } from '../../utils/commonUtils';
import SearchBar from '../searchBar/searchBar';
import ServerList from '../../components/searchErg/serverList';
import DataBoard from '../../components/searchErg/dataBoard';
import BlockBoard from '../../components/searchErg/blockBoard';
import TxBoard from '../../components/searchErg/txBoard';

export default function CoinInfo () {

  const [ global, setGlobal ] = useState({});
  const [ coinList, setCoinList ] = useState([]);
  const [ poolStats, setPoolStats ] = useState({});
  const [ config, setConfig ] = useState({});
  const [ network, setNetwork ] = useState({});

  const coinIcon = <img alt='erg' src={ergLogo} />;
  const coinName = 'Ergo';
  const coinUrl = '/search/erg';
  const coinIntro = 'Ergo（ERG）是下一代智能合约平台，通过安全、可访问和去中心化的金融工具确保普通人的经济自由。'
  const coinIntroEn = 'Ergo is a next-generation smart contract platform that ensures the economic freedom of ordinary people through secure, accessible, and decentralized financial tools. '
  
  const getResPorts = async () => {
    try {
      const resPorts = (await getErgPorts()).data;
      return resPorts;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_SERVERLIST'));
    }
  }

  const getPoolStats = async () => {
    try {
      const poolStats = (await getErgPoolStats()).data['pool_statistics'];
      return poolStats;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_POOL'));
    }
  }

  const getNetwork = async () => {
    try {
      const network = (await getErgNetwork()).data;
      return network;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getPriceData = async () => {
    try {
      const priceData = (await getPrice()).data.data;
      return priceData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
    }
  }

  const getRewardData = async () => {
    try {
      const rewardData = (await getReward()).data.data;
      return rewardData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_REWARD_INFO'));
    }
  }

  const getConfig = async () => {
    try {
      const config = (await getErgConfig()).data;
      return config;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_CONFIG'));
    }
  }

  const toCoinName = (name) => {
    switch (name) {
      case 'global':
      case 'GLOBAL':
      case '9053':
      case 9053:
        return 'ERG';
      default:
        return name;
    }
  }

  const processData = async () => {
    const resPorts = (await getResPorts()) || {};
    const resPortsList = resPorts.global || [];
    let machines = 0;
    for (const port of resPortsList) {
      machines += port.miners;
    }
    const poolStats = (await getPoolStats()) || {};
    const network = (await getNetwork()) || {};
    const priceData = (await getPriceData()) || {};
    const rewardData = (await getRewardData()) || {};
    const config = (await getConfig()) || {};
    const coinListN = [];
    for (const port in poolStats.portCoinAlgo) {
      coinListN.push({
        name: toCoinName(port),
        algo: poolStats.portCoinAlgo[port],
        yield: port === '9053' ? '100%' : ((poolStats.coinProfit[port] || 0) * 100).toFixed(2) + '%',
        effort: (poolStats.currentEfforts[port] / (network[port] && network[port].difficulty) * 100).toFixed(2) + '%',
        miners: poolStats.portMinerCount[port] || 0,
        hashrate: CommonUtils.toHashrate(poolStats.portHash[port]),
        globalHashrate: CommonUtils.difftoHashrate((network[port] && network[port].difficulty), toCoinName(port)),
        height: (network[port] && network[port].height)
      })
    }
    setCoinList(coinListN);
    setGlobal({
      machines,
      hashrate: CommonUtils.difftoHashrate(network.difficulty),
      poolHashrate: CommonUtils.toHashrate(poolStats.hashRate),
      price: priceData['ERG']?.CNY,
      change: priceData['ERG']?.CNY_CH,
      reward: rewardData['ERG']?.reward,
      blocks: poolStats.totalBlocksFound + poolStats.totalAltBlocksFound,
      miners: poolStats.miners
    })
    setPoolStats(poolStats);
    setConfig(config);
    setNetwork(network);
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  const dataBoardLoaded = Object.keys(global).length !== 0;
  const blockBoardLoaded = Object.keys(poolStats).length !== 0 
    && Object.keys(config).length !== 0 && Object.keys(network).length !== 0;
  const txBoardLoaded = Object.keys(poolStats).length !== 0;

  return (
    <>
    <CoinIntro coinIcon={coinIcon} coinName={coinName} coinIntro={ intl.options.currentLocale === 'en-US' ? coinIntroEn : coinIntro } coinUrl={coinUrl}
    explorer={"https://explorer.ergoplatform.com/"} tutorial={"https://www.yuque.com/skypool/cpu/kbyfcg"}/>
    <SearchBar type="erg" />
    <ServerList />
    { dataBoardLoaded ? <DataBoard coinList={coinList} global={global} /> : 
      <div className='spin-box'><Spin /></div> }
    { blockBoardLoaded ? <BlockBoard poolStats={poolStats} config={config} network={network} /> :
      <div className='spin-box'><Spin /></div> }
    { txBoardLoaded ? <TxBoard poolStats={poolStats} /> :
      <div className='spin-box'><Spin /></div> }
    </>
  )
}
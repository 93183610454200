import { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import CoinIntro from '../searchCommon/coinIntro';
import xmrLogo from '../../assets/monero-xmr-logo.svg';
import intl from 'react-intl-universal';
import {
  getCpuPorts,
  getCpuPoolStats,
  getCpuNetwork,
  getCpuConfig,
  getPrice
} from '../../api/ajaxMain';
import { CommonUtils } from '../../utils/commonUtils';
import SearchBar from '../searchBar/searchBar';
import ServerList from '../../components/searchCpu/serverList';
import DataBoard from '../../components/searchCpu/dataBoard';
import CoinBoard from '../../components/searchCpu/coinBoard';
import BlockBoard from '../../components/searchCpu/blockBoard';
import TxBoard from '../../components/searchCpu/txBoard';

export default function CoinInfo ({ change }) {

  const [ global, setGlobal ] = useState({});
  const [ coinList, setCoinList ] = useState([]);
  const [ poolStats, setPoolStats ] = useState({});
  const [ config, setConfig ] = useState({});
  const [ network, setNetwork ] = useState({});

  const coinIcon = <img alt='xmr' src={xmrLogo} />;
  const coinName = 'Monero';
  const coinUrl = '/search/cpu';
  const coinIntro = 'Monero（XMR，门罗币）是目前市值最大的隐私币，门罗币通过 CPU 挖矿获得，无法查看他人钱包地址的余额和交易记录。\n天池机枪池同时连接多个 CPU 挖矿币种，根据当前矿机各个算法的 <算力，全网难度，币价> 计算并自动切换到当前收益最高的 CPU 币种，帮助矿工获取 CPU 挖矿的最大收益，程序自动化在交易所兑换山寨币为门罗币给矿工结算。'
  const coinIntroEn = 'Monero is the dominant private blockchain currently. Monero is obtained by CPU mining and others\' balance and transaction history can not be accessed.\n Skypool CPU Mining Solution connects to multiple CPU mining blockchains and is able to connect to the highest reward one automatically. The reward is converted to Monero for miners. '
  
  const getResPorts = async () => {
    try {
      const resPorts = (await getCpuPorts()).data;
      return resPorts;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_SERVERLIST'));
    }
  }

  const getPoolStats = async () => {
    try {
      const poolStats = (await getCpuPoolStats()).data['pool_statistics'];
      return poolStats;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_POOL'));
    }
  }

  const getNetwork = async () => {
    try {
      const network = (await getCpuNetwork()).data;
      return network;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getPriceData = async () => {
    try {
      const priceData = (await getPrice()).data.data;
      return priceData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
    }
  }

  const getConfig = async () => {
    try {
      const config = (await getCpuConfig()).data;
      return config;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_CONFIG'));
    }
  }

  const toCoinName = (name) => {
    switch (name) {
      case 'global':
      case 'GLOBAL':
      case '18081':
      case 18081:
        return 'XMR';
      case 'altblocks':
      case '18082':
      case 18082:
        return 'ALT-COINS';
      case '19994':
      case 19994:
        return 'ARQ';
      case '18090':
      case 18090:
        return 'QRL';
      case '17767':
      case 17767:
        return 'ZEPH';
      case '17750':
      case 17750:
        return 'XHV';
      default:
        return name;
    }
  }

  const processData = async () => {
    const resPorts = (await getResPorts()) || {};
    const resPortsList = resPorts.global || [];
    let machines = 0;
    for (const port of resPortsList) {
      machines += port.miners;
    }
    const poolStats = (await getPoolStats()) || {};
    const network = (await getNetwork()) || {};
    const priceData = (await getPriceData()) || {};
    const config = (await getConfig()) || {};
    const coinListN = [];
    for (const port in poolStats.portCoinAlgo) {
      if (parseInt(port) === 34568) continue;
      if (parseInt(port) === 17402) continue;
      coinListN.push({
        name: toCoinName(port),
        algo: poolStats.portCoinAlgo[port],
        yield: port === '18081' ? '100%' : ((poolStats.coinProfit[port] || 0) * 100).toFixed(2) + '%',
        effort: (poolStats.currentEfforts[port] / (network[port] && network[port].difficulty) * 100).toFixed(2) + '%',
        miners: poolStats.portMinerCount[port] || 0,
        hashrate: CommonUtils.toHashrate(poolStats.portHash[port]),
        globalHashrate: CommonUtils.difftoHashrate((network[port] && network[port].difficulty), toCoinName(port)),
        height: (network[port] && network[port].height)
      })
    }
    coinListN.splice(0, 0, coinListN[2]);
    coinListN.splice(3, 1);
    setCoinList(coinListN);
    setGlobal({
      machines,
      hashrate: CommonUtils.difftoHashrate(network.difficulty),
      poolHashrate: CommonUtils.toHashrate(poolStats.hashRate),
      price: priceData['XMR']?.CNY,
      change: priceData['XMR']?.CNY_CH,
      blocks: poolStats.totalBlocksFound + poolStats.totalAltBlocksFound,
      miners: poolStats.miners
    })
    setPoolStats(poolStats);
    setConfig(config);
    setNetwork(network);
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  const dataBoardLoaded = Object.keys(global).length !== 0;
  const coinBoardLoaded = coinList.length !== 0;
  const blockBoardLoaded = Object.keys(poolStats).length !== 0 
    && Object.keys(config).length !== 0 && Object.keys(network).length !== 0;
  const txBoardLoaded = Object.keys(poolStats).length !== 0;

  return (
    <>
    <CoinIntro coinIcon={coinIcon} coinName={coinName} coinIntro={ intl.options.currentLocale === 'en-US' ? coinIntroEn : coinIntro } coinUrl={coinUrl}
    originalSite={"https://cpu.skypool.org/"} explorer={"https://xmrchain.net/"} tutorial={"https://www.yuque.com/skypool/cpu/wu8sx8"}/>
    <SearchBar type="xmr" />
    <ServerList />
    { dataBoardLoaded ? <DataBoard coinList={coinList} global={global} /> : 
      <div className='spin-box'><Spin /></div> }
    { coinBoardLoaded ? <CoinBoard coinList={coinList} /> :
      <div className='spin-box'><Spin /></div> }
    { blockBoardLoaded ? <BlockBoard poolStats={poolStats} config={config} network={network} /> :
      <div className='spin-box'><Spin /></div> }
    { txBoardLoaded ? <TxBoard poolStats={poolStats} /> :
      <div className='spin-box'><Spin /></div> }
    </>
  )
}
import { useState, useEffect } from 'react';
import { Spin, message } from 'antd';
import intl from 'react-intl-universal';

import ServerList from './serverList';
import DataBoard from './dataBoard';
import GraphBoard from './graphBoard';
import CoinIntro from '../searchCommon/coinIntro';
import SearchBar from '../searchBar/searchBar';

import {
  getNimiqNodeList,
  getNimiqGlobalData,
  getPrice,
  getNimiqPageData,
} from '../../api/ajaxMain';

export default function CoinInfo ({coinIntroInfo, urlName, change}) {

  const [serverListData, setServerListData] = useState([]);
  const [globalData, setGlobalData] = useState({});
  const [priceData, setPriceData] = useState({});
  const [graphData, setGraphData] = useState({});
  const [dataBoardLoaded, setDataBoardLoaded] = useState(false);

  const {coinIcon, coinName, coinUrl, coinIntro} = coinIntroInfo;

  const getServerListData = async () => {
    try {
      const res = await getNimiqNodeList();
      if (res.status !== 200) {
        return message.error(intl.get('FAIL_TO_GET_SERVERLIST'));
      }
      setServerListData(res.data.data.nodeList);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_SERVERLIST'));
    }
  }

  const getDataBoardData = async () => {
    try {
      const res = await getNimiqGlobalData();
      if (res.status !== 200) {
        return message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
      }
      const resPrice = await getPrice();
      if (resPrice.status !== 200) {
        return message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
      }
      setGlobalData(res.data.data);
      setPriceData(resPrice.data.data);
      setDataBoardLoaded(true);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }

  }

  const getGraphData = async () => {
    try {
      const res = await getNimiqPageData();
      if (res.status !== 200) {
        return message.error(intl.get('FAIL_TO_GET_GRAPH'));
      }
      setGraphData(res.data.data);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GRAPH'));
    }
  }

  useEffect(() => {
    getServerListData();
    getDataBoardData();
    getGraphData();
  }, []);

  return (
    <>
    <CoinIntro coinIcon={coinIcon} coinName={coinName} coinIntro={coinIntro} coinUrl={coinUrl}
    originalSite={"https://nimiq.skypool.org/"} explorer={"https://nimiq.mopsus.com/"} tutorial={"https://www.yuque.com/skypool/nimiq-doc/nv5rxp"}/>
    <SearchBar type='nim' />
    {serverListData.length !== 0 ?
    <ServerList serverListData={serverListData} /> :
    <div className='spin-box'><Spin /></div>}
    {dataBoardLoaded ?
    <DataBoard change={change} nimGlobalData={globalData} priceData={priceData} /> :
    <div className='spin-box'><Spin /></div>}
    {Object.keys(graphData).length !== 0 ? 
    <GraphBoard resGraph={graphData} /> :
    <div className='spin-box'><Spin /></div>}
    </>
  )
}
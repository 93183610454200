import { useState, useEffect } from 'react';
import { Menu, Spin } from 'antd';
import { Area, Column } from '@ant-design/charts';
import { CommonUtils } from '../../utils/commonUtils';
import intl from 'react-intl-universal';

/*eslint-disable*/ 
export default function GraphBoard ({resGraph}) {

  const [currChart, setCurrChart] = useState('block');
  const [loaded, setLoaded] = useState(false);
  const [blockNumData, setBlockNumData] = useState([]);
  const [hashrateData, setHashrateData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [minerData, setMinerData] = useState([]);

  const changeChart = (e) => {
    setCurrChart(e.key);
  }

  const processData = () => {
    const blockNumber = [];
    let currentHour = new Date();
    currentHour.setDate(currentHour.getDate() - 1);
    currentHour = new Date(currentHour.getTime() + 60 * 60 * 1000);
    let blocks = 0;
    let reward = 0;
    let pool24hRewardPTR = resGraph.pool24hReward.length - 1;
    while (pool24hRewardPTR >= 0) {
      const currentTimestamp = new Date(resGraph.pool24hReward[pool24hRewardPTR].timestamp);
      if (currentTimestamp < currentHour) {
        blocks++;
        reward += resGraph.pool24hReward[pool24hRewardPTR].reward;
        pool24hRewardPTR--;
      } else {
        blockNumber.push({
          'time': CommonUtils.formatDateShort(new Date(currentHour.getTime())), 
          'blockNumber': blocks, 
          'reward': reward
        });
        blocks = 0;
        reward = 0;
        currentHour = new Date(currentHour.getTime() + 60 * 60 * 1000);
      }
    }
    setBlockNumData(blockNumber);
    const hashrateList = [];
    const machineList = [];
    const minerList = [];
    for (const item of resGraph.pool24hHashrate) {
      hashrateList.push({
        label: item.extra,
        time: CommonUtils.formatDateShort(new Date(item.timestamp)),
        value: parseFloat(CommonUtils.toMH(item.hashrate).slice(0, -5))
      });
      machineList.push({
        label: item.extra,
        time: CommonUtils.formatDateShort(new Date(item.timestamp)),
        value: item.machineNumber
      });
      minerList.push({
        label: item.extra,
        time: CommonUtils.formatDateShort(new Date(item.timestamp)),
        value: item.addressNumber
      })
    }
    setHashrateData(hashrateList);
    setMachineData(machineList);
    setMinerData(minerList);
  }

  useEffect(() => {
    processData();
    setLoaded(true);
  }, []);

  if (!loaded) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  // config of charts
  const configBlock = {
    data: blockNumData,
    xField: 'time',
    yField: 'blockNumber',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    tooltip: {
      formatter: (datum) => {
        return { name: intl.get('FOUND_BLOCKS'), value: `${datum.blockNumber}` };
      },
    },
  };

  const configHashrate = {
    data: hashrateData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    legend: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value.toFixed(2)} MH/s` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} MH/s`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  const configMachine = {
    data: machineData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    legend: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value} ${intl.get('TAI')}` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} ${intl.get('TAI')}`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  const configMiner = {
    data: minerData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    legend: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value} ${intl.get('GE')}` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} ${intl.get('GE')}`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  return (
    <>
    <section style={{marginBottom: '1.5rem'}} className='etc-btns-sec'>
      <Menu onClick={changeChart} selectedKeys={[currChart]} mode="horizontal">
        <Menu.Item key="block" >
          {intl.get('BLOCK_NUMBER')}
        </Menu.Item>
        <Menu.Item key="hashrate" >
          {intl.get('HASHRATE')}
        </Menu.Item>
        <Menu.Item key="machine">
          {intl.get('MACHINE')}
        </Menu.Item>
        <Menu.Item key="miner">
          {intl.get('MINER')}
        </Menu.Item>
      </Menu>
    </section>
    <section className='graph-sec'>
      <div style={{margin: '0'}} className='graph-panel'>
        <div className='graph-title'>
          {
            currChart === 'block' &&
            intl.get('BLOCKS_FOUND')
          }
          {
            currChart === 'hashrate' &&
            intl.get('HASHRATE_VS_TIME')
          }
          {
            currChart === 'machine' &&
            intl.get('MACHINE_VS_TIME')
          }
          {
            currChart === 'miner' &&
            intl.get('MINER_VS_TIME')
          }
        </div>
        <div className='graph-con'>
          {
            currChart === 'block' &&
            <Column 
              {...configBlock} 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />
          }
          {
            currChart === 'hashrate' &&
            <Area
              {...configHashrate} 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />
          }
          {
            currChart === 'machine' &&
            <Area
              {...configMachine} 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />
          }
          {
            currChart === 'miner' &&
            <Area 
              {...configMiner} 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />
          }
        </div>
      </div>
    </section>
    </>
  )
}
import { useState } from 'react';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { emit } from '../../emit';
import {
  Popover,
  Drawer,
  Row,
  Col,
  Button
} from 'antd';
import { UserOutlined, MenuOutlined, GlobalOutlined, SwapOutlined, DollarOutlined, PayCircleOutlined, CalculatorOutlined } from '@ant-design/icons';
import store from 'store';
import Calculator from '../calculator/calculator';

import logo128 from '../../assets/logo128.png';

import './commonHeader.css';
import memoryUtils from '../../utils/memoryUtils';
import { reqLogout } from '../../api/ajaxAuth';
import { setNotLogIn } from '../../utils/memory';

function CommonHeader({ changeCry }) {

  const [smVisible, setSmVisible] = useState(false);
  const [mode, setMode] = useState(memoryUtils.mode);
  const [currency, setCurrency] = useState(memoryUtils.currency);
  const [visible, setVisible] = useState(false);

  const handleChange = (val) => {
    emit.emit('change_language', val);
    window.location.reload();
  }

  const showSmDrawer = () => {
    setSmVisible(true);
  }

  const changeLang = () => {

    handleChange(intl.options.currentLocale === 'en-US' ? 'zh-CN' : 'en-US');
    setSmVisible(false);
    window.location.reload();
  }

  const changeMode = () => {
    let root = document.documentElement;
    if (mode === 'DARK') {
      memoryUtils.mode = 'LIGHT';
      store.set('mode', 'LIGHT');
      root.className = 'light';
      setMode('LIGHT');
    } else { // mode === 'LIGHT'
      memoryUtils.mode = 'DARK';
      store.set('mode', 'DARK');
      root.className = 'night';
      setMode('DARK');
    }
  }

  const changeCurrency = () => {
    if (currency === 'USD') {
      memoryUtils.currency = 'CNY';
      store.set('currency', 'CNY');
      setCurrency('CNY');
      changeCry();
    } else { // currency === 'CNY'
      memoryUtils.currency = 'USD';
      store.set('currency', 'USD');
      setCurrency('USD');
      changeCry();
    }
  }

  const logout = async () => {
    await reqLogout();
    await setNotLogIn();
    window.location.reload();
  }

  const langContent = (
    <div>
      <div onClick={() => handleChange('en-US')} className='content-block'>
        <span>English</span>
      </div>
      <div onClick={() => handleChange('zh-CN')} className='content-block'>
        <span>中文</span>
      </div>
    </div>
  );

  return (
    <div className='common-header'>
      <nav className='sides'>
        <div className='common-logo-con'>
          <a href='/'><img className='logo-con' alt="logo" src={logo128} /></a>
        </div>
        <div className='side-btns-con nine-hide'>
          <div onClick={() => setVisible(true)} className='nav-btn'>
            <CalculatorOutlined style={{ fontSize: '17px', marginRight: '5px' }} />
            <span className='nav-btn-text'>{intl.get('CALCULATOR_H')}</span>
          </div>
          <div className='nav-btn'>
            <Popover placement="bottom" content={langContent}>
              <GlobalOutlined
                style={{ fontSize: '17px', marginRight: '5px' }}
              /><span className='nav-btn-text'>{intl.get('LANGUAGE')}</span>
            </Popover>
          </div>
          <div onClick={changeMode} className='nav-btn'>
            <SwapOutlined style={{ fontSize: '17px', marginRight: '5px' }} />
            <span className='nav-btn-text'>{intl.get(mode)}</span>
          </div>
          <div onClick={changeCurrency} className='nav-btn'>
            {currency === 'USD' ?
              <DollarOutlined style={{ fontSize: '17px', marginRight: '5px' }} /> :
              <PayCircleOutlined style={{ fontSize: '17px', marginRight: '5px' }} />}
            <span className='nav-btn-text'>{currency}</span>
          </div>
          {memoryUtils.userMain.isLogIn &&
            <div onClick={logout} className='nav-btn'>
              <UserOutlined style={{ fontSize: '17px', marginRight: '5px' }} />
              <span className='nav-btn-text'>{intl.get('SIGN_OUT')}</span>
            </div>}
          {!memoryUtils.userMain.isLogIn &&
            <div style={{ display: 'flex', fontSize: '18px', color: 'white' }}>
              <a className='user-a' href='/login'><div className='user-a'>{intl.get('SIGN_IN')}</div></a>
              {` | `}
              <a className='user-a' href='/register'><div className='user-a'>{intl.get('REG')}</div></a>
            </div>
          }
        </div>
        <div onClick={showSmDrawer} className='menu-con nine-show'>
          <MenuOutlined style={{ color: 'white', fontSize: '30px', lineHeight: '64px' }} />
        </div>
      </nav>
      <nav className='center-nav nine-hide'>
        <div className='center-btns'>
          <a className='nav-btn-a' href='/userCenter'>
            <span className='nav-btn-a-text'>{intl.get('USER_CENTER')}</span>
          </a>
          <a className='nav-btn-a' href='/tutorial'>
            <span className='nav-btn-a-text'>{intl.get('HELP_CENTER')}</span>
          </a>
        </div>
      </nav>
      <Drawer
        title={intl.get('MENU')}
        placement='bottom'
        closable={false}
        onClose={() => setSmVisible(false)}
        visible={smVisible}
      >
        <Row style={{ marginBottom: '10px' }}>
          <Col span={12}>
            <Button style={{ width: '96%', margin: '0 auto' }}>
              <a href='/tutorial' rel="noreferrer" target='_blank'>
                {intl.get('HELP_CENTER')}
              </a>
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={changeLang}
              style={{ width: '96%', margin: '0 auto' }}
            >
              <GlobalOutlined />
              {intl.get('LANGUAGE')}
            </Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={12}>
            <Button
              onClick={changeMode}
              style={{ width: '96%', margin: '0 auto' }}
            >
              <SwapOutlined />
              {intl.get(mode)}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={changeCurrency}
              style={{ width: '96%', margin: '0 auto' }}
            >
              {currency === 'USD' ?
                <DollarOutlined /> :
                <PayCircleOutlined />}
              {currency}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Button
              href='/userCenter'
              style={{ width: '96%', margin: '0 auto' }}
            >
              <UserOutlined />
              {intl.get('USER_CENTER')}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => setVisible(true)}
              style={{ width: '96%', margin: '0 auto' }}
            >
              <CalculatorOutlined />
              {intl.get('CALCULATOR_H')}
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Calculator visible={visible} onCancel={() => setVisible(false)} />
    </div>
  )

}

export default withRouter(CommonHeader);
import { useState, useEffect } from 'react';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import MainTable from '../../components/homePage/mainTable';
import announceHelpers from '../../components/announce/announce.jsx';
import intl from 'react-intl-universal';

export default function HomePage () {

  const [ announceList, setAnnounceList ] = useState([]);
  const [ change, setChange ] = useState(0);
  const [ loaded, setLoaded ] = useState(false);

  const changeCry = () => {
    setChange(change + 1);
  }

  useEffect(() => {
    const en = intl.options.currentLocale === 'en-US';
    announceHelpers.getAnnounce(en, 'main', setAnnounceList);
    // eslint-disable-next-line
  }, []);

  return (
    <div className='homePage'>
      { loaded && <CommonHeader changeCry={changeCry} /> }
      { announceHelpers.showAnnounce(announceList) }
      <MainTable change={change} setLoaded={setLoaded} />
      { loaded && <CommonFooter /> }
    </div>
  )
}
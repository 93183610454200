/*eslint-disable*/
import axios from 'axios';
import config from '../config';

/* GLOBAL */

export const getPrice = async () =>
  await axios.get(`${config.mainApi}/v1/info/getPrice`, {
    params: {},
    withCredentials: false,
    timeout: 30000,
  });

export const getHashrate = async () =>
  await axios.get(`${config.mainApi}/v1/info/getHashrate`, {
    params: {},
    withCredentials: false,
    timeout: 30000,
  });

export const getReward = async () =>
  await axios.get(`${config.mainApi}/v1/info/getReward`, {
    params: {},
    withCredentials: false,
    timeout: 30000,
  });

export const getChangeRate = async () =>
  await axios.get(`${config.mainApi}/v1/info/getChangeRate`, {
    params: {},
    withCredentials: false,
    timeout: 30000,
  })

export const getAnnounce = async (page) =>
  await axios.get(`${config.mainApi}/v1/info/getAnnounce`, {
    params: { page },
    withCredentials: false,
    timeout: 30000,
  });

/* NIM */

export const getNimiqPageData = async () =>
  await axios.get(`${config.nimiqApi}/api/v1/pool/globalPoolProfile`, {
    params: {},
    withCredentials: false
  })

export const getNimiqGlobalData = async () =>
  await axios.get(`${config.nimiqApi}/api/v1/pool/poolProfile`, {
    params: {},
    withCredentials: false
  })

export const getNimiqNodeList = async () =>
  await axios.get(`${config.nimiqApi}/api/v1/pool/nodeProfile`, {
    params: {},
    withCredentials: false
  })

export const getBasicDataNim = async (addr) =>
  await axios.get(`${config.nimiqApi}/api/v1/pool/profile?address=${addr}`, {
    params: {},
    withCredentials: false,
  });

/* RVN */

export const getRvnGlobalData = async () =>
  await axios.get(`${config.rvnApi}/api/stats`, {
    params: {},
    withCredentials: false
  })

export const getRvnChartData = async () =>
  await axios.get(`${config.rvnApi}/api/pool_stats`, {
    params: {},
    withCredentials: false
  })

export const getRvnBlockList = async () =>
  await axios.get(`${config.rvnApi}/api/blocks`, {
    params: {},
    withCredentials: false
  })

export const getRvnWorkers = async (addr) =>
  await axios.get(`${config.rvnApi}/api/worker_stats?${addr}`, {
    params: {},
    withCredentials: false
  })

export const getRvnTransactions = async (addr) =>
  await axios.get(`${config.rvnApi}/api/payments?${addr}`, {
    params: {},
    withCredentials: false
  })

/* ERG */

export const getErgPorts = async () =>
  await axios.get(`${config.ergApi}/pool/ports`, {
    params: {},
    withCredentials: false
  })

export const getErgBlocks = async (page, limit) =>
  await axios.get(`${config.ergApi}/pool/blocks?`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getErgNetwork = async () =>
  await axios.get(`${config.ergApi}/network/stats`, {
    params: {},
    withCredentials: false
  })

export const getErgConfig = async () =>
  await axios.get(`${config.ergApi}/config`, {
    params: {},
    withCredentials: false
  })

export const getErgPoolStats = async () =>
  await axios.get(`${config.ergApi}/pool/stats`, {
    params: {},
    withCredentials: false
  })

export const getErgTxns = async (page, limit) =>
  await axios.get(`${config.ergApi}/pool/payments`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getErgAllWorkers = async (addr) =>
  await axios.get(`${config.ergApi}/miner/${addr}/stats/allWorkers`, {
    params: {},
    withCredentials: false,
  });

export const getErgOfflineWorkers = async (addr) =>
  await axios.get(`${config.ergApi}/miner/${addr}/stats/allOfflineWorkers`, {
    params: {},
    withCredentials: false,
  });

export const getErgGraphData = async (addr) =>
  await axios.get(`${config.ergApi}/miner/${addr}/chart/hashrate`, {
    params: {},
    withCredentials: false
  });

export const getErgGlobalData = async (addr) =>
  await axios.get(`${config.ergApi}/miner/${addr}/stats`, {
    params: {},
    withCredentials: false
  });

export const getErgTransactions = async (addr) =>
  await axios.get(`${config.ergApi}/miner/${addr}/payments`, {
    params: {},
    withCredentials: false
  });

/* ETC */
export const getEtcStats = async () =>
  await axios.get(`${config.etcApi}/api/stats`, {
    params: {},
    withCredentials: false,
    timeout: 30000,
  })

export const getEtcBlocks = async () =>
  await axios.get(`${config.etcApi}/api/blocks`, {
    params: {},
    withCredentials: false,
    timeout: 30000,
  })

  export const getEtcHistory = async () =>
    await axios.get(`${config.etcApi}/api/history`, {
      params: {},
      withCredentials: false,
      timeout: 30000,
    })

  export const getEtcAccount = async (account) =>
    await axios.get(`${config.etcApi}/api/accounts/${account}`, {
      params: {},
      withCredentials: false,
      timeout: 30000,
    })

  export const getEtcAccountHistory = async (account) =>
    await axios.get(`${config.etcApi}/api/accountHistory/${account}`, {
      params: {},
      withCredentials: false,
      timeout: 30000,
    })

    export const getEtcPayments = async () =>
    await axios.get(`${config.etcApi}/api/payments`, {
      params: {},
      withCredentials: false,
      timeout: 30000,
    })


/* CPU */

export const getCpuPorts = async () =>
  await axios.get(`${config.skypoolApi}/pool/ports`, {
    params: {},
    withCredentials: false
  })

export const getXmrBlocks = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/blocks?`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getCpuNetwork = async () =>
  await axios.get(`${config.skypoolApi}/network/stats`, {
    params: {},
    withCredentials: false
  })

export const getCpuConfig = async () =>
  await axios.get(`${config.skypoolApi}/config`, {
    params: {},
    withCredentials: false
  })

export const getCpuPoolStats = async () =>
  await axios.get(`${config.skypoolApi}/pool/stats`, {
    params: {},
    withCredentials: false
  })

export const getAltBlocks = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/altblocks`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getWowBlocks = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/coin_altblocks/34568`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getArqBlocks = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/coin_altblocks/19994`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getQrlBlocks = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/coin_altblocks/18090`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getXhvBlocks = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/coin_altblocks/17750`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getZephBlocks = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/coin_altblocks/17767`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getCpuTxns = async (page, limit) =>
  await axios.get(`${config.skypoolApi}/pool/payments`, {
    params: {
      page,
      limit
    },
    withCredentials: false
  })

export const getAllWorkers = async (addr) =>
  await axios.get(`${config.skypoolApi}/miner/${addr}/stats/allWorkers`, {
    params: {},
    withCredentials: false,
  });

export const getOfflineWorkers = async (addr) =>
  await axios.get(`${config.skypoolApi}/miner/${addr}/stats/allOfflineWorkers`, {
    params: {},
    withCredentials: false,
  });

export const getGraphData = async (addr) =>
  await axios.get(`${config.skypoolApi}/miner/${addr}/chart/hashrate`, {
    params: {},
    withCredentials: false
  });

export const getGlobalData = async (addr) =>
  await axios.get(`${config.skypoolApi}/miner/${addr}/stats`, {
    params: {},
    withCredentials: false
  });

export const getTransactions = async (addr) =>
  await axios.get(`${config.skypoolApi}/miner/${addr}/payments`, {
    params: {},
    withCredentials: false
  });

export const postThreshold = async (threshold, isDailyPay, addr) =>
  await axios.post(`${config.skypoolApi}/user/${addr}/threshold`,
    {
      threshold,
      isDailyPay
    },
    { withCredentials: false }
  )

export const getThreshold = async (addr) =>
  await axios.get(`${config.skypoolApi}/user/${addr}/threshold`, {
    params: {},
    withCredentials: false
  })

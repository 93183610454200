import config from '../config';
import axios from 'axios';

// 用户中心

export const bindEmailOrPhone = async (type, bind, code) =>
  await axios.post(`${config.mainApi}/v1/account/bindEmailOrPhone`, {
    type,
    bind,
    code,
    withCredentials: true
  })

export const sendCode = async (type, to, lang) =>
  await axios.get(`${config.mainApi}/v1/account/sendCode`, {
    params: { type, to, lang },
    withCredentials: true
  })

export const getAccountInfo = async () =>
  await axios.get(`${config.mainApi}/v1/account/getAccountInfo`, {
    params: {},
    withCredentials: true
  })

export const getFollowList = async (page, limit) =>
  await axios.get(`${config.mainApi}/v1/account/getFollowList`, {
    params: { page, limit },
    withCredentials: true
  })

export const updateFollowList = async (currency, comment, address, page, limit) =>
  await axios.post(`${config.mainApi}/v1/account/updateFollowList`, {
    currency,
    comment,
    address,
    page,
    limit,
    withCredentials: true
  })

export const shortenFollowList = async (address, page, limit) =>
  await axios.get(`${config.mainApi}/v1/account/shortenFollowList`, {
    params: { address, page, limit },
    withCredentials: true
  })
import { useEffect } from 'react';
import { Row, Col } from 'antd';
import memoryUtils from '../../utils/memoryUtils';
import intl from 'react-intl-universal';

export default function DataBoard ({ global, change, coinList }) {

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  useEffect(() => {

  }, [change]);

  return (
    <>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('GLOBAL_DATA')}
      </div>
      <div
        className='data-board'
        style={{padding: '15px 0 5px'}}
      >
        <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('GLOBAL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                  {global.hashrate}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('HEIGHT')}
                </div>
                <div className='data-board-item-figure'>
                  {coinList[0].height}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('DAILY_REWARD')}
                </div>
                <div className='data-board-item-figure'>
                {global.reward} <span className='unit'> ERG/M</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                {intl.get('PRICE')}                               
                {
                  global.change >= 0 ? 
                  <span style={{marginLeft: '3px', color: 'green'}}>
                    {`+${global.change}`}%
                  </span> : 
                  <span style={{marginLeft: '3px', color: 'red'}}>
                    {global.change}%
                  </span>
                }
                </div>
                <div className='data-board-item-figure'>
                  {crySign + (parseFloat(global.price) / exchange).toFixed(3)}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('POOL_DATA')}
      </div>
        <div 
          className='data-board'
          style={{padding: '15px 0 5px'}}
        >
          <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
            <Row>
              <Col sm={{span:12}} lg={{span:6}}>
                <div className='data-board-item'>
                  <div className='data-board-item-title'>
                  {intl.get('POOL_HASHRATE')}
                  </div>
                  <div className='data-board-item-figure'>
                    {global.poolHashrate}
                  </div>
                </div>
              </Col>
              <Col sm={{span:12}} lg={{span:6}}>
                <div className='data-board-item'>
                  <div className='data-board-item-title'>
                    {intl.get('ONLINE_MINERS')}
                  </div>
                  <div className='data-board-item-figure'>
                    {global.miners}
                  </div>
                </div>
              </Col>
              <Col sm={{span:12}} lg={{span:6}}>
                <div className='data-board-item'>
                  <div className='data-board-item-title'>
                  {intl.get('ONLINE_MACHINES')}
                  </div>
                  <div className='data-board-item-figure'>
                    {global.machines}
                  </div>
                </div>
              </Col>
              <Col sm={{span:12}} lg={{span:6}}>
                <div className='data-board-item'>
                  <div className='data-board-item-title'>
                  {intl.get('CURRENT_SHARES')}
                  </div>
                  <div className='data-board-item-figure'>
                    <span style={parseFloat(coinList[0].effort) >= 100.00 ? { color: 'red'} : { color: 'green' }}>{coinList[0].effort}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className='divider-line sm-hide'></div>
          <div style={{margin: '1rem 0 1.5rem'}}  className='data-board-row'>
            <Row>
              <Col sm={{span:12}} lg={{span:6}}>
                <div className='data-board-item'>
                  <div className='data-board-item-title'>
                    {intl.get('TRANSFER_TIME')}
                  </div>
                  <div className='data-board-item-figure'>
                    12:00 UTC+8
                  </div>
                </div>
              </Col>
              <Col sm={{span:12}} lg={{span:6}}>
                <div className='data-board-item'>
                  <div className='data-board-item-title'>
                  {intl.get('PAY_THRESHOLD')}
                  </div>
                  <div className='data-board-item-figure'>
                    1 ERG
                  </div>
                </div>
              </Col>
              <Col sm={{span:12}} lg={{span:6}}>
                <div className='data-board-item'>
                  <div className='data-board-item-title'>
                    {intl.get('FEE_RATE')}
                  </div>
                  <div className='data-board-item-figure'>
                    <span className='unit'>PPS+</span> 1.5%
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}
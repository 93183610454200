import { useState, useEffect } from 'react';
import { message, Spin, Badge } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  getEtcStats,
  getEtcAccount,
  getEtcAccountHistory,
  getPrice
} from '../../api/ajaxMain';
import intl from 'react-intl-universal';
import copy from 'copy-to-clipboard';
import FreshBtn from '../searchCommon/freshBtn';
import {CommonUtils} from '../../utils/commonUtils';
import {
  CopyOutlined
} from '@ant-design/icons';

import AccountDataBoard from './accountDataBoard';
import AccountGraphBoard from './accountGraphBoard';
import AccountTableBoard from './accountTableBoard';

import etcLogo from '../../assets/etc.png';

function AccountInfo ({address, history}) {
  
  const [ stats, setStats] = useState({});
  const [ account, setAccount ] = useState({});
  const [ accountHistory, setAccountHistory ] = useState([]);
  const [ priceData, setPriceData ] = useState({});
  const [ freshNum, setFreshNum ] = useState(-1);

  const getEtcStatsData = async () => {
    try {
      const res = await getEtcStats();
      setStats(res.data);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getEtcAccountData = async () => {
    try {
      const res = await getEtcAccount(address);
      setAccount(res.data);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_TX_LIST'));
    }
  }

  const getEtcAccountHistoryData = async () => {
    try {
      const res = await getEtcAccountHistory(address) || [];
      const parseData = res.data.map(v => {
        const splits = v.split(':');
        return {
          time: CommonUtils.formatDateShort(new Date(parseInt(splits[0]) * 1000)),
          machine: parseInt(splits[1]),
          hashrate: parseInt(splits[2]),
        }
      });
      setAccountHistory(parseData);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_TX_LIST'));
    }
  }


  const getPriceData = async () => {
    try {
      const res = await getPrice();
      setPriceData(res.data.data);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
    }
  }

  const refresh = (freshNum) => {
    setFreshNum(freshNum);
  }

  const getAllData = async () => {
    await Promise.all([
      getEtcStatsData(),
      getEtcAccountData(),
      getEtcAccountHistoryData(),
      getPriceData(),
    ]);
    message.success(intl.get('SUCCESS_TO_GET_ACCOUNT_DATA'));
  }

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [freshNum]);

  const dataBoardLoaded =  Object.keys(stats).length !== 0 && Object.keys(account).length !== 0 && Object.keys(priceData).length !== 0;
  const graphBoardLoaded = accountHistory.length !== 0;
  const tableBoardLoaded = Object.keys(account).length !== 0;

  return (
    <>
    <div className='coin-type-box'>
      <img onClick={() => {history.push('/search/etc'); window.location.reload();}} alt='etc' src={etcLogo} />
      <div onClick={() => {history.push('/search/etc'); window.location.reload();}} className='coin-type-box-title'>
        ETC
      </div>
      <FreshBtn refresh={refresh} />
    </div>
    <section className='account-info'>
      <Badge status='success'/> {address}            
      <CopyOutlined 
        style={{marginLeft: '1rem', cursor: 'pointer'}} 
        onClick={() => { copy(address); message.success(intl.get('COPIED_TO_CLIPBOARD')) }}
      />
    </section>
    { dataBoardLoaded ? 
      <AccountDataBoard stats={stats} account={account} priceData={priceData} /> : 
      <div className='spin-box'><Spin /></div> }
    { graphBoardLoaded ?
      <AccountGraphBoard accountHistory={accountHistory} /> :
      <div className='spin-box'><Spin /></div> }
    { tableBoardLoaded ?
      <AccountTableBoard account={account} /> :
      <div className='spin-box'><Spin /></div> }
    </>
  )
}

export default withRouter(AccountInfo);
import { useState, useEffect } from 'react';
import './tutorial.css';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import { Divider, Typography } from 'antd';
const { Title, Paragraph } = Typography;

export default function Tutorial({ history }) {

  const [checked, setChecked] = useState(false);

  const checkState = async () => {
    setChecked(true);
  }

  useEffect(() => {
    checkState();
    // eslint-disable-next-line
  }, []);

  if (!checked) return <></>

  return (
    <div className='userCenter'>
      <CommonHeader changeCry={() => { }} />
      <div className='userCenter-con'>
        <Typography>
          <Title>新手如何在天池矿池选择币种</Title>
          <Paragraph>
          更新时间：2024年2月22日
          </Paragraph>
          <Divider/>
          <Paragraph>
            <ul>
              <li>
                <a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=kR9K-s4fbhS_q6QBWZ-9djcS1U_7wkbD&authKey=OF4dLSHnP9BcPye9MXCUbq6bf4wknV9zCySGOZJlxXDAceIR3JVxDj7ZC8SHXvbz&noverify=0&group_code=631930869" target="_blank" rel="noreferrer">天池矿池QQ群①：631930869（已满）</a>
              </li>
              <li>
                <a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=jToSLlNRtdV1Y5O4IlRRxA-q4lh9cOaJ&authKey=aw0QlORZSm4JBG0GScNZPk10yljvDiMtv9em3jyqVF%2B2dF%2FA2xIAMMFo1ZH8nWnK&noverify=0&group_code=246441770" target="_blank" rel="noreferrer">天池矿池QQ群②：246441770</a>
              </li>
              <li>
                <a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=o0Z-QDEK8sSz2uKLrxFkJ6ScGf20m5Hm&authKey=K2gweDxZ5wAibJP2v1zyfXd1dldvqxeeYCun6MwQaSh%2B1kwrJ5jh%2F86qA9%2BT0AcA&noverify=0&group_code=948383419" target="_blank" rel="noreferrer">网吧版QQ群：948383419</a>
              </li>
            </ul>
          </Paragraph>
          <Divider/>
          <Paragraph>
            天池建立于2018年，目前是包含了 CPU/GPU PoW 服务的综合性矿池。小白刚进来难以区分挖什么币，在这里做一下介绍。
          </Paragraph>
          <Paragraph>
            天池针对不同的硬件：
          <Divider/>
            <ol>
              <li>
                所有 CPU 设备，使用 <a href="/search/cpu" target="_blank">CPU 机枪池</a>，全自动切换当前最佳收益的 CPU 矿币，统一结算成门罗币 XMR 支付。
                <a href="https://www.yuque.com/skypool/cpu/wu8sx8" target="_blank" rel="noreferrer">点击查看 CPU 机枪池详细教程</a>
              </li>
              <Divider/>
              <li>
                显卡可以在天池挖以下显卡币种：
                <ul>
                  <li><a href="/search/etc" target="_blank">ETC 以太经典</a></li>
                  <li><a href="https://bar.skypool.xyz/" target="_blank" rel="noreferrer">CFX Conflux</a></li>
                </ul>
              </li>
              <Divider/>
              <li>
                3G 以下显存的低端显卡，推荐使用显卡挖 XHV 算法在 CPU 机枪池结算 XMR 门罗币，<a href="https://www.yuque.com/skypool/cpu/wu8sx8#EfUGP" target="_blank" rel="noreferrer">点击查看 XHV 详细教程</a>
              </li>
              <Divider/>
            </ol>
          </Paragraph>
        </Typography>
      </div>
      <CommonFooter />
    </div>
  )
}

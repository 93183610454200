import { useState, useEffect } from 'react';
import { Table, Menu, Spin } from 'antd';
import intl from 'react-intl-universal';

import { CommonUtils } from '../../utils/commonUtils';

/*eslint-disable*/ 
export default function AccountTableBoard ({res}) {

  const [currTable, setCurrTable] = useState('online');
  const [tableData, setTableData] = useState({});

  const changeTable = (e) => {
    setCurrTable(e.key);
  }

  const processData = () => {
    const allWorkerList = res.data.data.workers;
    let onlineList = [];
    let offlineList = [];
    let onlineCount = 0;
    let offlineCount = 0;
    for (const worker of allWorkerList) {
      let workerItem = {
        name: worker.name,
        hashrate: worker.currentAccepts / 300,
        hashrate24: (worker.totalAccepts + worker.totalExpired) / (24 * 60 * 60),
        hash24hValid: worker.totalAccepts,
        hash24hExpired: worker.totalExpired,
        hash24hInvalid: worker.totalInvalid,
        lastUpdate: worker.lastUpdate
      };
      if (worker.status === "online") {
        onlineList.push(workerItem);
        onlineCount = onlineCount + 1;
      } else {
        offlineList.push(workerItem);
        offlineCount = offlineCount + 1;
      }
    }

    const txList = res.data.data.withdrawHistory.slice(0, 30);
    const transList = [];
    for (const tx of txList) {
      let txItem = {
        time: tx.timestamp,
        value: tx.value,
        fee: tx.fee,
        txHash: tx.txHash
      };
      transList.push(txItem);
    }

    setTableData({
      onlineList,
      offlineList,
      onlineCount,
      offlineCount,
      transList
    });
  }

  const onlineColumns = [
    {
      title: intl.get('MACHINE'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name)
      },
      width: '80px'
    },
    {
      title: intl.get('CURRENT_HASHRATE'),
      dataIndex: 'hashrate',
      sorter: {
        compare: (a, b) => a.hashrate - b.hashrate
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.hashrate)
      },
    },
    {
      title: intl.get('24_HASHRATE'),
      dataIndex: 'hashrate24',
      sorter: {
        compare: (a, b) => a.hashrate24 - b.hashrate24
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.hashrate24)
      }
    },
    {
      title: intl.get('24_VALID_SHARES'),
      dataIndex: 'hash24hValid',
      sorter: {
        compare: (a, b) => a.hash24hValid - b.hash24hValid
      },
      render: (text, record, index) => {
        return (
          <span>
            {CommonUtils.parseHash(record.hash24hValid)}{' '}
            ({(
              record.hash24hValid * 100
              / (record.hash24hValid + record.hash24hInvalid + record.hash24hExpired)).toFixed(2)}%)
          </span>
        )
      }
    },
    {
      title: intl.get('24_EXPIRED_SHARES'),
      dataIndex: 'hash24hExpired',
      sorter: {
        compare: (a, b) => a.hash24hExpired - b.hash24hExpired
      },
      render: (text, record, index) => {
        return CommonUtils.parseHash(record.hash24hExpired)
      }
    },
    {
      title: intl.get('24_INVALID_SHARES'),
      dataIndex: 'hash24hInvalid',
      sorter: {
        compare: (a, b) => b.hash24hInvalid - a.hash24hInvalid
      },
      render: (text, record, index) => {
        return CommonUtils.parseHash(record.hash24hInvalid)
      },
    },
    {
      title: intl.get('LAST_UPDATE'),
      dataIndex: 'lastUpdate',
      sorter: {
        compare: (a, b) => b.lastUpdate - a.lastUpdate
      },
      render: (text, record, index) => {
        intl.options.currentLocale === 'en-US' ?
        CommonUtils.toUpdateTimeEn(record.lastUpdate / 1000) :
        CommonUtils.toUpdateTime(record.lastUpdate / 1000)
      }
    },
  ];

  const offlineColumns = [
    {
      title: intl.get('MACHINE'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name)
      },
      width: '80px'
    },
    {
      title: intl.get('CURRENT_HASHRATE'),
      dataIndex: 'hashrate',
      sorter: {
        compare: (a, b) => a.hashrate - b.hashrate
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.hashrate)
      },
    },
    {
      title: intl.get('24_HASHRATE'),
      dataIndex: 'hashrate24',
      sorter: {
        compare: (a, b) => a.hashrate24 - b.hashrate24
      },
      render: (text, record, index) => {
        return CommonUtils.toHashrate(record.hashrate24)
      }
    },
    {
      title: intl.get('24_VALID_SHARES'),
      dataIndex: 'hash24hValid',
      sorter: {
        compare: (a, b) => a.hash24hValid - b.hash24hValid
      },
      render: (text, record, index) => {
        return CommonUtils.parseHash(record.hash24hValid)
      },
    },
    {
      title: intl.get('24_EXPIRED_SHARES'),
      dataIndex: 'hash24hExpired',
      sorter: {
        compare: (a, b) => a.hash24hExpired - b.hash24hExpired
      },
      render: (text, record, index) => {
        return CommonUtils.parseHash(record.hash24hExpired)
      },
    },
    {
      title: intl.get('24_INVALID_SHARES'),
      dataIndex: 'hash24hInvalid',
      sorter: {
        compare: (a, b) => b.hash24hInvalid - a.hash24hInvalid
      },
      render: (text, record, index) => {
        return CommonUtils.parseHash(record.hash24hInvalid)
      },
    },
    {
      title: intl.get('LAST_UPDATE'),
      dataIndex: 'lastUpdate',
      sorter: {
        compare: (a, b) => b.lastUpdate - a.lastUpdate
      },
      render: (text, record, index) => {
        return intl.options.currentLocale === 'en-US' ? 
        CommonUtils.toUpdateTimeEn(record.lastUpdate / 1000) :
        CommonUtils.toUpdateTime(record.lastUpdate / 1000)
      }
    },
  ];

  const transactionColumns = [
    {
      title: intl.get('TIME'),
      dataIndex: 'time',
      sorter: {
        compare: (a, b) => a.time - b.time
      },
      render: (text, record, index) => {
        return CommonUtils.formatDate(new Date(record.time))
      }
    },
    {
      title: intl.get('VALUE'),
      dataIndex: 'value',
      sorter: {
        compare: (a, b) => a.value - b.value
      },
      render: (text, record, index) => {
        return `${(record.value / 1e5).toFixed(2)} NIM`
      }
    },
    {
      title: intl.get('TXHASH'),
      dataIndex: 'txHash',
      render: (text, record, index) => {
        return <a target='_blank' href={`https://nimiq.mopsus.com/tx/0x${record.txHash}`}>
          {`${record.txHash.slice(0, 12)}...${record.txHash.slice(-12)}`}
        </a>
      },
    },
  ];

  useEffect(() => {
    processData();
  }, [])

  if (Object.keys(tableData).length === 0) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  const { onlineList, offlineList, onlineCount, offlineCount, transList } = tableData;

  return (
    <>
    <section style={{marginBottom: '1.5rem'}} className='etc-btns-sec'>
      <Menu onClick={changeTable} selectedKeys={[currTable]} mode="horizontal">
        <Menu.Item key="online" >
          {intl.get('ONLINE')}({onlineCount && onlineCount})
        </Menu.Item>
        <Menu.Item key="offline" >
          {intl.get('OFFLINE')}({offlineCount && offlineCount})
        </Menu.Item>
        <Menu.Item key="transaction">
          {intl.get('TX_LIST')}
        </Menu.Item>
      </Menu>
    </section>
    <section className='table-sec'>
      <div className='table-panel'>
        <div style={{marginTop: '0'}} className='main-panel-table'>
          {
            currTable === 'online' &&
            <Table rowKey="name" columns={onlineColumns} dataSource={onlineList} pagination={false} />
          }
          {
            currTable === 'offline' &&
            <Table rowKey="name" columns={offlineColumns} dataSource={offlineList} pagination={false} />
          }
          {
            currTable === 'transaction' &&
            <Table rowKey="txHash" columns={transactionColumns} dataSource={transList} pagination={false} />
          }
        </div>              
      </div>
    </section>
    </>
  )
}
import { message, Spin, Menu } from 'antd';
import { useState, useEffect } from 'react';
import {CommonUtils} from '../../utils/commonUtils';
import { Area } from '@ant-design/charts';
import {
  getRvnBlockList,
  getRvnChartData
} from '../../api/ajaxMain';
import intl from 'react-intl-universal';

export default function GraphBoard () {

  const [currChart, setCurrChart] = useState('block');
  const [blockList, setBlockList] = useState([]);
  const [hashrateData, setHashrateData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [minerData, setMinerData] = useState([]);

  const changeChart = (e) => {
    setCurrChart(e.key);
  }

  const getResBlockList = async () => {
    try {
      const res = await getRvnBlockList();
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_BLOCK_RECORDS'));
    }
  }

  const getResChartData = async () => {
    try {
      const res = await getRvnChartData();
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GRAPH'));
    }
  }

  const processBlockData = async () => {
    const resBlockList = (await getResBlockList()) || [];
    const blockList = [];
    let count = 0;
    for (const block in resBlockList) {
      if (count >= 30) break;
      const blockData = resBlockList[block].split(':');
      blockList.push({
        height: blockData[2],
        hash: blockData[0],
        time: CommonUtils.formatDate(new Date(parseInt(blockData[4]))) 
      });
      count += 1;
    }
    setBlockList(blockList);
  }

  const processChartData = async () => {
    const resChartData = (await getResChartData()) || [];
    const hashrateData = [];
    const machineData = [];
    const minerData = [];
    for (const item of resChartData) {
      const data = item.pools.ravencoin;
      hashrateData.push({
        label: intl.get('HASHRATE'),
        time: CommonUtils.formatDateShort(new Date(item.time * 1000)),
        value: data.hashrate,
        // value: parseFloat(CommonUtils.toHashrate(data.hashrate).slice(0, -5))
      });
      machineData.push({
        label: intl.get('ONLINE_MACHINES'),
        time: CommonUtils.formatDateShort(new Date(item.time * 1000)),
        value: data.workerCount
      });
      minerData.push({
        label: intl.get('ONLINE_MINERS'),
        time: CommonUtils.formatDateShort(new Date(item.time * 1000)),
        value: data.minerCount
      })
    }
    setHashrateData(hashrateData);
    setMachineData(machineData);
    setMinerData(minerData);
  }

  useEffect(() => {
    processBlockData();
    processChartData();
    // eslint-disable-next-line
  }, []);

  const loaded = blockList.length && hashrateData.length 
    && machineData.length && minerData.length;
  if (!loaded) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  const configHashrate = {
    data: hashrateData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: CommonUtils.toHashrate(datum.value) };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return CommonUtils.toHashrate(value)
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  const configMachine = {
    data: machineData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value}` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} ${intl.get('TAI')}`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  const configMiner = {
    data: minerData,
    height: 400,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    isStack: false,
    smooth: true,
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: `${datum.value}` };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(value) {
          return `${value} ${intl.get('GE')}`;
        },
      },
    },
    legend: { 
      position: 'top'
    }
  };

  return (
    <>
    <section style={{marginBottom: '1.5rem'}} className='etc-btns-sec'>
      <Menu onClick={changeChart} selectedKeys={[currChart]} mode="horizontal">
        <Menu.Item key="block" >
          {intl.get('BLOCK')}
        </Menu.Item>
        <Menu.Item key="hashrate" >
          {intl.get('HASHRATE')}
        </Menu.Item>
        <Menu.Item key="machine">
          {intl.get('MACHINE')}
        </Menu.Item>
        <Menu.Item key="miner">
          {intl.get('MINER')}
        </Menu.Item>
      </Menu>
    </section>
    <section className='graph-sec'>
      {currChart === 'block' &&
        <div style={{ padding: '1rem 0', background: 'white' }} className='main-panel-table'>
          <table style={{width: '90%', margin: '0 auto'}}>
            <thead style={{backgroundColor: 'white', color: 'black'}} className='panel-table-thead'>
              <tr>
                <th className='sites-sec-th'>{intl.get('HEIGHT')}</th>
                <th className='sites-sec-th'>{intl.get('INFO_HASH')}</th>
                <th className='sites-sec-th'>{intl.get('TIME')}</th>
              </tr>
            </thead>
            <tbody>
              {
                blockList.map((item, key) => {
                  return (
                    <tr 
                      key={key}
                    >
                      <td className='addr sites-sec-td'>
                        {item.height}
                      </td>
                      <td className='addr sites-sec-td'>
                        <a 
                          target='_blank'
                          rel="noreferrer" 
                          href={`https://ravencoin.network/block/${item.hash}`}
                        >
                          {`${item.hash.slice(0, 5)}...${item.hash.slice(-12)}`}
                        </a>
                      </td>
                      <td className='addr sites-sec-td'>
                        {item.time}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      }

      { 
        currChart !== 'block' &&
        <div style={{margin: '0'}} className='graph-panel'>
        <div className='graph-title'>
          {
            currChart === 'hashrate' &&
            intl.get('HASHRATE_VS_TIME')
          }
          {
            currChart === 'machine' &&
            intl.get('MACHINE_VS_TIME')
          }
          {
            currChart === 'miner' &&
            intl.get('MINER_VS_TIME')
          }
        </div>
        <div className='graph-con'>

          {
            currChart === 'hashrate' &&
            <>
            <Area
              {...configHashrate}
              className='sm-hide' 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />    
            <Area
              {...configHashrate} 
              className='sm-show'
              style={{padding: '5px 20px 10px', height: '22rem'}}  
            /> 
            </>
          }
          {
            currChart === 'machine' &&
            <>
            <Area
              {...configMachine}
              className='sm-hide' 
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />  
            <Area
              {...configMachine} 
              className='sm-show'
              style={{padding: '5px 20px 10px', height: '22rem'}}  
            /> 
            </>
          }
          {
            currChart === 'miner' &&
            <>
            <Area 
              {...configMiner} 
              className='sm-hide'
              style={{padding: '0.5rem 3rem 1rem', height: '22rem'}}  
            />    
            <Area
              {...configMiner} 
              className='sm-show'
              style={{padding: '5px 20px 10px', height: '22rem'}}  
            /> 
            </>
          }
        </div>
      </div>
      }
    </section>
    </>
  )
}
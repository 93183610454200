import { 
  message, 
  Spin,
  Row,
  Col
} from 'antd';
import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import memoryUtils from '../../utils/memoryUtils';

import {
  getEtcStats,
  getPrice
} from '../../api/ajaxMain';
import {CommonUtils} from '../../utils/commonUtils';

export default function DataBoard ({ change }) {

  const [global, setGlobal] = useState({});
  const [pool, setPool] = useState({});

  const getEtcStatsData = async () => {
    try {
      const res = await getEtcStats();
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getPriceData = async () => {
    try {
      const res = await getPrice();
      return res?.data?.data || {};
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
    }
  }

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  const etcRewardPerBlock = 2.56;

  const processData = async () => {
    const etcStatsData = await getEtcStatsData();
    const priceData = (await getPriceData()) || {};
    setGlobal({
      hashrate: CommonUtils.difftoHashrate(Number(etcStatsData.nodes[0].difficulty), 'ETC'),
      lastTime: Number(etcStatsData.nodes[0].lastBeat),
      height: etcStatsData.nodes[0].height,
      difficulty: CommonUtils.parseHash(etcStatsData.nodes[0].difficulty),
      lastReward: etcRewardPerBlock,
      price: priceData['ETC']?.CNY,
      change: priceData['ETC']?.CNY_CH,
      rate: 'PPS+ 1.5%',
      algo: intl.get('GRAPHICS_CARD')
    });
    setPool({
      hashrate: CommonUtils.toHashrate(etcStatsData.hashrate),
      block: etcStatsData.maturedTotal + etcStatsData.immatureTotal + etcStatsData.candidatesTotal,
      miner: etcStatsData.minersTotal,
      machine: etcStatsData.rigs,
      reward: (etcRewardPerBlock * 8.64e10 * 0.985 / Number(etcStatsData.nodes[0].difficulty)).toFixed(6),
      effort: ((etcStatsData.stats.roundShares || 0) / Number(etcStatsData.nodes[0].difficulty) * 100).toFixed(2),
    });
  }

  useEffect(() => {
    if (change === 0) processData();
    // eslint-disable-next-line
  }, [change]);

  const loaded = Object.keys(global).length !== 0 && Object.keys(pool).length !== 0;
  if (!loaded) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  return (
    <>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('GLOBAL_DATA')} 
      </div>
      <div className='data-board'>
        <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                {intl.get('GLOBAL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                {global.hashrate.slice(0, -4)} 
                  <span className='unit'> 
                  {global.hashrate.slice(-4)} 
                  </span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('LAST_BLOCK')}
                </div>
                <div className='data-board-item-figure'>
                { intl.options.currentLocale === 'en-US' ?
                  CommonUtils.toUpdateTimeEn(Date.now() / 1000 - global.lastTime) :
                  CommonUtils.toUpdateTime(Date.now() / 1000 - global.lastTime) }
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('HEIGHT')}
                </div>
                <div className='data-board-item-figure'>
                {global.height}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('DIFFICULTY')}
                </div>
                <div className='data-board-item-figure'>
                {global.difficulty.slice(0, -3)} 
                  <span className='unit'> 
                  {global.difficulty.slice(-3)} 
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='divider-line sm-hide'></div>
        <div className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('BLOCK_REWARDS')}
                </div>
                <div className='data-board-item-figure'>
                {global.lastReward}<span className='unit'> ETC</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('DAILY_REWARD')}
                </div>
                <div className='data-board-item-figure'>
                {pool.reward} <span className='unit'> ETC/M</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('PRICE')}
                  { global.change > 0 ? <span style={{color: 'green', marginLeft: '3px'}}>
                    {`+${global.change}`}%</span> 
                    : <span style={{color: 'red', marginLeft: '3px'}}>{global.change}%</span>}
                </div>
                <div className='data-board-item-figure'>
                  {crySign + (parseFloat(global.price) / exchange).toFixed(3)}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('HARDWARE')}
                </div>
                <div className='data-board-item-figure'>
                  {global.algo}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('POOL_DATA')}
      </div>
      <div className='data-board'>
        <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('POOL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                {pool.hashrate && pool.hashrate.slice(0, -4)} 
                <span className='unit'> 
                {pool.hashrate && pool.hashrate.slice(-4)}
                </span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('TOTAL_BLOCKS')}
                </div>
                <div className='data-board-item-figure'>
                {pool.block}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('ONLINE_MINERS')}
                </div>
                <div className='data-board-item-figure'>
                {pool.miner}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('ONLINE_MACHINES')}
                </div>
                <div className='data-board-item-figure'>
                {pool.machine}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='divider-line sm-hide'></div>
        <div className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                {intl.get('CURRENT_SHARES')}
                </div>
                <div className='data-board-item-figure'>
                  <span style={pool.effort >= 100.00 ? { color: 'red'} : { color: 'green' }}>{pool.effort}%</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('TRANSFER_TIME')}
                </div>
                <div className='data-board-item-figure'>
                  12:00 UTC+8
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('PAY_THRESHOLD')}
                </div>
                <div className='data-board-item-figure'>
                0.1<span className='unit'> ETC</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('FEE_RATE')}
                </div>
                <div className='data-board-item-figure'>
                  <span className='unit'>PPS+</span> 1.5%
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  )
}

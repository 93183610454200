import { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { 
  UserOutlined, 
  LockOutlined,
  MessageOutlined
} from '@ant-design/icons';
import intl from 'react-intl-universal';

import {
  registerSendCode,
  registerLogin,
  loginByEmail
} from '../../api/ajaxAuth';
import memoryUtils from '../../utils/memoryUtils';

import './loginPage.css';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import { setLogIn } from '../../utils/memory';

export default function LoginPage ({ location, history }) {

  let emailInitial = null;
  if (location.search) {
    const query = location.search.slice(1);
    const arr = query.split('&');
    emailInitial = arr[0].split('=')[1];
  }

  const [ formType, setFormType ] = useState('email');
  const [ hasCheckedLogin, setHasCheckedLogin ] = useState(false);
  const [ email ] = useState(emailInitial);

  useEffect(() => {
    checkState();
    // eslint-disable-next-line
  }, [])

  const changeToPhone = () => {
    setFormType('phone');
  }

  const changeToEmail = () => {
    setFormType('email');
  }

  const sendCode = async () => {
    const codeButton = document.getElementById('code-button');
    codeButton.disabled = true;
    
    // send the code
    const phoneInput = document.getElementById('eth_login_phone');
    const phone = phoneInput.value;
    
    if (!phone) {
      codeButton.disabled = false;
      message.error(intl.get('PLEASE_INPUT_PHONE'));
      return;
    }

    const response = await registerSendCode(phone);
    if (response.data.success) {
      message.success(intl.get('SENT_SUCCESS'));
    } else {
      codeButton.disabled = false;
      return message.error(intl.get('SENT_FAIL'));
    }

    let seconds = 60;
    const timer = setInterval(() => {
      seconds--;
      codeButton.innerText = seconds + intl.get('SECONDS_UNTIL_RESEND');
      codeButton.disabled = true;
      if (!seconds) {
        clearInterval(timer);
        codeButton.innerText = intl.get('GET_SMS_CODE');
        codeButton.disabled = false;
      }
    }, 1000);
    
  }

  const onFinishPhone = async (values) => {
    const { phone, code } = values;
    const response = await registerLogin(phone, code);
    if (response.data.success) {
      message.success(intl.get('LOGIN_SUCCESS'));
      await setLogIn(phone);
      history.replace('/userCenter');
    } else {
      message.error(intl.get('CODE_INCORRECT'));
    }
  }

  const onFinishEmail = async (values) => {
    const { email, password } = values;
    const response = await loginByEmail(email, password);
    if (response.data.success) {
      message.success(intl.get('LOGIN_SUCCESS'));
      await setLogIn(email);
      history.replace('/userCenter');
    } else {
      message.error(intl.get('PASSWORD_INCORRECT'));
    }
  }

  const checkState = async () => {
    if (memoryUtils.userMain.isLogIn && memoryUtils.userMain.user) {
      await setLogIn(memoryUtils.userMain.user);
      history.replace('/userCenter');
    } else {
      setHasCheckedLogin(true);
    }
  }

  let loginPanel;
  const PLEASE_INPUT_EMAIL = intl.get('PLEASE_INPUT_EMAIL');
  const PLEASE_INPUT_PASSWORD = intl.get('PLEASE_INPUT_PASSWORD');
  const PLEASE_INPUT_PHONE = intl.get('PLEASE_INPUT_PHONE');
  const PLEASE_INPUT_SMS_CODE = intl.get('PLEASE_INPUT_SMS_CODE');
  const PASSWORD_LENGTH_ERROR = intl.get('PASSWORD_LENGTH_ERROR');

  if (formType === 'email') {
    loginPanel = (
      <section className='login-section'>
        <div className='login-container'>
          <div className='login-panel'>
            <div className='login-title'>
              {intl.get('WELCOME_WORD_MAIN')}
            </div>
            <div className='reg-link'>
              {intl.get('CHANGE_TO_PHONE')}
              {/*eslint-disable*/} 
              <a onClick={changeToPhone} className='learn-more'>{intl.get('CLICK_TO_CHANGE')}</a>
            </div>
            <Form
              name="eth_login"
              className="login-form"
              initialValues={{ email }}
              onFinish={onFinishEmail}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: PLEASE_INPUT_EMAIL }]}
              >
                <Input 
                  prefix={<UserOutlined style={{fontSize: '1.2rem'}} className="site-form-item-icon" />}
                  type='email'
                  style={{fontSize: '1.4rem'}}
                  placeholder={intl.get('EMAIL')}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: PLEASE_INPUT_PASSWORD },
                  { min: 6, message: PASSWORD_LENGTH_ERROR },
                  { max: 12, message: PASSWORD_LENGTH_ERROR }
                ]}
              >
                <Input
                  prefix={<LockOutlined style={{fontSize: '1.2rem'}} className="site-form-item-icon" />}
                  type="password"
                  style={{fontSize: '1.4rem'}}
                  placeholder={intl.get('PASSWORD')}
                />
              </Form.Item>
              <div className="form-bottom">
                <div className='login-button'>
                  <Form.Item id='login-form-button-con'>
                    <Button style={{ borderRadius: '1rem' }} size='large' type="primary" htmlType="submit" id="login-form-button">
                      {intl.get('LOGIN')}
                    </Button>
                    <span id='to-reg'><a href='/register'>{intl.get('TO_REGISTER')}</a></span>
                  </Form.Item>
                </div>
                <div className='forget-pwd'>
                  <a className="learn-more" href='/forgetpwd'>{intl.get('FORGET_PASSWORD')}</a>
                </div>
              </div>

            </Form>
          </div>
        </div>
      </section>
    );
  } else {
    loginPanel = (
      <section className='login-section'>
        <div className='login-container'>
          <div className='login-panel'>
            <div className='login-title'>
              {intl.get('WELCOME_WORD_MAIN')}
            </div>
            <div className='reg-link'>
              {intl.get('CHANGE_TO_EMAIL')}
              <a onClick={changeToEmail} className='learn-more'>{intl.get('CLICK_TO_CHANGE')}</a>
            </div>
            <Form
              name="eth_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinishPhone}
            >
              <Form.Item
                name="phone"
                rules={[{ required: true, message: PLEASE_INPUT_PHONE }]}
              >
                <Input 
                  prefix={
                    <div>
                      <UserOutlined style={{fontSize: '1.2rem'}} className="site-form-item-icon" />
                      <span style={{marginLeft: '1rem', fontSize: '1.4rem'}}>+86</span>
                    </div>
                  } 
                  placeholder={intl.get('PHONE')} 
                  style={{fontSize: '1.4rem'}}
                />
              </Form.Item>
              <div className='code-box'>
                <Form.Item
                  name="code"
                  style={{'width': '65%'}}
                  rules={[{ required: true, message: PLEASE_INPUT_SMS_CODE }]}
                >
                  <Input
                    prefix={<MessageOutlined style={{fontSize: '1.2rem'}} className="site-form-item-icon" />}
                    placeholder={intl.get('SMS_CODE')}
                    style={{fontSize: '1.4rem'}}
                  />
                </Form.Item>
                <div className='code-button'>
                  <Button onClick={sendCode} id='code-button'>{intl.get('GET_SMS_CODE')}</Button>
                </div>
              </div>
              
              <div className="form-bottom">
                <div className='login-button'>
                  <Form.Item id='login-form-button-con'>
                    <Button style={{ borderRadius: '1rem' }} size='large' type="primary" htmlType="submit" id="login-form-button">
                      {intl.get('REG_LOGIN')}
                    </Button>
                  </Form.Item>
                </div>
              </div>

            </Form>
          </div>
        </div>
      </section>
    );
  }

  if (!hasCheckedLogin) {
    return (
      <div></div>
    )
  }

  return (
    <div className='loginPageMain'>
      <CommonHeader />
      <div className='login-page'>
        {loginPanel}
      </div>
      <CommonFooter />
    </div>
  )
}
import intl from 'react-intl-universal';
import { message } from 'antd';
import {
  CopyOutlined
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';

export default function ServerList () {

  const nodeList =[
    {
      name: '矿机节点 Machine Validator',
      addr: 'stratum+tcp://sh.rvn.skypool.xyz:10000',
      diff: '0.1'
    },
    {
      name: '【SSL】矿机节点 SSL Machine Validator',
      addr: 'stratum+ssl://sh.rvn.skypool.xyz:10002',
      diff: '0.1'
    },
    {
      name: 'NiceHash Validator',
      addr: 'stratum+tcp://sh.rvn.skypool.xyz:10004',
      diff: '1'
    }
  ];

  return (
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('SERVERLIST')}
      </div>
      <div 
        style={{
          padding: '1rem 20px 1.5rem',
          background: 'white'
        }}
        className='main-panel-table'
      >
        <table style={{width: '100%'}} >
          <thead style={{backgroundColor: 'white', color: 'black'}} className='panel-table-thead'>
            <tr>
              <th className='sites-sec-th-center'>{intl.get('NAME')}</th>
              <th className='sites-sec-th-center'>{intl.get('SERVER')}</th>
              <th className='sites-sec-th-center'>{intl.get('DIFFICULTY')}</th>
            </tr>
          </thead>
          <tbody>
            {
              nodeList.map((item, key) => {
                return (
                  <tr 
                    key={key}
                  >
                    <td className='addr sites-sec-td-center'>
                      {item.name}
                    </td>
                    <td className='addr sites-sec-td-center'>
                      {item.addr}
                      <CopyOutlined 
                        style={{marginLeft: '10px', cursor: 'pointer'}} 
                        onClick={() => {copy(item.addr); message.success(intl.get('COPIED_TO_CLIPBOARD'))}}
                      />
                    </td>
                    <td className='addr sites-sec-td-center'>
                      {item.diff}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
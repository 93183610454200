import intl from 'react-intl-universal';

export default function CoinBoard ({ coinList }) {

  return (
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('COINS')}
      </div>
      <div 
        style={{
          padding: '25px 25px 25px',
          background: 'white'
        }}
        className='main-panel-table'
      >
        <table style={{width: '100%'}}>
          <thead style={{backgroundColor: 'white', color: 'black'}} className='panel-table-thead'>
            <tr>
              <th className='sites-sec-th'>{intl.get('COIN')}</th>
              <th className='sites-sec-th'>{intl.get('ALGORITHM')}</th>
              <th className='sites-sec-th'>{intl.get('REWARD_H')}</th>
              <th className='sites-sec-th'>{intl.get('CURRENT_SHARES')}</th>
              <th className='sites-sec-th'>{intl.get('MINERS')}</th>
              <th className='sites-sec-th'>{intl.get('HASHRATE')}</th>
              <th className='sites-sec-th'>{intl.get('GLOBAL')}</th>
              <th className='sites-sec-th'>{intl.get('HEIGHT')}</th>
            </tr>
          </thead>
          <tbody>
            { coinList.map((item, key) => {
                if (!item) return <></>;
                return (
                  <tr 
                    key={key}
                  >
                    <td className='addr sites-sec-td'>
                      {item.name}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.algo}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.yield}
                    </td>
                    <td className='addr sites-sec-td'>
                      <span style={parseFloat(item.effort) >= 100.00 ? { color: 'red'} : { color: 'green' }}>{item.effort}</span>
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.miners}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.hashrate}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.globalHashrate}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.height}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
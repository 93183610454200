import { useState, useEffect } from 'react';
import AreaChart from '../searchNimiq/areaChart';
import intl from 'react-intl-universal';
import { CommonUtils } from '../../utils/commonUtils';

export default function AccountGraphBoard ({accountHistory}) {

  const [searchedTableFirst] = useState(true);
  const [graphData, setGraphData] = useState([]);

  const processData = () => {
    setGraphData(accountHistory);
  };

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  const config = {
    data: graphData,
    height: 400,
    xField: 'time',
    yField: 'hashrate',
    smooth: true,
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return CommonUtils.toHashrate(v);
        },
      },
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index, key) => {
                const { value } = item;
                return (
                  <div key={key}
                  >
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span className="g2-tooltip-list-item-value">{ CommonUtils.toHashrate(value) }</span>
                    </span>
                  </div>
                );
              })}
            </ul>
          </>
        );
      }
    }
  };

  const configO = {
    data: graphData,
    height: 400,
    xField: 'time',
    yField: 'machine',
    areaStyle: {fill: 'green'},
    line: {color: 'green'},
    smooth: true,
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index, key) => {
                const { value } = item;
                return (
                  <div key={key}
                  >
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span className="g2-tooltip-list-item-value">{intl.get('ONLINE_MACHINES')}: {value}</span>
                    </span>
                  </div>
                );
              })}
            </ul>
          </>
        );
      }
    }
  };
  
  return (
    <>
    <section className='graph-sec'>
      <div className='graph-panel'>
        <div className='graph-title'>
          {intl.get('HASHRATE_VS_TIME')}
        </div>
        <div className='graph-con'>
          <AreaChart 
            aStyle={{padding: '0.5rem 3rem 1rem', height: '21rem'}}
            config={config}
            first={searchedTableFirst}
          />
        </div>
      </div>
    </section>
    <section className='graph-sec'>
      <div className='graph-panel'>
        <div className='graph-title'>
          {intl.get('ONLINE_MACHINES_VS_TIME')}
        </div>
        <div className='graph-con'>
          <AreaChart 
            aStyle={{padding: '0.5rem 3rem 1rem', height: '21rem'}}
            config={configO}
            first={searchedTableFirst}
          />
        </div>
      </div>
    </section>
    </>
  )
}
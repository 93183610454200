import {
  Row,
  Col,
  Tooltip,
  Spin
} from 'antd';
/*eslint-disable*/ 
import intl from 'react-intl-universal';
import { useState, useEffect } from 'react';
import memoryUtils from '../../utils/memoryUtils';
import {
  DatabaseOutlined,
  ShareAltOutlined,
  WalletOutlined,
  QuestionCircleFilled
} from '@ant-design/icons';

import { CommonUtils } from '../../utils/commonUtils';

export default function AccountDataBoard ({res, resGlobal, priceData}) {

  const [accountData, setAccountData] = useState({});

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  const processData = () => {
    const allWorkerList = res.data ? res.data.data.workers : [];
    let onlineCount = 0;
    let offlineCount = 0;
    for (const worker of allWorkerList) {
      if (worker.status === "online") {
        onlineCount = onlineCount + 1;
      } else {
        offlineCount = offlineCount + 1;
      }
    }
    const allDeviceCurrentAccepts = res.data ? res.data.data.allDeviceCurrentAccepts : [];
    const allDevice24hAccepts = res.data ? res.data.data.allDevice24hAccepts : [];
    const balance = res.data ? res.data.data.balance : [];
    const day = 24 * 3600 * 1000;
    const rewardNim = (allDevice24hAccepts / day * (resGlobal?.data?.data?.miningRate || 0)).toFixed(2);
    setAccountData({
      onlineCount,
      offlineCount,
      hashrate: CommonUtils.toHashrate(allDeviceCurrentAccepts / 300),
      hashrate24: CommonUtils.toHashrate(allDevice24hAccepts / (24 * 60 * 60)),
      balance: (balance / 1e5).toFixed(2),
      reward: (resGlobal?.data?.data?.miningRate || 0).toFixed(2),
      rewardNim,
      rewardCny: rewardNim * (priceData['NIM']?.CNY || 0)
    });
  }

  useEffect(() => {
    processData();
  }, []);

  if (Object.keys(accountData).length === 0) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  return (
    <section className='data-sec'>
      <Row align='middle'>
        <Col sm={{span: 24}} lg={{span: 4}} >
          <div className='data-block'>
            <div className='block-title'>
            <DatabaseOutlined style={{marginRight: '0.5rem'}} />{intl.get('MACHINE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('ONLINE')}</div>
                <div style={{color: 'green'}} className='lower-figure'>{accountData.onlineCount}</div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('OFFLINE')}</div>
                <div style={{color: 'red'}} className='lower-figure'>{accountData.offlineCount}</div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 13}}>
          <div className='data-block'>
            <div className='block-title'>
            <ShareAltOutlined style={{marginRight: '0.5rem'}} />{intl.get('HASHRATE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} sm={{span: 12}} lg={{span: 7}}>
                <div className='lower-title'>
                  {intl.get('CURRENT_HASHRATE')} 
                  <Tooltip placement="bottom" title={intl.get('CURR_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>{accountData.hashrate}</div>
              </Col>
              <Col className='lg-right-border' sm={{span: 12}} lg={{span: 7}}>
                <div className='lower-title'>
                  {intl.get('24_HASHRATE')}
                  <Tooltip placement="bottom" title={intl.get('24_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                  
                </div>
                <div className='lower-figure'>{accountData.hashrate24}</div>
              </Col>
              <div className='updown-divider sm-show'></div>
              <Col sm={{span: 24}} lg={{span: 10}}>
                <div className='lower-title'>
                  {intl.get('DAILY_REWARD')}
                  <Tooltip placement="bottom" title={intl.get('D_REWARD_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>
                  {accountData.rewardNim}{` NIM`}
                  <span className='unit'>
                    {` ≈ `}{crySign}{(accountData.rewardCny / exchange).toFixed(2)}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 7}}>
          <div className='data-block'>
            <div className='block-title'>
            <WalletOutlined style={{marginRight: '0.5rem'}} />{intl.get('PERSONAL')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('WALLET_BALANCE')}</div>
                <div className='lower-figure'>{accountData.balance}{' NIM'}</div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('COIN_REWARD')}</div>
                <div className='lower-figure'>{accountData.reward} <span className='unit'> NIM/K</span></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  )
}
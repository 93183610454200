import { useState } from 'react';
import { Menu } from 'antd';
import BlockTable from './blockTable';
import intl from 'react-intl-universal';

export default function BlockBoard ({ poolStats, config, network }) {

  const [ coin, setCoin ] = useState('xmr');

  return (
    <>
    <section style={{marginBottom: '5px'}} className='etc-btns-sec'>
      <div style={{paddingBottom: '20px'}} className='sites-sec-title'>
        {intl.get('BLOCKS_FOUND')}
      </div>
      <Menu onClick={(e) => setCoin(e.key)} selectedKeys={[coin]} mode="horizontal" style={{justifyContent: 'center'}} >
        <Menu.Item key="xmr" >
        XMR
        </Menu.Item>
        <Menu.Item key="alt" >
        ALT-COINS
        </Menu.Item>
        <Menu.Item key="arq">
        ARQ
        </Menu.Item>
        <Menu.Item key="qrl">
        QRL
        </Menu.Item>
        <Menu.Item key="zeph">
        ZEPH
        </Menu.Item>
        <Menu.Item key="xhv">
        XHV
        </Menu.Item>
      </Menu>
    </section>
    <section className='coin-table-sec'>
      <BlockTable coin={coin} poolStats={poolStats} config={config} network={network} />
    </section>
    </>
  )
}
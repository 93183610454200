import { Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { CommonUtils } from '../../utils/commonUtils';
import {
  CopyOutlined
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';

/*eslint-disable*/ 
export default function ServerList ({serverListData}) {

  const [siteList, setSiteList] = useState([]);

  const processData = () => {
    const list = [];
    for (const node of serverListData) {
      let nodeName;
      let server;
      if (node.name === 'sh1') {
        nodeName = '上海（天池协议）Shanghai (Skypool Protocol)';
        server = 'ws://sh1.nimiq.skypool.xyz:4000';
      } else if (node.name === 'sh1-o') {
        nodeName = '上海（官方协议）Shanghai (Official Protocol)';
        server = 'wss://sh1.nimiq.skypool.xyz:5000';
      } else {
        nodeName = '香港-0 Hongkong-0';
        server = 'ws://hk0.nimiq.skypool.xyz:4000';
      }
      list.push({
        area: nodeName,
        server,
        machine: node.deviceNumber,
        hashrate: CommonUtils.toHashrate(node.hashrate)
      });
    }
    setSiteList(list);
  }

  useEffect(() => {
    processData();
  }, []);

  if (siteList.length === 0) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  return (
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('SERVERLIST')}
      </div>
      <div 
        style={{
          padding: '1rem 20px 1.5rem',
          background: 'white'
        }}
        className='main-panel-table'
      >
        <table style={{width: '100%'}}>
          <thead style={{backgroundColor: 'white', color: 'black'}} className='panel-table-thead'>
            <tr>
              <th className='sites-sec-th-center'>{intl.get('AREA')}</th>
              <th className='sites-sec-th-center'>{intl.get('SERVER')}</th>
              <th className='sites-sec-th-center'>{intl.get('ONLINE_MACHINES')}</th>
              <th className='sites-sec-th-center'>{intl.get('HASHRATE')}</th>
            </tr>
          </thead>
          <tbody>
            {
              siteList.map((item, key) => {
                return (
                  <tr 
                    key={key}
                  >
                    <td className='addr sites-sec-td-center'>
                      {item.area}
                    </td>
                    <td className='addr sites-sec-td-center'>
                      {item.server}
                      <CopyOutlined 
                        style={{marginLeft: '10px', cursor: 'pointer'}} 
                        onClick={() => {copy(item.server); message.success(intl.get('COPIED_TO_CLIPBOARD'))}}
                      />
                    </td>
                    <td className='addr sites-sec-td-center'>
                      {item.machine}
                    </td>
                    <td className='addr sites-sec-td-center'>
                      {item.hashrate}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
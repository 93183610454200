import { useState, useEffect } from 'react';
import { Form, Input, Button,  message } from 'antd';
import { 
  UserOutlined
} from '@ant-design/icons';
import store from 'store';
import intl from 'react-intl-universal';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';

import {
  reqSendEmail
} from '../../api/ajaxAuth';

import './loginPage.css';
import memoryUtils from '../../utils/memoryUtils';

export default function ForgetPwd ({ history }) {

  const [ hasCheckedLogin, setHasCheckedLogin ] = useState(false);
  const [ isLogIn, setIsLogIn ] = useState(false);

  const sendEmail = async () => {
    const emailBox = document.getElementById('eth_login_email');
    const sendBtn = document.getElementById('login-form-button');
    const email = emailBox.value;
    const storeSkypool = await store.get('skypool');
    let lang;
    if (!storeSkypool) {
      lang = 'zh-CN';
    } else {
      lang = storeSkypool.lang ? storeSkypool.lang : 'zh-CN';
    }
    const response = await reqSendEmail(email, lang);
    if (!response.data.success) {
      message.error(intl.get('SENT_FAIL'));
    }
    message.success(intl.get('SENT_SUCCESS'));

    sendBtn.disabled = true;
    let seconds = 60;
    const timer = setInterval(() => {
      seconds--;
      sendBtn.innerText = seconds + intl.get('SECONDS_UNTIL_RESEND');
      sendBtn.disabled = true;
      if (!seconds) {
        clearInterval(timer);
        sendBtn.innerText = intl.get('SEND_EMAIL');
        sendBtn.disabled = false;
      }
    }, 1000);
  }

  const checkState = async () => {
    const user = memoryUtils.user;
    if (user) {
      setIsLogIn(true);
    }
    setHasCheckedLogin(true);
  }

  useEffect(() => {
    checkState();
  }, []);

  const PLEASE_INPUT_EMAIL = intl.get('PLEASE_INPUT_EMAIL');
  const INVALID_EMAIL = intl.get('INVALID_EMAIL');
    
  const loginPanel = (
    <section className='login-section'>
      <div className='login-container'>
        <div className='login-panel'>
          <div className='login-title'>
            {intl.get('FORGET_PASSWORD')}
          </div>
          <Form
            name="eth_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={sendEmail}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: PLEASE_INPUT_EMAIL },
                { type: 'email', message: INVALID_EMAIL }
              ]}
            >
              <Input 
                prefix={<UserOutlined className="site-form-item-icon" />} 
                placeholder={PLEASE_INPUT_EMAIL}
              />
            </Form.Item>
            
            <div className="form-bottom">
              <div className='login-button'>
                <Form.Item id='login-form-button-con'>
                  <Button size='large' type="primary" htmlType="submit" id="login-form-button">
                    {intl.get('SEND_EMAIL')}
                  </Button>
                </Form.Item>
              </div>
            </div>

          </Form>
        </div>
      </div>
    </section>
  );

  if (!hasCheckedLogin) {
    return (
      <div>loading</div>
    )
  }

  if (isLogIn) {
    history.replace('/ethereum/dashboard');
  }

  return (
    <div className='login'>
      <CommonHeader />
      <div style={{minHeight: '70vh'}} className='login-page'>
        {loginPanel}
      </div>
      <CommonFooter />
    </div>
  )
}
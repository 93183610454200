import { useState, useEffect } from 'react';
import { Form, Input, Button, Result, message } from 'antd';
import { 
  UserOutlined
} from '@ant-design/icons';
import intl from 'react-intl-universal';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import {
  checkEmailToken,
  reqChangepwd
} from '../../api/ajaxAuth';

import './loginPage.css';

export default function ChangePwd ({ history, location }) {

  const [ email, setEmail ] = useState(null);
  const [ verifyStatus, setVerifyStatus ] = useState('not verify');

  const changepwd = async () => {
    const passwordBox = document.getElementById('eth_login_password');
    const password = passwordBox.value;
    const response = await reqChangepwd(email, password);
    if (!response.data.success) {
      return message.error('change fail');
    }
    message.success('change success');
    history.replace(`/login?email=${email}`);
  }

  const check = async () => {
    if (!location.search) {
      return setVerifyStatus('verify fail');
    }
    const query = location.search.slice(1);
    const arr = query.split('&');
    const token = arr[0].split('=')[1];
    const email = arr[1].split('=')[1];

    const response = await checkEmailToken(email, token);
    if (!response.data.success) {
      setVerifyStatus('verify fail');
    }
    setVerifyStatus('verify success');
    setEmail(email);
  }

  useEffect(() => {
    check();
    // eslint-disable-next-line
  }, []);

  const PLEASE_INPUT_NEW_PASSWORD = intl.get('PLEASE_INPUT_NEW_PASSWORD');
  const PASSWORD_LENGTH_ERROR = intl.get('PASSWORD_LENGTH_ERROR');
  const PLEASE_CONFIRM_YOUR_PASSWORD = intl.get('PLEASE_CONFIRM_YOUR_PASSWORD');
    
  const loginPanel = (
    <section className='login-section'>
      <div className='login-container'>
        <div className='login-panel'>
          <div className='login-title'>
            {intl.get('CHANGE_PASSWORD')}
          </div>
          <Form
            name="eth_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={changepwd}
          >
            <Form.Item
              name="password"
              rules={[
                { 
                  required: true, 
                  message: PLEASE_INPUT_NEW_PASSWORD },
                {
                  min: 6,
                  message: PASSWORD_LENGTH_ERROR
                },
                {
                  max: 12,
                  message: PASSWORD_LENGTH_ERROR
                },
              ]}
            >
              <Input 
                prefix={<UserOutlined className="site-form-item-icon" />} 
                type='password'
                placeholder={PLEASE_INPUT_NEW_PASSWORD} 
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: PLEASE_CONFIRM_YOUR_PASSWORD,
                },
                {
                  min: 6,
                  message: PASSWORD_LENGTH_ERROR
                },
                {
                  max: 12,
                  message: PASSWORD_LENGTH_ERROR
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(intl.get('PASSWORD_NOT_MATCH')));
                  },
                }),
              ]}
            >
              <Input 
                prefix={<UserOutlined className="site-form-item-icon" />} 
                type='password'
                placeholder={PLEASE_CONFIRM_YOUR_PASSWORD} 
              />
            </Form.Item>
            
            <div className="form-bottom">
              <div className='login-button'>
                <Form.Item id='login-form-button-con'>
                  <Button size='large' type="primary" htmlType="submit" id="login-form-button">
                    {intl.get('CHANGE_PASSWORD')}
                  </Button>
                </Form.Item>
              </div>
            </div>

          </Form>
        </div>
      </div>
    </section>
  );

  if (verifyStatus === 'not verify') {
    return (
      <div>loading</div>
    );
  }

  if (verifyStatus === 'verify fail') {
    return (
      <Result
        status="404"
        title="404"
        subTitle="404 title"
        extra={<Button type="primary" href='/login'>Back Home</Button>}
      />
    );
  }

  return (
    <div className='changePwdMain'>
      <CommonHeader />
      <div className='login-page'>
        {loginPanel}
      </div>
      <CommonFooter />
    </div>
  )
}
import { 
  message, 
  Spin,
  Row,
  Col
} from 'antd';
import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import memoryUtils from '../../utils/memoryUtils';

import {
  getRvnGlobalData,
  getPrice
} from '../../api/ajaxMain';
import {CommonUtils} from '../../utils/commonUtils';

export default function DataBoard ({ change }) {

  const [global, setGlobal] = useState({});
  const [pool, setPool] = useState({});

  const getResGlobalAndTime = async () => {
    try {
      const res = await getRvnGlobalData();
      return [res?.data?.pools?.ravencoin, res?.data?.time];
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getPriceData = async () => {
    try {
      const res = await getPrice();
      return res?.data?.data || {};
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
    }
  }

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  const processData = async () => {
    const [resGlobal, resTime] = (await getResGlobalAndTime()) || [{}, {}];
    const priceData = (await getPriceData()) || {};
    const rvnGlobalHashrate = parseFloat(resGlobal.poolStats ? resGlobal.poolStats.networkSols : 0) / 1e12;
    const rvnReward = 2500;
    const reward2 = (rvnReward * 1440 / (rvnGlobalHashrate * 1000 * 1000)).toFixed(2);

    setGlobal({
      hashrate: resGlobal.poolStats ? resGlobal.poolStats.networkSolsString : '',
      lastTime: resTime,
      height: resGlobal.poolStats ? resGlobal.poolStats.networkBlocks : 0,
      difficulty: resGlobal.poolStats ? resGlobal.poolStats.networkDiff : 0,
      lastReward: rvnReward.toString(),
      price: priceData['RVN']?.CNY,
      change: priceData['RVN']?.CNY_CH,
      rate: 'PPS+ 1.5%',
      algo: intl.get('GRAPHICS_CARD')
    });
    setPool({
      hashrate: resGlobal.hashrateString,
      block: resGlobal.blocks ? resGlobal.blocks.confirmed + resGlobal.blocks.orphaned + resGlobal.blocks.pending : 0,
      miner: resGlobal.minerCount,
      machine: resGlobal.workerCount,
      reward: reward2,
    });
  }

  useEffect(() => {
    if (change === 0) processData();
    // eslint-disable-next-line
  }, [change]);

  const loaded = Object.keys(global).length !== 0 && Object.keys(pool).length !== 0;
  if (!loaded) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  return (
    <>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('GLOBAL_DATA')} 
      </div>
      <div className='data-board'>
        <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                {intl.get('GLOBAL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                {global.hashrate.slice(0, -4)} 
                  <span className='unit'> 
                  {global.hashrate.slice(-4)} 
                  </span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('LAST_BLOCK')}
                </div>
                <div className='data-board-item-figure'>
                { intl.options.currentLocale === 'en-US' ?
                  CommonUtils.toUpdateTimeEn(Date.now() / 1000 - global.lastTime) :
                  CommonUtils.toUpdateTime(Date.now() / 1000 - global.lastTime) }
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('HEIGHT')}
                </div>
                <div className='data-board-item-figure'>
                {global.height}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('DIFFICULTY')}
                </div>
                <div className='data-board-item-figure'>
                {parseFloat(global.difficulty).toFixed(2)}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='divider-line sm-hide'></div>
        <div className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('BLOCK_REWARDS')}
                </div>
                <div className='data-board-item-figure'>
                {global.lastReward}<span className='unit'> RVN</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('DAILY_REWARD')}
                </div>
                <div className='data-board-item-figure'>
                {pool.reward} <span className='unit'> RVN/M</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('PRICE')}
                  { global.change > 0 ? <span style={{color: 'green', marginLeft: '3px'}}>
                    {`+${global.change}`}%</span> 
                    : <span style={{color: 'red', marginLeft: '3px'}}>{global.change}%</span>}
                </div>
                <div className='data-board-item-figure'>
                  {crySign + (parseFloat(global.price) / exchange).toFixed(3)}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('HARDWARE')}
                </div>
                <div className='data-board-item-figure'>
                  {global.algo}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('POOL_DATA')}
      </div>
      <div className='data-board'>
        <div style={{margin: '1rem 0 1.5rem'}} className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('POOL_HASHRATE')}
                </div>
                <div className='data-board-item-figure'>
                {pool.hashrate && pool.hashrate.slice(0, -4)} 
                <span className='unit'> 
                {pool.hashrate && pool.hashrate.slice(-4)}
                </span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('TOTAL_BLOCKS')}
                </div>
                <div className='data-board-item-figure'>
                {pool.block}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('ONLINE_MINERS')}
                </div>
                <div className='data-board-item-figure'>
                {pool.miner}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('ONLINE_MACHINES')}
                </div>
                <div className='data-board-item-figure'>
                {pool.machine}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='divider-line sm-hide'></div>
        <div className='data-board-row'>
          <Row>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('TRANSFER_TIME')}
                </div>
                <div className='data-board-item-figure'>
                  {intl.get('PER_12_HOURS')}
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('PAY_THRESHOLD')}
                </div>
                <div className='data-board-item-figure'>
                10<span className='unit'> RVN</span>
                </div>
              </div>
            </Col>
            <Col sm={{span:12}} lg={{span:6}}>
              <div className='data-board-item'>
                <div className='data-board-item-title'>
                  {intl.get('FEE_RATE')}
                </div>
                <div className='data-board-item-figure'>
                  <span className='unit'>PPS+</span> 1.5%
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  )
}
import {Component} from 'react';

import { Area } from '@ant-design/charts';

export default class AreaChart extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.first
  }

  render() {
    return (
      <Area 
        style={this.props.aStyle}
        {...this.props.config}
      />)
  }
}
import intl from 'react-intl-universal';
import memoryUtils from '../../utils/memoryUtils';
import { Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { withRouter } from 'react-router';

function PowTable ({ dataList, history }) {

  let exchange = 1;
  let crySign = '¥';
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
    crySign = '$';
  }

  var nameToFixed = (name) => {
    if (memoryUtils.currency === 'USD') {
      switch (name) {
        case 'ETC':
          return 2;
        case 'XMR':
          return 2;
        case 'XHV':
          return 3;
        case 'QRL':
          return 3;
        case 'ETH':
          return 2;
        case 'CFX':
          return 4;
        default:
          return 4;
      }
    } else { // CNY
      switch (name) {
        case 'ETC':
          return 2;
        case 'NIM':
          return 4;
        case 'XMR':
          return 2;
        case 'XHV':
          return 3;
        case 'QRL':
          return 3;
        case 'ETH':
          return 2;
        case 'CFX':
          return 4;
        default:
          return 4;
      }
    }

  }
  
  return (
    <div className='main-panel-table'>
      <table style={{width: '100%'}}>
        <thead className='panel-table-thead'>
          <tr>
            <th>{intl.get('COIN')}</th>
            <th>{intl.get('POOL_SP')}<span className='sm-hide'>{intl.get('HASHRATE')}</span></th>
            <th className='sm-hide'>{intl.get('GLOBAL_SP')}<span className='sm-hide'>{intl.get('HASHRATE')}</span></th>
            <th 
              className='sm-hide'
              style={{
                textAlign: 'right',
                paddingRight: '36px',
                width: '15%'
              }}
            >{intl.get('PRICE')}</th>
            <th>{intl.get('DAILY_REWARD')}</th>
            <th className='sm-hide'>{intl.get('FEE_RATE')}</th>
            <th className='sm-hide'>{intl.get('HARDWARE')}</th>
          </tr>
        </thead>
        <tbody>
          { dataList.slice(0, 1).map((item, key) => {
              return (
                <tr 
                  key={key}
                  onClick={() => {
                    history.push(item.goto)
                  }} 
                  className='data-row'
                >
                  <td className='first-col' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3.5rem', fontWeight: 700}}>
                    <div className='icon-con'>
                      <img alt='logo' src={item.logo} />
                    </div>
                    {item.type}
                    <span style={{width: '18px', height: '18px'}}></span>
                  </td>
                  <td className='addr'>
                    <span className='figure'>
                    {
                      item.hash.slice(0, -4)
                    }
                    </span>
                    {
                      item.hash.slice(-4) === ' H/s' ?
                      item.hash.slice(-4) :
                      item.hash.slice(-5)
                    }
                  </td>
                  <td className='addr sm-hide'>
                    <span className='figure'>
                      {
                        item.gHash.slice(0, -4)
                      }
                    </span>
                    {
                      item.gHash.slice(-4) === ' H/s' ?
                      item.gHash.slice(-4) :
                      item.gHash.slice(-5)
                    }
                  </td>
                  <td style={{textAlign: 'right'}} className='addr sm-hide'>
                  <span className='figure' style={{marginRight: '3px'}}>{crySign}{(parseFloat(item.price) / exchange).toFixed(nameToFixed(item.type))}</span>
                    {' '}{item.change >= 0 ? <span style={{color: 'green'}}>{`+${item.change}`}%</span> : <span style={{color: 'red'}}>{item.change}%</span>}</td>
                  <td className='addr'>
                    <span className='figure'>{item.reward}</span>
                    { item.type === 'NIM' ? ' NIM/K' : item.type === "ETC" ? ' ETC/M' : ' RVN/M' }
                    <span>{' ≈ '}{crySign}{parseFloat(item.rewardCny / exchange).toFixed(3)}</span>
                  </td>
                  <td className='addr sm-hide'>{item.rate1}<span style={{marginLeft:'3px', fontWeight: "700"}}>{item.rate2}</span></td>
                  <td className='addr sm-hide'>{item.algo}</td>
                </tr>
              )
            })
          }
          { dataList.slice(1, 6).map((item, key) => {
              return (
                <tr 
                  key={key}
                  onClick={() => {
                    console.log('item.goto:', item.goto)
                    history.push(item.goto)
                  }} 
                  className='data-row-cpu'
                >
                  <td className='first-col' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3.5rem', fontWeight: 700}}>
                    <div className='icon-con'>
                      <img alt='logo' src={item.logo} />
                    </div>
                    {item.type}
                    <Tooltip className='sm-hide' placement="bottom" title={intl.get('CPU_TOOLTIP')} >
                      <ExclamationCircleFilled style={{cursor: 'pointer', color: '#02b902', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                    </Tooltip>
                  </td>
                  <td className='addr'>
                    <span className='figure'>
                    {
                      item.hash.slice(0, -4)
                    }
                    </span>
                    {
                      item.hash.slice(-4) === ' H/s' ?
                      item.hash.slice(-4) :
                      item.hash.slice(-5)
                    }
                  </td>
                  <td className='addr sm-hide'>
                    <span className='figure'>
                      {
                        item.gHash.slice(0, -4)
                      }
                    </span>
                    {
                      item.gHash.slice(-4) === ' H/s' ?
                      item.gHash.slice(-4) :
                      item.gHash.slice(-5)
                    }
                  </td>
                  <td style={{textAlign: 'right'}} className='addr sm-hide'>
                    <span className='figure' style={{marginRight: '3px'}}>
                    {crySign}{(parseFloat(item.price) / exchange).toFixed(nameToFixed(item.type))}
                    </span>
                    {' '}
                    {
                      item.change >= 0 ? 
                      <span style={{color: 'green'}}>
                        {`+${item.change}`}%
                      </span> : 
                      <span style={{color: 'red'}}>
                        {item.change}%
                      </span>
                    }
                  </td>
                  <td className='addr'>
                    {
                      item.type === 'XMR' ?
                      <>
                        <span className='figure'>
                          {(item.reward * 1e3).toFixed(5)}
                        </span>
                        {` XMR/K`}
                      </> :
                      <>
                        <span className='figure'>
                          { (item.reward * 100).toFixed(2) }{'%'}
                        </span>
                        {` XMR`}
                        <Tooltip className='sm-hide' placement="bottom" title={intl.get('CPU_TOOLTIP2')}>
                          <ExclamationCircleFilled style={{cursor: 'pointer', color: '#02b902', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                        </Tooltip>
                      </>
                    }
                    <span>
                      { item.type === 'XMR' && <>{` ≈ `}{crySign}{(parseFloat(item.rewardCny * 1e3) / exchange).toFixed(3)}</> }
                    </span>
                  </td>
                  <td className='addr sm-hide'>{item.rate1}<span style={{marginLeft:'3px', fontWeight: "700"}}>{item.rate2}</span></td>
                  <td className='addr sm-hide'>{item.algo}</td>
                </tr>
              )
            })
          }
          { dataList.slice(6).map((item, key) => {
              return (
                <tr 
                  key={key}
                  onClick={() => {
                    window.open(item.goto, "_blank");
                  }} 
                  className='data-row-card'
                >
                  <td className='first-col' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3.5rem', fontWeight: 700}}>
                    <div className='icon-con'>
                      <img alt='logo' src={item.logo} />
                    </div>
                    {item.type}
                    <Tooltip className='sm-hide' placement="bottom" title={intl.get('BAR_TOOLTIP')}>
                      <ExclamationCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                    </Tooltip>
                  </td>
                  <td className='addr'>
                    {item.hash}
                  </td>
                  <td className='addr sm-hide'>{item.gHash}</td>
                  <td style={{textAlign: 'right'}} className='addr sm-hide'>
                    <span className='figure' style={{marginRight: '3px'}}>
                    {crySign}{(parseFloat(item.price) / exchange).toFixed(nameToFixed(item.type))}
                    </span>
                    {' '}{item.change >= 0 ? <span style={{color: 'green'}}>{`+${item.change}`}%</span> : <span style={{color: 'red'}}>{item.change}%</span>}</td>
                  <td className='addr'>{item.reward}<span>{item.rewardCny}</span></td>
                  <td className='addr sm-hide'>{item.rate1}<span style={{marginLeft:'3px', fontWeight: "700"}}>{item.rate2}</span></td>
                  <td className='addr sm-hide'>{item.algo}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div> 
  )
}

export default withRouter(PowTable);
import {
  Row,
  Col
} from 'antd';
import intl from 'react-intl-universal';

import {withRouter} from 'react-router-dom';

function CoinIntro ({coinIcon, coinName, coinUrl, coinIntro, history, originalSite, explorer, tutorial}) {

  return (
    <section className='coin-info-sec sm-hide'>
      <div className='coin-info-sec-upper'>
        <Row align='middle'>
          <Col sm={{span:11}} lg={{span: 14}}>
            <div onClick={() => {history.push(coinUrl); window.location.reload();}} className='coin-info-title-con'>
              <div className='coin-info-title-icon'>
                { coinIcon }
              </div>
              <div className='coin-info-title'>
                { coinName }
              </div>
            </div>
          </Col>
          <Col sm={{span:14}} lg={{span: 10}}>
            <div className='coin-info-btns-con'>
              {originalSite &&
              <a className='a-style' href={originalSite} target="_blank" rel="noreferrer"><div className='coin-info-up-btn'>
                {intl.get('ORIGINAL_SITE')}
              </div></a>
              }
              {explorer &&
              <a className='a-style' href={explorer} target="_blank" rel="noreferrer"><div className='coin-info-up-btn'>
                {intl.get('BLOCKCHAIN_EXPLORER')}
              </div></a>
              }
            </div>
          </Col>
        </Row>
      </div>
      <div className='coin-info-sec-lower'>
        <Row align='bottom'>
          <Col sm={{span:24}} lg={{span: 16}}>
            <div className='coin-info-intro'>
              { coinIntro }
            </div>
          </Col>
          <Col sm={{span:24}} lg={{span: 8}}>
            <div className='coin-info-btns-con'>
              {tutorial &&
              <a className='a-style' href={tutorial} target="_blank" rel="noreferrer"><div style={{fontSize: '1.2rem'}} className='coin-info-up-btn'>
                {intl.get('START_MINING')}
              </div></a>
              }
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default withRouter(CoinIntro);
import { useState, useEffect } from 'react';
import { 
  Badge, 
  message,
  Spin
} from 'antd';
import {
  CopyOutlined
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { CommonUtils } from '../../utils/commonUtils';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import FreshBtn from '.././searchCommon/freshBtn';
import AccountDataBoard from './accountDataBoard';
import AccountGraphBoard from './accountGraphBoard';
import AccountTableBoard from './accountTableBoard';

import nimiqLogo from '../../assets/nimiq-nim-logo.svg';

import {
  getBasicDataNim,
  getNimiqGlobalData,
  getPrice
} from '../../api/ajaxMain';

/*eslint-disable*/ 
function AccountInfo ({address, history}) {

  const [accountDataBoardLoaded, setAccountDataBoardLoaded] = useState(false);
  const [accountGraphBoardLoaded, setAccountGraphBoardLoaded] = useState(false);
  const [accountTableBoardLoaded, setAccountTableBoardLoaded] = useState(false);
  const [res, setRes] = useState({});
  const [resGlobal, setResGlobal] = useState({});
  const [priceData, setPriceData] = useState({});
  const [freshNum, setFreshNum] = useState(-1);

  const getBasicData = async () => {
    try {
      const res = await getBasicDataNim(address);
      if (res.data.code !== 0) {
        return message.error(intl.get('FAIL_TO_GET_ACCOUNT_DATA'));
      }
      setRes(res);
      setAccountGraphBoardLoaded(true);
      setAccountTableBoardLoaded(true);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_ACCOUNT_DATA'));
    }
  }

  const getGlobalData = async () => {
    try {
      const res = await getNimiqGlobalData();
      if (res.data.code !== 0) {
        return message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
      }
      setResGlobal(res);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getPriceData = async () => {
    try {
      const resPrice = await getPrice();
      if (resPrice.status !== 200) {
        return message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
      }
      setPriceData(resPrice.data.data);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PRICE_DATA'));
    }
  }

  const getData = async () => {
    await getBasicData();
    await getGlobalData();
    await getPriceData();
    setAccountDataBoardLoaded(true);
    message.success(intl.get('SUCCESS_TO_GET_ACCOUNT_DATA'));
  }

  const refresh = (freshNum) => {
    setFreshNum(freshNum);
  }

  useEffect(() => {
    getData();
  }, [freshNum]);

  return (
    <>
    <div className='coin-type-box'>
      <img onClick={() => {history.push('/search/nim'); window.location.reload();}} src={nimiqLogo} />
      <div onClick={() => {history.push('/search/nim'); window.location.reload();}} className='coin-type-box-title'>
        NIM
      </div>
      <FreshBtn refresh={refresh} />
    </div>
    <section className='account-info'>
      <Badge status='success' />{CommonUtils.parseNimAddr(address)} 
      <CopyOutlined 
        style={{marginLeft: '1rem', cursor: 'pointer'}} 
        onClick={() => {copy(CommonUtils.parseNimAddr(address)); message.success(intl.get('COPIED_TO_CLIPBOARD'))}}
      />
    </section>
    { accountDataBoardLoaded ? <AccountDataBoard res={res} resGlobal={resGlobal} priceData={priceData} /> : <div className='spin-box'><Spin /></div> }
    { accountGraphBoardLoaded ? <AccountGraphBoard res={res} /> : <div className='spin-box'><Spin /></div> }
    { accountTableBoardLoaded ? <AccountTableBoard res={res} /> : <div className='spin-box'><Spin /></div> }
    </>
  )
}

export default withRouter(AccountInfo);
import './commonPanel.css';
import {
  EyeOutlined,
  EditOutlined,
  UnorderedListOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { Button, Input, message, Pagination, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { getFollowList, updateFollowList, shortenFollowList } from '../../api/ajaxAccount';
import { CommonUtils } from '../../utils/commonUtils';
import nimLogo from '../../assets/nimiq-nim-logo.svg';
import rvnLogo from '../../assets/ravencoin-rvn-logo.svg';
import xmrLogo from '../../assets/monero-xmr-logo.svg';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import memoryUtils from '../../utils/memoryUtils';
import store from 'store';

function FollowPanel ({history}) {

  // eslint-disable-next-line
  const limit = 10;
  const [ total, setTotal ] = useState(0);
  const [ followList, setFollowList ] = useState([]);
  const [ currency, setCurrency ] = useState('unknown');
  const [ currPage, setCurrPage ] = useState(1);

  const getFollowListWrap = async (page) => {
    try {
      const res = await getFollowList(page, limit);
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_FOLLOW_LIST'));
    }
  }

  const updateFollowListWrap = async (addr, comment) => {
    try {
      const res = await updateFollowList(currency, comment, addr, currPage - 1, limit);
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_ADD_WALLET'));
    }
  }

  const shortenFollowListWrap = async (address) => {
    try {
      const res = await shortenFollowList(address, currPage - 1, limit);
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_UNFOLLOW'));
    }
  }

  const unfollow = async (address) => {
    const res = await shortenFollowListWrap(address);
    if (!res.success) return;
    setFollowList(res.data);
    setTotal(res.total);
  }

  const addToList = async () => {
    if (currency === 'unknown') return message.warning(intl.get('INVALID_ADDRESS'));
    const addrInput = document.getElementById('addr-input');
    const commentInput = document.getElementById('comment-input');
    const addr = addrInput.value;
    const comment = commentInput.value;
    if (comment.length > 40) return message.warning(intl.get('TOO_LONG_COMMENT'))
    const res = await updateFollowListWrap(addr, comment);
    if (!res.success && res.msg === 'Already exists') return message.warning(intl.get('ADDRESS_ALREADY_EXISTS'));
    if (!res.success && res.msg === 'exceed follow list length limit') 
      return message.warning(intl.get('EXCEED_FOLLOW_LIST_LENGTH_LIMIT'));
    if (!res.success) return message.error(intl.get('CANNOT_ADD'));
    setFollowList(res.data.slice(0, 10));
    setTotal(res.total);
    return message.success(intl.get('ADDED'));
  }

  const checkAddr = async (e) => {
    const addr = e.target.value;
    let currencyNew = 'unknown';
    if (CommonUtils.parseNimAddrStrict(addr) !== false) {
      currencyNew = 'NIM';
    }
    if (CommonUtils.parseRvnAddrStrict(addr) !== false) {
      currencyNew = 'RVN';
    }
    if (CommonUtils.parseXmrAddrStrict(addr) !== false) {
      currencyNew = 'XMR';
    }
    if (CommonUtils.parseXchAddrStrict(addr) !== false) {
      currencyNew = 'XCH';
    }
    if (currencyNew === currency) return;
    setCurrency(currencyNew);
  }

  const pageChange = async (page) => {
    const res = await getFollowListWrap(page - 1);
    setFollowList(res.data);
    setTotal(res.total);
    setCurrPage(page);
  }

  const processData = async () => {
    const res = await getFollowListWrap(0);
    if (!res.success) {
      message.error('身份验证失败');
      memoryUtils.userMain = { isLogIn: false, user: null };
      await store.set('userMain', { isLogIn: false, user: null });
      return history.push('/login');;
    }
    setFollowList(res.data);
    setTotal(res.total);
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  const tourl = (cry) => {
    switch (cry) {
      case 'NIM':
        return 'nim';
      case 'RVN':
        return 'rvn';
      case 'XMR':
        return 'cpu';
      default:
        return 'xch';
    }
  }

  const tosrc = (cry) => {
    switch (cry) {
      case 'NIM':
        return nimLogo;
      case 'RVN':
        return rvnLogo;
      case 'XMR':
        return xmrLogo;
      default:
        return xmrLogo;
    }
  }

  return (
    <div className='settingPanel'>
      <div className='setting-panel-container'>
        <div className='panel-title'>
          <EyeOutlined style={{ marginRight: '6px' }} />{intl.get('WALLET_LIST')}
        </div>
        <div className='follow-list-con'>
          <div className='add-follow-upper'>
            <div className='add-follow-title'>
              <UnorderedListOutlined style={{ marginRight: '6px' }} />{intl.get('WALLET_LIST')}
            </div>
          </div>
          <div className='common-table-con'>
            <table className='common-table'>
              <thead>
                <tr>
                <th className='common-col-1'></th>
                <th className='common-col-2'>{intl.get('COIN')}</th>
                <th className='common-col-3'>{intl.get('COMMENT')}</th>
                <th className='common-col-4'>{intl.get('ADDRESS')}</th>
                <th className='common-col-5'>{intl.get('ACTION')}</th>
                <th className='common-col-6'></th>
                </tr>
              </thead>
              <tbody>
                { followList.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td></td>
                        <td><img className='follow-icon sm-hide' alt='logo' src={tosrc(item.currency)} />{ item.currency }</td>
                        <td style={{overflow: 'hidden'}}>
                          { item.comment.length <= 17 && window.innerWidth > 940 &&
                            item.comment }
                          { item.comment.length > 17 && window.innerWidth > 940 &&
                            <Tooltip title={item.comment}>
                              { item.comment.slice(0, 17) }...
                            </Tooltip> }
                          { item.comment.length <= 6 && window.innerWidth <= 940 &&
                            item.comment }
                          { item.comment.length > 6 && window.innerWidth <= 940 &&
                            <Tooltip title={item.comment}>
                              { item.comment.slice(0, 6) }...
                            </Tooltip> }
                        </td>
                        <td style={{overflow: 'hidden'}}>
                          { item.address.length <= 46 && window.innerWidth > 940 &&
                            <a href={`/search/${tourl(item.currency)}#${item.address}`}>{item.address}</a> }
                          { item.address.length > 46 && window.innerWidth > 940 &&
                            <Tooltip title={item.address}>
                              <a href={`/search/${tourl(item.currency)}#${item.address}`}>
                                { item.address.slice(0, 24) }...{ item.address.slice(-20) }
                              </a>
                            </Tooltip> }
                          { item.address.length <= 12 && window.innerWidth <= 940 &&
                            <a href={`/search/${tourl(item.currency)}#${item.address}`}>{item.address}</a> }
                          { item.address.length > 12 && window.innerWidth <= 940 &&
                            <Tooltip title={item.address}>
                              <a href={`/search/${tourl(item.currency)}#${item.address}`}>
                                { item.address.slice(0, 6) }...{ item.address.slice(-6) }
                              </a>
                            </Tooltip> }
                        </td>
                        <td>
                          <div onClick={() => unfollow(item.address)} className='in-table-btn'>
                            {intl.get('UNFOLLOW')}
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    )
                })}
              </tbody>
            </table>
            <div className='pag-con'>
              <Pagination current={currPage} onChange={pageChange} defaultCurrent={1} pageSize={10} total={total} showSizeChanger={false} />
            </div>
          </div>
        </div>
        <div className='add-follow'>
          <div className='add-follow-upper'>
            <div className='add-follow-title'>
              <EditOutlined style={{ marginRight: '6px' }} />{intl.get('ADD_WALLET')}
            </div>
            <div className='add-follow-subtitle'>
              {intl.get('ADD_FOLLOW_SUB')}
            </div>
          </div>
          <div className='add-follow-lower'>
            <div style={{  display: 'flex', alignItems: 'center' }} className='add-follow-input-box'>
              <Input onChange={checkAddr} id='addr-input' placeholder={intl.get('WALLET_ADDRESS')} allowClear className='add-follow-input' />
              <div className='currency-box'>
                {/*
                { currency === 'NIM' && <div><img alt='nim' src={nimLogo} /></div> }
                { currency === 'RVN' && <div><img alt='rvn' src={rvnLogo} /></div> }
                { currency === 'XMR' && <div><img alt='xmr' src={xmrLogo} /></div> }
                */}
                { currency !== 'unknown' && <CheckOutlined style={{ color: 'green', marginRight: '5px' }} /> }
                { currency !== 'unknown' && <div className='night-text' style={{ fontWeight: '400' }}>{currency}</div> }
              </div>
            </div>
            <div className='add-follow-input-box'>
              <Input id='comment-input' placeholder={intl.get('COMMENT')} allowClear className='add-follow-input' />
            </div>
            <Button onClick={addToList} type='primary' className='add-follow-btn'>{intl.get('ADD')}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(FollowPanel);
import { useState } from 'react';
import AreaChart from '../searchNimiq/areaChart';
import intl from 'react-intl-universal';
import { CommonUtils } from '../../utils/commonUtils';

export default function AccountGraphBoard ({ graphData }) {

  const [ searchedTableFirst ] = useState(true);

  const config = {
    data: graphData,
    height: 400,
    xField: 'time',
    yField: 'value',
    smooth: true,
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return CommonUtils.toHashrate(v * 1000);
        },
      },
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index, key) => {
                const { value } = item;
                return (
                  <div key={key}
                  >
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span className="g2-tooltip-list-item-value">{ CommonUtils.toHashrate(Number(value) * 1000) }</span>
                    </span>
                  </div>
                );
              })}
            </ul>
          </>
        );
      }
    }
  };

  return (
    <section className='graph-sec'>
      <div className='graph-panel'>
        <div className='graph-title'>
          {intl.get('HASHRATE_VS_TIME')}
        </div>
        <div className='graph-con'>
          <AreaChart config={config} first={searchedTableFirst} aStyle={{padding: '0.5rem 3rem 1rem', height: '21rem'}} />
        </div>
      </div>
    </section>
  )
}
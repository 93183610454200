import { message, Row, Col, Switch, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  getThreshold,
  postThreshold
} from '../../api/ajaxMain';
import TransferRange from './transferRange';
import {
  QuestionCircleFilled
} from '@ant-design/icons';

export default function TransferBoard ({ address }) {

  const [ isDailyPay, setIsDailyPay ] = useState(null);
  const [ threshold, setThreshold ] = useState(null);

  const getThresholdData = async () => {
    try {
      const thresholdData = (await getThreshold(address)).data;
      return thresholdData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_PAY_THRESHOLD'));
    }
  }

  const processThresholdData = async () => {
    const thresholdData = await getThresholdData();
    setIsDailyPay(thresholdData.msg.is_daily_pay === 1 ? true : false);
    setThreshold(parseFloat(thresholdData.msg.payout_threshold / 1e12));
  }

  const switchChange = async (checked) => { 
    try {
      await postThreshold(threshold, isDailyPay ? 0 : 1, address);
      setIsDailyPay(checked);
    } catch (e) {
      message.error(intl.get('THIS_ACCOUNT_CANNOT_SET_THRESHOLD'));
    }
  }

  const minusOne = async () => {
    if (threshold <= 0.02) {
      return;
    }    
    try {
      let step;
      if (threshold >= 2) {
        step = 1;
      } else if (threshold <= 1 && threshold > 0.1) {
        step = 0.1;
      } else {
        step = 0.01;
      }
      await postThreshold(parseFloat((threshold - step).toFixed(2)), isDailyPay ? 1 : 0, address);
      setThreshold(parseFloat((threshold - step).toFixed(2)));
    } catch (e) {
      message.error(intl.get('THIS_ACCOUNT_CANNOT_SET_THRESHOLD'));
    }
  }

  const addOne = async () => {
    try {      
      let step;
      if (threshold >= 1) {
        step = 1;
      } else if (threshold < 1 && threshold >= 0.10) {
        step = 0.1;
      } else {
        step = 0.01;
      }
      await postThreshold(parseFloat((threshold + step).toFixed(2)), isDailyPay ? 1 : 0, address);
      setThreshold(parseFloat((threshold + step).toFixed(2)));
    } catch (e) {
      message.error(intl.get('THIS_ACCOUNT_CANNOT_SET_THRESHOLD'));
    }
  }

  useEffect(() => {
    processThresholdData();
    // eslint-disable-next-line
  }, []);

  return (
    <section className='transfer-sec'>
      <div className='transfer-block'>
        <Row style={{ height: '100%' }}>
          <Col lg={{ span: 3 }} sm={{ span: 0 }}>
          </Col>
          <Col lg={{ span: 9 }} sm={{ span: 24 }}>
            <div className='transfer-switch'>
              {intl.get('AUTO_TRANSFER')}              
              <Tooltip placement="bottom" title={intl.get('TRANSFER_TOOLTIP')}>
                <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '5px'}} />
              </Tooltip>
              <Switch 
                checked={isDailyPay} 
                style={{marginLeft: '1rem'}} 
                onClick={switchChange}
              />
            </div> 
          </Col>
          <div className='updown-divider sm-show'></div>
          <Col lg={{ span: 9 }} sm={{ span: 24 }}>
            <TransferRange addOne={addOne} minusOne={minusOne} threshold={threshold} />
          </Col>
          <Col lg={{ span: 3 }} sm={{ span: 0 }}>
          </Col>
        </Row>
      </div>
    </section>
  )
}
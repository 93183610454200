import { message } from 'antd';
import { useState, useEffect } from 'react';
import { getAccountInfo } from '../../api/ajaxAccount';
import BindModal from '../../components/userCenter/bindModal';
import PwdModal from './pwdModal';
import {
  UserOutlined,
  ToolOutlined,
  LockOutlined,
  MobileOutlined,
  MailOutlined,
  KeyOutlined
} from '@ant-design/icons';
import intl from 'react-intl-universal';
import './commonPanel.css';

export default function SettingPanel () {

  const [ bindVis, setBindVis ] = useState(false);
  const [ pwdVis, setPwdVis ] = useState(false);
  const [ type, setType ] = useState('email');
  const [ accountData, setAccountData ] = useState({});

  const getAccountInfoWrap = async () => {
    try {
      const res = await getAccountInfo();
      return res.data;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_ACCOUNT_INFO'));
    }
  }

  const getData = async () => {
    const res = await getAccountInfoWrap();
    if (!res.success) {
      return message.error(intl.get('INVALID_ACCOUNT'));
    }
    setAccountData(res.data);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [])

  return (
    <div className='settingPanel'>
      <div className='panel-container'>
        <div className='panel-title'>
          <UserOutlined style={{ marginRight: '6px' }} />{intl.get('PERSONAL_SETTINGS')}
        </div>
        <div className='panel-block'>
          <div className='panel-block-title'>
            <ToolOutlined style={{ marginRight: '6px' }} />{intl.get('BINDINGS')}
          </div>
          <div className='panel-block-content'>
            <div className='panel-block-row'>
              <div className='contact-type'>
                <MobileOutlined style={{ marginRight: '6px' }} />{intl.get('MOBILE')}
              </div>
              <div className='contact-content'>
                { accountData.phone ? accountData.phone : intl.get('NOT_BINDED') }
              </div>
              <div className='contact-btn'>
                <span onClick={() => { setBindVis(true); setType('phone'); }}>
                  { accountData.phone ? intl.get('CHANGE_B') : intl.get('BIND') }
                </span>
              </div>
            </div>
            <div className='panel-block-row'>
              <div className='contact-type'>
                <MailOutlined style={{ marginRight: '6px' }} />{intl.get('MAIL')}
              </div>
              <div className='contact-content'>
                { accountData.email ? accountData.email : intl.get('NOT_BINDED') }
              </div>
              <div className='contact-btn'>
                <span onClick={() => { setBindVis(true); setType('email'); }}>
                  { accountData.email ? intl.get('CHANGE_B') : intl.get('BIND') }
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='panel-block'>
          <div className='panel-block-title'>
            <LockOutlined style={{ marginRight: '6px' }} />{intl.get('SECURITY')}
          </div>
          <div className='panel-block-content'>
            <div className='panel-block-row'>
              <div className='contact-type'>
                <KeyOutlined style={{ marginRight: '6px' }} />{intl.get('PASSWORD')}
              </div>
              <div className='changePanel-middle'>
                ********
              </div>
              <div onClick={() => setPwdVis(true)} className='contact-btn changePanel-right'>
                {intl.get('CHANGE_PASSWORD')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BindModal visible={bindVis} onCancel={() => setBindVis(false)} type={type} />
      <PwdModal visible={pwdVis} onCancel={() => setPwdVis(false)} />
    </div>
  )
  
}
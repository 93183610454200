import { useState, useEffect } from 'react';
import { Menu } from 'antd';
import {
  UserOutlined,
  EyeOutlined
} from '@ant-design/icons';
import './userCenter.css';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import memoryUtils from '../../utils/memoryUtils';
import SettingPanel from '../../components/userCenter/settingPanel';
import FollowPanel from '../../components/userCenter/followPanel';
import intl from 'react-intl-universal';
import { setNotLogIn } from '../../utils/memory';

export default function UserCenter ({ history }) {

  const [ panel, setPanel ] = useState('follow');
  const [ checked, setChecked ] = useState(false);

  const checkState = async () => {
    if (!memoryUtils.userMain.isLogIn) {
      setNotLogIn();
      history.push('/login');
    }    
    setChecked(true);
  }

  useEffect(() => {
    checkState();
    // eslint-disable-next-line
  }, []);

  if (!checked) return <></>

  return (
    <div className='userCenter'>
      <CommonHeader changeCry={() => {}} />
      <div className='userCenter-con'>
        <div className='sm-menu sm-show'>
          <Menu mode='vertical' onClick={(e) => setPanel(e.key)} defaultSelectedKeys={['follow']}>
            <Menu.Item key="follow">
              {intl.get('WALLET_LIST')}
            </Menu.Item>
            <Menu.Item key="setting">
              {intl.get('PERSONAL_SETTINGS')}
            </Menu.Item>
          </Menu>
        </div>
        <div className='both-panel-con'>
          <div className='left-panel'>
            <div className='left-panel-menu'>
              <div 
                onClick={() => setPanel('follow')} 
                className={ panel === 'follow' ? 'left-menu-item-click' : 'left-menu-item' }
              >
                <EyeOutlined style={{marginRight: '6px'}} /> {intl.get('WALLET_LIST')}
              </div>
              <div 
                onClick={() => setPanel('setting')} 
                className={ panel === 'setting' ? 'left-menu-item-click' : 'left-menu-item' }
              >
                <UserOutlined style={{marginRight: '6px'}} /> {intl.get('PERSONAL_SETTINGS')}
              </div>
            </div>
          </div>
          <div className="right-panel">
            { panel === 'setting' && <SettingPanel /> }
            { panel === 'follow' && <FollowPanel /> }
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  )
}
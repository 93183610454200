import { Button, message } from 'antd';
import { Modal, Row, Col, Input } from 'antd';
import intl from 'react-intl-universal';
import { bindEmailOrPhone, sendCode as reqSendCode } from '../../api/ajaxAccount';
import './bindModal.css';

export default function BindModal ({ visible, onCancel, type }) {

  const bindEmailOrPhoneFunc = async (type, bind, code) => {
    try {
      const res = await bindEmailOrPhone(type, bind, code);
      return res.data;
    } catch (e) {
      message.error(intl.get('NETWORK_ERROR'));
    }
  }

  const sendCodeFunc = async (type, to, lang) => {
    try {
      const res = await reqSendCode(type, to, lang);
      return res.data;
    } catch (e) {
      message.error(intl.get('NETWORK_ERROR'))
    }
  }

  const clickToBind = async () => {
    const emailBox = document.getElementById('email-input');
    const codeBox = document.getElementById('code-input');
    const email = emailBox.value;
    const code = codeBox.value;
    if (!email) return message.warning(type === 'email' ? intl.get('PLEASE_INPUT_EMAIL') : intl.get('PLEASE_INPUT_PHONE'));
    if (!code) return message.warning(intl.get('PLEASE_INPUT_CODE'));

    const res = await bindEmailOrPhoneFunc(type, email, code);
    if (!res || !res.success) return message.error(intl.get('FAIL_TO_BIND'));
    
    message.success(intl.get('BINDED'));
    onCancel();
  }

  const sendCode = async () => {
    const codeButton = document.getElementById('code-button');
    codeButton.disabled = true;
    const emailBox = document.getElementById('email-input');

    if (!emailBox.value) {
      codeButton.disabled = false;
      return message.warning(type === 'email' ? intl.get('PLEASE_INPUT_EMAIL') : intl.get('PLEASE_INPUT_PHONE'));
    }

    const res = await sendCodeFunc(type, emailBox.value, intl.options.currentLocale === 'en-US' ? 'en-US' : 'zh-CN');
    if (!res.success) {
      codeButton.disabled = false;
      return message.error(intl.get('FAIL_TO_SEND_CODE'));
    }

    message.success(intl.get('CODE_SENT'));
    let seconds = 60;
    const timer = setInterval(() => {
      seconds--;
      codeButton.innerText = seconds + intl.get('SECONDS_UNTIL_RESEND');
      codeButton.disabled = true;
      if (!seconds) {
        clearInterval(timer);
        codeButton.innerText = intl.get('GET_SMS_CODE');
        codeButton.disabled = false;
      }
    }, 1000);
  }

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className='bindModal'
      title={ type === 'email' ? intl.get('BIND_EMAIL') : intl.get('BIND_PHONE') }
      onCancel={onCancel}
      style={{ top: '30vh' }}
    >
      <div className='bindModal'>
        <div className='modal-input-title'>{ type === 'email' ? intl.get('EMAIL_ACCOUNT') : intl.get('MOBILE_NUMBER') }</div>
        <div className='modal-input-box'><Input allowClear={true} id='email-input' className='modal-input-class' /></div>
        <div style={{paddingTop: '20px'}} className='modal-input-title'>{ type === 'email' ? intl.get('EMAIL_CODE') : intl.get('PHONE_CODE') }</div>
        <div className='modal-input-box'>
          <Row>
            <Col lg={{ span: 16 }} sm={{ span: 12 }}>
              <Input allowClear={true} id='code-input' className='modal-input-class' />
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }} lg={{ span: 8 }} sm={{ span: 12 }}>
              <Button onClick={sendCode} id='code-button' type='primary' className='modal-input-class' style={{ width: '95%' }}>{intl.get('SEND_CODE')}</Button>
            </Col>
          </Row>
        </div>
        <Button 
          type='primary' 
          className='modal-input-class' 
          style={{ width: '100%', marginTop: '35px' }}
          onClick={clickToBind}
        >{intl.get('BIND')}</Button>
      </div>
    </Modal>
  )
}
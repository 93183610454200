import axios from 'axios';
import config from '../config';

// 登入鉴权

export const checkLoginState = async () =>
  await axios.get(`${config.mainApi}/v1/user/checkLoginState`, {
    params: {},
    withCredentials: true,
  });

export const registerLogin = async (phone, code) =>
  await axios.get(`${config.mainApi}/v1/user/registerLogin`, {
    params: {
      phone,
      code,
      phoneAreaCode: '+86'
    },
    withCredentials: true,
  });

export const registerSendCode = async (phone) =>
  await axios.get(`${config.mainApi}/v1/user/registerSendCode`, {
    params: {
      phone,
      phoneAreaCode: '+86'
    },
    withCredentials: true,
  });

export const loginByEmail = async (email, password) =>
  await axios.get(`${config.mainApi}/v1/user/loginByEmail`, {
    params: {
      email,
      password
    },
    withCredentials: true
  })

export const reqLogout = async () =>
  await axios.get(`${config.mainApi}/v1/user/logout`, {
    params: {},
    withCredentials: true
  })

export const registerByEmail = async (email, password) =>
  await axios.get(`${config.mainApi}/v1/user/registerByEmail`, {
    params: {
      email,
      password
    },
    withCredentials: true
  })

export const reqSendEmail = async (email, lang) =>
  await axios.get(`${config.mainApi}/v1/user/sendEmail`, {
    params: {
      toMail: email,
      lang
    },
    withCredentials: true
  })

export const reqChangepwd = async (email, password) =>
  await axios.get(`${config.mainApi}/v1/user/changePwd`, {
    params: {
      email,
      password
    },
    withCredentials: true
  })

export const checkEmailToken = async (email, token) =>
  await axios.get(`${config.mainApi}/v1/user/checkEmailToken`, {
    params: {
      email,
      token
    },
    withCredentials: true
  })
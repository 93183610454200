import { useState, useEffect } from 'react';
import { message } from 'antd';
import intl from 'react-intl-universal';
import { 
  getErgBlocks,
} from '../../api/ajaxMain';
import BlockSingleTable from './blockSingleTable';

export default function BlockTable ({ coin, poolStats, config, network }) {

  const [ blockList, setBlockList ] = useState([]);
  const [ currPage, setCurrPage ] = useState(0);
  const [ total, setTotal ] = useState(0);
  const [ perPage ] = useState(15);
  const [ totalShares, setTotalShares ] = useState(0);

  const getBlocks = async (page, limit) => {
    try {
      const res = ((await getErgBlocks(page, limit)).data) || [];
      return res;
    } catch (e) {
      message.error(intl.get('FAILED_TO_GET_BLOCKS'));
    }
  }

  const onTableChange = async (pagination) => {
    setCurrPage(pagination.current - 1);
    const res = (await getBlocks(pagination.current - 1, perPage)) || [];
    processData(res, pagination.current - 1);
  }

  const processData = (res, cp) => {
    processErgData(res, cp);
  }

  const processErgData = (res, cp) => {
    const totalBlocksFound = poolStats['totalBlocksFound'];
    const ergList = [];
    let index = 0;
    let pageShares = 0;
    let pageDiff = 0;
    for (const block of res) {
      pageShares += block.shares;
      pageDiff += block.diff;
      ergList.push({
        id: totalBlocksFound - index - 1 - cp * perPage,
        name: 'erg',
        time: block.ts,
        shares: `${(block.shares / block.diff * 100).toFixed(2)}%`,
        oriShares: block.shares,
        oriDiff: block.diff,
        amount: (block.value / 1e9).toFixed(6),
        height: block.height,
        valid: block.valid,
        unlocked: block.unlocked,
        mature: config.maturity_depth - (network['9053'].height - block.height),
        hash: block.hash
      })
      index += 1;
    }
    const totalShares = `${(pageShares / pageDiff * 100).toFixed(2)}%`;
    setTotalShares(totalShares);
    setBlockList(ergList);
    const total = poolStats ? poolStats['totalBlocksFound'] : 0;
    setTotal(total);
  }

  const getDataAtFirst = async () => {
    setCurrPage(0);
    const res = (await getBlocks(0, perPage)) || [];
    processData(res, 0);
  }

  useEffect(() => {
    getDataAtFirst();
    // eslint-disable-next-line
  }, [coin]);

  return (
    <>
    <div style={{paddingTop: '0px'}} className='blockTable-subtitle'>
    {intl.get('PAGE_EFFORTS')}: {` `}
      <span style={parseFloat(totalShares) < 100 ? {color: 'green'} : {color: 'red'}}>
        { totalShares }
      </span>
    </div>
    <BlockSingleTable 
      coin={coin} 
      blockList={blockList} 
      total={total} 
      onTableChange={onTableChange} 
      currPage={currPage + 1}
    />
    </>
  )
}
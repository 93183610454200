import { Switch } from 'antd';
import { useState } from 'react';
import intl from 'react-intl-universal';

export default function FreshBtn ({ refresh }) {

  const [ autoFresh, setAutoFresh ] = useState(false);
  const [ stateTimer, setStateTimer ] = useState(null);
  let timer = null;

  let freshNum = 0;
  let seconds = 60;

  const freshBtnChange = async () => {
    if (autoFresh) {
      clearInterval(stateTimer);
      setStateTimer(null);
      seconds = 60;
      return setAutoFresh(false);
    }
    setAutoFresh(true);
    timer = setInterval(() => {
      setStateTimer(timer);
      seconds -= 1;
      let freshFigure = document.getElementById('fresh-figure');
      if (!freshFigure) { 
        clearInterval(timer);
        return;
      }
      freshFigure.innerText = seconds;
      if (seconds === 0) {
        freshNum += 1;
        refresh(freshNum);
        seconds = 60;
      }
    }, 1000);
  }

  return (
    <div className='fresh-btn'>
      <div className='fresh-text'>
        { !autoFresh && intl.get('AUTO_FRESH') }
        { autoFresh && <>{intl.get('AUTO_FRESH')}(<span id='fresh-figure'>60</span>s)</> }
      </div>
      <Switch onChange={() => freshBtnChange()} />
    </div>
  )
}
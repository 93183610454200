import etcLogo from '../../assets/etc.png';
import intl from 'react-intl-universal';
import CoinIntro from '../searchCommon/coinIntro';
import SearchBar from '../searchBar/searchBar';
import ServerList from '../searchEtc/serverList';
import DataBoard from '../searchEtc/dataBoard';
import GraphBoard from '../searchEtc/graphBoard';
import TxBoard from '../searchEtc/txBoard';

export default function CoinInfo ({ change }) {

  const coinIcon = <img alt='etc' src={etcLogo} />;
  const coinName = 'Ethereum Classic';
  const coinUrl = '/search/etc';
  const coinIntro = '以太坊经典（Ethereum Classic）是一个开源、公开、 以区块链为基础的分布式计算平台。它提供了一个分散的具有图灵完备性的虚拟机。该虚拟机可以利用全世界的虚拟机节点网络执行脚本。以太坊经典是以太坊平台分叉而成的加密货币。在分叉前，经典以太坊被直接称为以太坊。分叉以后，旧分叉以太坊经典，新分叉则保留原名。在第1920000资料块出现前便拥有以太坊者都已获得相等数量的以太坊经典。 ';  
  const coinIntroEn = 'Ethereum Classic is an open source, blockchain-based distributed computing platform featuring smart contract (scripting) functionality.Ethereum Classic maintains the original, unaltered history of the Ethereum network. The Ethereum project\'s mainnet initially released via Frontier on 30 July 2015. However, due to a hack of a third-party project, the Ethereum Foundation created a new version of the Ethereum mainnet on 20 July 2016.'

  return (
    <>
    <CoinIntro coinIcon={coinIcon} coinName={coinName} coinIntro={ intl.options.currentLocale === 'en-US' ? coinIntroEn : coinIntro } coinUrl={coinUrl}
    explorer={"https://blockscout.com/etc/mainnet"} tutorial={"https://www.yuque.com/skypool/cpu/ppkb8z"}/>
    <SearchBar type='etc' />
    <ServerList />
    <DataBoard change={change} />
    <GraphBoard />
    <TxBoard />
    </>
  )
}
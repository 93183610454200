import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import announceHelpers from '../../components/announce/announce.jsx';
import CoinInfo from '../../components/searchNimiq/coinInfo';
import AccountInfo from '../../components/searchNimiq/accountInfo';

import nimiqLogo from '../../assets/nimiq-nim-logo.svg';

import '../../styles/searchPage/searchPage.css';


export default function NimiqPage ({location}) {

  const coinIcon = <img alt='nimiq' src={nimiqLogo} />;
  const coinName = 'Nimiq';
  const coinUrl = '/search/nim';
  const coinIntro = 'Nimiq 是一个2017年启动的区块链项目，其应用场景目标为支付（闪电网络，P2P货币兑换等）， 特色是只通过浏览器打开一个网页就能参与到区块链网络中进行转账和挖矿， 相当于 Web 版的比特币，大幅度降低了普通用户参与区块链的门槛。Nimiq 主流挖矿设备是显卡，也支持 CPU 挖矿。';
  const coinIntroEn = 'Nimiq is a blockchain project started in 2017, which is developed for payment like Lightning Network, P2P Currency Exchange etc. The feature is that users can transfer and mine by just opening a webpage, which makes the participation easier as opposed to Web Bitcoin. The mainstream mining device of Nimiq is disk, and CPU Mining is also supported.'

  const coinIntroInfo = {
    coinIcon, 
    coinName, 
    coinUrl, 
    coinIntro: intl.options.currentLocale === 'en-US' ? coinIntroEn : coinIntro
  };

  const [ announceList, setAnnounceList ] = useState([]);
  const [ change, setChange ] = useState(0);

  const changeCry = () => setChange(change + 1);

  useEffect(() => {
    if (change !== 0) return;
    const en = intl.options.currentLocale === 'en-US';
    announceHelpers.getAnnounce(en, 'nim', setAnnounceList);
    // eslint-disable-next-line
  }, [])

  return (
    <div className='search-page'> 
      <CommonHeader changeCry={changeCry} />
      { announceHelpers.showAnnounce(announceList) }
      <div className='search-container'>
        {location.hash && 
        <AccountInfo change={change} address={location.hash.slice(1)} />}
        {!location.hash &&
        <CoinInfo change={change} coinIntroInfo={coinIntroInfo} urlName={'nim'} />}
      </div>
      <CommonFooter />
    </div>
  )
}
import { message } from 'antd';
import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import intl from 'react-intl-universal';
import { CommonUtils } from '../../utils/commonUtils';
import { getCpuPorts } from '../../api/ajaxMain';
import {
  CaretDownOutlined,
  CaretUpOutlined
} from '@ant-design/icons';
import {
  CopyOutlined
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';

export default function ServerList () {

  const [ expanded, setExpanded ] = useState(false);
  const [ nodeList, setNodeList ] = useState([]);
  const [ resPorts, setResPorts ] = useState({});

  const toServerName = (k) => {
    if (k < 5) {
      return 'auto.skypool.xyz';
    } else if (k === 5) {
      return '【TLS】 auto.skypool.xyz';
    } else if (k <= 11) {
      return intl.get('NUMEROUS_MACHINES_CONTACT');
    } else {
      return 'f4';
    }
  }

  const nodeLoadMore = async () => {
    const ppsList = resPorts['pps'];
    const nodeList = [];
    let key = 0;
    for (const port of ppsList) {
      nodeList.push({
        server: toServerName(key),
        port: port.port,
        difficulty: port.difficulty,
        miners: port.miners,
        height: port.host.blockID,
        time: intl.options.currentLocale === 'en-US' ? CommonUtils.toUpdateTimeEn(new Date(Date.now() / 1000 - port.host.blockIDTime)): CommonUtils.toUpdateTime(new Date(Date.now() / 1000 - port.host.blockIDTime)),
        desc: port.description
      });
      key += 1;
    }
    setNodeList(nodeList);
    setExpanded(true);
  }

  const nodeLoadLess = async () => {
    const list = resPorts['global'];
    const nodeList = [];
    let key = 0;
    for (const port of list) {
      nodeList.push({
        server: toServerName(key),
        port: port.port,
        difficulty: port.difficulty,
        miners: port.miners,
        height: port.host.blockID,
        time: intl.options.currentLocale === 'en-US' ? CommonUtils.toUpdateTimeEn(new Date(Date.now() / 1000 - port.host.blockIDTime)): CommonUtils.toUpdateTime(new Date(Date.now() / 1000 - port.host.blockIDTime)),
        desc: port.description
      });
      key += 1;
    }
    setNodeList(nodeList);
    setExpanded(false);
  }

  const getResPorts = async () => {
    try {
      const resPorts = (await getCpuPorts()).data;
      return resPorts;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_SERVERLIST'));
    }
  }

  const processData = async () => {
    const resPorts = (await getResPorts()) || {};
    const resPortsList = resPorts.global || [];
    const nodeList = [];
    for (const port of resPortsList) {
      nodeList.push({
        server: port.port === 9999 ? '【TLS】 auto.skypool.xyz' : 'auto.skypool.xyz',
        port: port.port,
        difficulty: port.difficulty,
        miners: port.miners,
        height: port.host.blockID,
        time: intl.options.currentLocale === 'en-US' ? CommonUtils.toUpdateTimeEn(new Date(Date.now() / 1000 - port.host.blockIDTime)): CommonUtils.toUpdateTime(new Date(Date.now() / 1000 - port.host.blockIDTime)),
        desc: port.description
      })
    }
    setNodeList(nodeList);
    setResPorts(resPorts);

  }

  const copyByPort = (port) => {
    copy(`auto.skypool.xyz:${port}`)
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  if (nodeList.length === 0 || Object.keys(resPorts) === 0) {
    return (
      <div className='spin-box'><Spin /></div>
    )
  }

  return (
    <div className='sites-sec'>
      <div className='sites-sec-title'>
        {intl.get('SERVERLIST')}
      </div>
      <div 
        style={{
          padding: '10px 30px',
          background: 'white'
        }}
        className='main-panel-table'
      >
        {/* <div className='main-panel-subtitle'>
          <Alert
            className='server-list-alert' 
            message={intl.get('LARGE_HASHRATE_VALIDATOR')}
            type="warning"
          />
        </div> */}
        <table style={{width: '100%'}}>
          <thead style={{backgroundColor: 'white', color: 'black'}} className='panel-table-thead'>
            <tr>
              <th className='sites-sec-th'>{intl.get('SERVER')}</th>
              <th className='sites-sec-th'>{intl.get('PORT')}</th>
              <th className='sites-sec-th'>{intl.get('DIFFICULTY')}</th>
              <th className='sites-sec-th'>{intl.get('MACHINE')}</th>
              <th className='sites-sec-th'>{intl.get('CURRENT_HEIGHT')}</th>
              <th className='sites-sec-th'>{intl.get('DESC')}</th>
            </tr>
          </thead>
          <tbody>
            {
              nodeList.map((item, key) => {
                return (
                  <tr 
                    key={key}
                  >
                    <td className='addr sites-sec-td'>
                      {item.server}
                      { item.server === 'auto.skypool.xyz' &&
                        <CopyOutlined 
                          style={{marginLeft: '10px', cursor: 'pointer'}} 
                          onClick={() => {copyByPort(item.port); message.success(intl.get('COPIED_TO_CLIPBOARD'))}}
                        /> }
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.port}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.difficulty}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.miners}
                    </td>
                    <td className='addr sites-sec-td'>
                      {item.height} ({item.time})
                    </td>
                    <td style={{width: '30%'}} className='addr sites-sec-td'>
                      {item.desc}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {
          expanded ?
          <div onClick={nodeLoadLess} className='load-more-btn'>
            <CaretUpOutlined style={{fontSize: '12px', marginRight: '2px'}} />{intl.get('SHOW_LESS')}
          </div> :
          <div onClick={nodeLoadMore} className='load-more-btn'>
            <CaretDownOutlined style={{fontSize: '12px', marginRight: '2px'}} />{intl.get('SHOW_MORE')}
          </div>
        }

      </div>
    </div>
  )
}
import {Button, Form, Input, message} from 'antd';
import { 
  UserOutlined, 
  LockOutlined
} from '@ant-design/icons';
import intl from 'react-intl-universal';
import {
  registerByEmail
} from '../../api/ajaxAuth';

import './registerPage.css';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';

export default function RegisterPage ({ history }) {

  const onFinish = async (values) => {
    const { email, password } = values;
    const response = await registerByEmail(email, password);
    if (!response.data.success) {
      return message.error(intl.get('REGISTER_FAIL'));
    }
    message.success(intl.get('REGISTER_SUCCESS'));
    history.replace(`/login?email=${email}`);
  }

  const INVALID_EMAIL = intl.get('INVALID_EMAIL');
  const PLEASE_INPUT_EMAIL = intl.get('PLEASE_INPUT_EMAIL');
  const PLEASE_INPUT_PASSWORD = intl.get('PLEASE_INPUT_PASSWORD');
  const PASSWORD_LENGTH_ERROR = intl.get('PASSWORD_LENGTH_ERROR');
  const PLEASE_SET_A_PASSWORD = intl.get('PLEASE_SET_A_PASSWORD');
  const PLEASE_CONFIRM_YOUR_PASSWORD = intl.get('PLEASE_CONFIRM_YOUR_PASSWORD');
  const PASSWORD_NOT_MATCH = intl.get('PASSWORD_NOT_MATCH');

  return (
    <div className='registerPageMain'>
      <CommonHeader />
      <div className='reg-page'>
        <section className='reg-section'>
          <div className='reg-container'>
            <div className='reg-panel'>
              <div className='reg-title'>
                <p>{intl.get('REGISTER_AND_BEGIN')}</p>
              </div>
              <div className='reg-link'>
                <span>{intl.get('HAS_ACCOUNT')}<a className='learn-more' href='/login'>{intl.get('TO_LOGIN')}</a></span>
              </div>
              <Form
                name="eth_reg"
                className="reg-form"
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: INVALID_EMAIL,
                    },
                    {
                      required: true,
                      message: PLEASE_INPUT_EMAIL,
                    },
                  ]}
                >
                  <Input 
                    prefix={<UserOutlined className="site-form-item-icon" />} 
                    placeholder={PLEASE_INPUT_EMAIL}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: PLEASE_INPUT_PASSWORD,
                    },
                    {
                      min: 6,
                      message: PASSWORD_LENGTH_ERROR
                    },
                    {
                      max: 12,
                      message: PASSWORD_LENGTH_ERROR
                    }
                  ]}
                  hasFeedback
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={PLEASE_SET_A_PASSWORD}
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: PLEASE_CONFIRM_YOUR_PASSWORD,
                    },
                    {
                      min: 6,
                      message: PASSWORD_LENGTH_ERROR
                    },
                    {
                      max: 12,
                      message: PASSWORD_LENGTH_ERROR
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error(PASSWORD_NOT_MATCH));
                      },
                    }),
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={PLEASE_CONFIRM_YOUR_PASSWORD}
                  />
                </Form.Item>

                <Form.Item>
                  <Button style={{borderRadius: '1rem'}} id="reg-form-button" type="primary" htmlType="submit">
                    {intl.get('REGISTER')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </section>
      </div>
      <CommonFooter />
    </div>
  )
}
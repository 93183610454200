import { useState, useEffect } from 'react';
import { CommonUtils } from '../../utils/commonUtils';
import { Row, Col, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import memoryUtils from '../../utils/memoryUtils';
import {
  DatabaseOutlined,
  ShareAltOutlined,
  WalletOutlined,
  QuestionCircleFilled
} from '@ant-design/icons';

export default function AccountDataBoard ({res, resGlobal, priceData}) {

  const [ info, setInfo ] = useState({});

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  const processData = () => {
    const balance = res.balance.toFixed(2);
    const hashrate = CommonUtils.toHashrate(Math.floor(res.minerLastHashrate));
    const hashrate24 = CommonUtils.toHashrate(Math.floor(res.average24hHashrate));
  
    let onlineCount = Object.keys(res.workers).length;
    let offlineCount = 0;
  
    const rvnGlobalHashrate = parseFloat(resGlobal.poolStats.networkSols) / 1e12;
    const rvnReward = 2500;
    const reward = (rvnReward * 1440 / (rvnGlobalHashrate * 1000 * 1000)).toFixed(2);
    const rewardRvn = rvnReward * 1440 / (rvnGlobalHashrate * 1000 * 1000) * res.average24hHashrate / 1e6;
    setInfo({
      balance,
      hashrate,
      hashrate24,
      reward,
      onlineCount,
      offlineCount,
      rewardRvn,
      rewardCny: rewardRvn * priceData['RVN'].CNY
    })
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  if (Object.keys(info).length === 0) return <div></div>;

  return (
    <section className='data-sec'>
      <Row align='middle'>
        <Col sm={{span: 24}} lg={{span: 5}} >
          <div className='data-block'>
            <div className='block-title'>
            <DatabaseOutlined style={{marginRight: '0.5rem'}} />{intl.get('MACHINE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('ONLINE')}</div>
                <div style={{color: 'green'}} className='lower-figure'>{info.onlineCount}</div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('OFFLINE')}</div>
                <div style={{color: 'red'}} className='lower-figure'>{info.offlineCount}</div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 12}} >
          <div className='data-block'>
            <div className='block-title'>
            <ShareAltOutlined style={{marginRight: '0.5rem'}} />{intl.get('HASHRATE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} sm={{span: 12}} lg={{span: 8}}>
                <div className='lower-title'>
                  {intl.get('CURRENT_HASHRATE')} 
                  <Tooltip placement="bottom" title={intl.get('CURR_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>{info.hashrate}</div>
              </Col>
              <Col className='lg-right-border' sm={{span: 12}} lg={{span: 8}}>
                <div className='lower-title'>
                  {intl.get('24_HASHRATE')}
                  <Tooltip placement="bottom" title={intl.get('24_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>{info.hashrate24}</div>
              </Col>
              <div className='updown-divider sm-show'></div>
              <Col  sm={{span: 24}} lg={{span: 8}}>
                <div className='lower-title'>
                  {intl.get('DAILY_REWARD')} 
                  <Tooltip placement="bottom" title={intl.get('D_REWARD_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>
                  {info.rewardRvn.toFixed(2)}{` RVN`}
                  <span className='unit'>{` ≈ `}{crySign}{crySign === '$' ? (info.rewardCny / exchange).toFixed(2) : (info.rewardCny).toFixed(2)}</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 7}} >
          <div className='data-block'>
            <div className='block-title'>
            <WalletOutlined style={{marginRight: '0.5rem'}} />{intl.get('PERSONAL')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('WALLET_BALANCE')}</div>
                <div className='lower-figure'>{info.balance}{' RVN'}</div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('COIN_REWARD')}</div>
                <div className='lower-figure'>
                  {info.reward} <span className='unit'> RVN/M</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  )
}
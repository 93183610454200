import ethLogo from '../assets/eth.png';
import nimLogo from '../assets/nimiq-nim-logo.svg';
import rvnLogo from '../assets/ravencoin-rvn-logo.svg';
import xmrLogo from '../assets/monero-xmr-logo.svg';
import arqLogo from '../assets/arq.png';
import qrlLogo from '../assets/qrl.png';
import zephLogo from '../assets/zeph.png';
import xhvLogo from '../assets/xhv.png';
import etcLogo from '../assets/etc.png';
import btgLogo from '../assets/btg.png';
import ergLogo from '../assets/erg.jpeg';
import cfxLogo from '../assets/cfx.png';
import kasLogo from '../assets/kas.png';
import intl from 'react-intl-universal';

const homeUtils = {
  getLogo: (name) => {
    switch (name) {
      case 'NIM':
        return nimLogo;
      case 'RVN':
        return rvnLogo;
      case 'XMR':
        return xmrLogo;
      case 'ARQ':
        return arqLogo;
      case 'QRL':
        return qrlLogo;
      case 'ZEPH':
        return zephLogo;
      case 'ETH':
        return ethLogo;
      case 'ETC':
        return etcLogo;
      case 'BTG':
        return btgLogo;
      case 'ERG': 
        return ergLogo;
      case 'CFX': 
        return cfxLogo;
      case 'KAS': 
        return kasLogo;
      default:
        return xhvLogo;
    }
  },
  getAlgo: (name) => {
    switch (name) {
      case 'XMR':
      case 'ARQ':
      case 'QRL':
      case 'ZEPH':
        return 'CPU';
      case 'XHV':
        return `CPU / ${intl.get('GRAPHICS_CARD')}`;
      case 'XCH':
        return intl.get('DISK');
      default:
        return intl.get('GRAPHICS_CARD');
    }
  },
  getGoto: (name) => {
    switch (name) {
      case 'XMR':
      case 'ARQ':
      case 'QRL':
      case 'ZEPH':
      case 'XHV':
        return `/search/cpu`;
      case 'NIM':
        return `/search/nim`;
      case 'RVN':
        return `/search/rvn`;
      case 'ERG':
        return `/search/erg`;
      case 'ETC':
        return `/search/etc`;
      case 'bar':
      default:
        return `https://bar.skypool.xyz`;
    }
  },
  getRate1: (name) => {
    switch (name) {
      case 'NIM':
        return 'PPLNS';
      case 'bar':
      default:
        return 'PPS+';
    }
  },
  getRate2: (name) => {
    switch (name) {
      case 'RVN':
      case 'ERG':
      case 'ETC':
        return '1.5%';
      case 'bar':
        return '2%';
      default:
        return '1%';
    }
  }
}

export default homeUtils;
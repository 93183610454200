/*eslint-disable*/ 
export const CommonUtils = {
  toHashrate: (hash) => {
    if (hash > 1e12) {
      return (hash / 1e12).toFixed(2).toString() + " TH/s";
    }
    if (hash > 1e9) {
      return (hash / 1e9).toFixed(2).toString() + " GH/s";
    }
    if (hash > 1e6) {
      return (hash / 1e6).toFixed(2).toString() + " MH/s";
    }
    if (hash > 1e3) {
      return (hash / 1e3).toFixed(2).toString() + " KH/s";
    }
    return Math.floor(hash + 0.01 || 0.0).toFixed(2).toString() + " H/s"
  },
  toMH: (hash) => {
    return (hash / 1e6).toFixed(2).toString() + " MH/s";
  },
  toUpdateTime: (time) => {
    if (time < 60) {
      return '几秒前';
    } else if (time < 3600) {
      const minutes = Math.floor(time / 60);
      return `${minutes} 分钟前`;
    } else if (time < 24 * 3600) {
      const hours = Math.floor(time / 3600);
      return `${hours} 小时前`;
    } else if (time < 30 * 24 * 3600) {
      const days = Math.floor(time / (24 * 3600));
      return `${days} 天前`;
    } else if (time < 365 * 24 * 3600) {
      const months = Math.floor(time / (30 * 24 * 3600));
      return `${months} 月前`;
    } else {
      const years = Math.floor(time / (365 * 30 * 24 * 3600));
      return `${years} 年前`;
    }
  },
  toUpdateTimeEn: (time) => {
    if (time < 60) {
      return 'Several seconds ago';
    } else if (time < 3600) {
      const minutes = Math.floor(time / 60);
      return `${minutes} minutes ago`;
    } else if (time < 24 * 3600) {
      const hours = Math.floor(time / 3600);
      return `${hours} hours ago`;
    } else if (time < 30 * 24 * 3600) {
      const days = Math.floor(time / (24 * 3600));
      return `${days} days ago`;
    } else if (time < 365 * 24 * 3600) {
      const months = Math.floor(time / (30 * 24 * 3600));
      return `${months} months ago`;
    } else {
      const years = Math.floor(time / (365 * 30 * 24 * 3600));
      return `${years} years ago`;
    }
  },
  fmoney: (s) => {
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(0) + "";
    var l = s.split(".")[0].split("").reverse(),
        r = s.split(".")[1];
    let t = "";
    for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
    }
    return t.split("").reverse().join("");
  },
  formatDate: (DateObj) => {
    return `${DateObj.getFullYear()}-${(DateObj.getMonth() + 1) < 10 ? `0${DateObj.getMonth() + 1}`: DateObj.getMonth() + 1}-${DateObj.getDate() < 10 ? `0${DateObj.getDate()}`: DateObj.getDate()} 
    ${DateObj.getHours() < 10 ? `0${DateObj.getHours()}`: DateObj.getHours()}:${DateObj.getMinutes() < 10 ? `0${DateObj.getMinutes()}`: DateObj.getMinutes()}:${DateObj.getSeconds() < 10 ? `0${DateObj.getSeconds()}`: DateObj.getSeconds()}`;
  },
  formatDateShort: (DateObj) => {
    return `${(DateObj.getMonth() + 1) < 10 ? `0${DateObj.getMonth() + 1}`: DateObj.getMonth() + 1}-${DateObj.getDate() < 10 ? `0${DateObj.getDate()}`: DateObj.getDate()} ${DateObj.getHours() < 10 ? `0${DateObj.getHours()}`: DateObj.getHours()}:${DateObj.getMinutes() < 10 ? `0${DateObj.getMinutes()}`: DateObj.getMinutes()}`;
  },
  formatDateD: (DateObj) => {
    return `${(DateObj.getMonth() + 1) < 10 ? `0${DateObj.getMonth() + 1}`: DateObj.getMonth() + 1}-${DateObj.getDate() < 10 ? `0${DateObj.getDate()}`: DateObj.getDate()}`;
  },
  parseHash: (hashrate) => {
    if (hashrate < 1e6) {
      return (hashrate / 1e3).toFixed(2) + ' KH';
    } else if (hashrate < 1e9) {
      return (hashrate / 1e6).toFixed(2) + ' MH';
    } else if (hashrate < 1e12) {
      return (hashrate / 1e9).toFixed(2) + ' GH';
    } else {
      return (hashrate / 1e12).toFixed(2) + ' TH';
    }
  },
  parseNimAddr: (addr) => {
    // no space
    if (addr.length === 36) {
      const arr = Array.from(addr);
      let res = [];
      for (let i = 0; i < 36; i += 4) {
        res.push(arr[i], arr[i + 1], arr[i + 2], arr[i + 3]);
        res.push(' ');
      }
      return res.join('').slice(0, -1);
    }
    if (addr.length === 60) {
      return addr.split('%20').join(' ');
    }
    return addr
  },
  parseNimAddrStrict: (addr) => {
    if (addr.length === 36) {
      if (!(/NQ/i.test(addr.slice(0, 2)))) {
        return false;
      }
      const arr = Array.from(addr);
      let res = [];
      for (let i = 0; i < 36; i += 4) {
        res.push(arr[i], arr[i + 1], arr[i + 2], arr[i + 3]);
        res.push(' ');
      }
      return res.join('').slice(0, -1);
    }
    if (addr.length === 44) {
      if (!(/NQ/i.test(addr.slice(0, 2)))) {
        return false;
      }
      const groups = addr.split(' ');
      if (groups.length !== 9) {
        return false;
      }
      for (const group of groups) {
        if (!(/[A-Za-z0-9]{4}/.test('group'))) {
          return false;
        }
      }
      return addr;
    }
    return false;
  },
  parseRvnAddrStrict: (addr) => {
    if (addr.length === 34) {
      if (addr[0] === 'R' || addr[0] === 'r') {
        return addr;
      }
      return false;
    }
    return false;
  },
  parseXmrAddrStrict: (addr) => {
    if (addr.length < 95) {
      return false;
    }
    if (addr[0] !== '4' && addr[0] !== '8') {
      return false;
    }
    return addr;
  },
  parseXchAddrStrict: (addr) => {
    if (addr.length !== 66) return false;
    if (addr.slice(0, 2) !== '0x') return false;
    return addr;
  },
  parseErgAddrStrict: (addr) => {
    if (addr.length !== 51 && addr.length !== 52) return false;
    if (addr[0] !== '9') return false;
    return addr;
  },
  parseEtcAddrStrict: (addr) => {
    if (addr.length !== 42) return false;
    if (addr.slice(0, 2) !== '0x') return false;
    return addr;
  },
  difftoHashrate: (hash, coin) => {
    switch (coin) {
      case 'qrl':
      case 'QRL':
        return CommonUtils.toHashrate(Math.floor(hash / 60));
      case 'ETC':
        return CommonUtils.toHashrate(Math.floor(hash / 13))
      default:
        return CommonUtils.toHashrate(Math.floor(hash / 120));
    }
  },
  toCoinName: (name) => {
    switch (name) {
      case 'global':
      case 'GLOBAL':
      case '18081':
      case 18081:
        return 'XMR';
      case 'altblocks':
      case '18082':
      case 18082:
        return 'ALT-COINS';
      case '19994':
      case 19994:
        return 'ARQ';
      case '18090':
      case 18090:
        return 'QRL';
      case '17767':
      case 17767:
        return 'ZEPH';
      case '17750':
      case 17750:
        return 'XHV';
      default:
        return name;
    }
  },  
  updateValue: (coin, value) => {
    coin = parseInt(coin);
    switch (coin) {
      case 19994:
        value *= 1e3;
        break;
      case 34568:
        value *= 1e1;
        break;
      case 17402:
        value *= 1e2;
        break;
      case 18090:
        value *= 1e3;
        break;
      case 17750:
        value *= 1;
        break;
    }
    return value;
  },
  hashToLink: (hash, type, port, height) => {
    let str;
    if (port === 19994) {
      str = "https://explorer.arqma.com/" + type + "/" + hash;
    } else if (port === 34568) {
      str = "https://explore.wownero.com/" + type + "/" + hash;
    } else if (port === 17402) {
      str = "http://explore.safex.io/search?value=" + hash;
    } else if (port === 18090) {
      str = "https://explorer.theqrl.org/" + type + "/" + height;
    } else if (port === 17750) {
      str = "https://explorer.havenprotocol.org/" + type + "/" + hash;
    } else if (port === 17767) {
      str = "https://explorer.zephyrprotocol.com/" + type + "/" + hash;
    } else {
      str = "https://xmrchain.net/" + type + "/" + hash;
    }
    return str; 
  },
  toTime: (seconds) => {
    if (seconds < 60) return `${(seconds).toFixed(2)} 秒`;
    if (seconds < 60 * 60) return `${(seconds / 60).toFixed(2)} 分钟`;
    if (seconds < 60 * 60 * 24) return `${(seconds / 60 / 60).toFixed(2)} 小时`;
    return `${(seconds / 60 / 60 / 24).toFixed(2)} 天`;
  },
  toTimeEn: (seconds) => {
    if (seconds < 60) return `${(seconds).toFixed(2)} second(s)`;
    if (seconds < 60 * 60) return `${(seconds / 60).toFixed(2)} minute(s)`;
    if (seconds < 60 * 60 * 24) return `${(seconds / 60 / 60).toFixed(2)} hour(s)`;
    return `${(seconds / 60 / 60 / 24).toFixed(2)} day(s)`;
  }
}
import { useState, useEffect } from 'react';
import { Modal, Row, Col, Select, Input, message } from "antd";
import './calculator.css';
import {
  SwapOutlined
} from '@ant-design/icons';
import { getReward } from '../../api/ajaxMain';
import intl from 'react-intl-universal';
import rvnLogo from '../../assets/ravencoin-rvn-logo.svg';
import etcLogo from '../../assets/etc.png';
import ergLogo from '../../assets/erg.jpeg';
import xmrLogo from '../../assets/monero-xmr-logo.svg';
import arqLogo from '../../assets/arq.png';
import qrlLogo from '../../assets/qrl.png';
import xhvLogo from '../../assets/xhv.png';
import zephLogo from '../../assets/zeph.png';

const { Option } = Select;

export default function Calculator ({ visible, onCancel }) {

  const [ coin, setCoin ] = useState('etc');
  const [ reward, setReward ] = useState(0.000);
  const [ rewardCny, setRewardCny ] = useState(0.000);
  const [ rewardData, setRewardData ] = useState({});

  const getRewardFunc = async () => {
    try {
      const response = await getReward();
      setRewardData(response.data.data);
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_REWARD_INFO'));
    }
  }

  const handleChange = (value) => {
    setCoin(value);
    const hashrateBox = document.getElementById('hashrate-box');
    const val = parseFloat(String(hashrateBox.value)) || 0.00;
    const reward = calcReward(val, value);
    const rewardCny = calcRewardCny(val, value);
    setReward(reward);
    setRewardCny(rewardCny);
  }

  const calcReward = (amount, coin) => {
    if (coin === 'etc') {
      return (rewardData['ETC'].reward * parseFloat(amount)).toFixed(6);
    }
    if (coin === 'xmr') {
      return (rewardData['XMR'].reward * 1e3 * parseFloat(amount)).toFixed(5);
    }
    return (rewardData[coin.toUpperCase()].share * rewardData['XMR'].reward * 1e3 * parseFloat(amount)).toFixed(5)
  }

  const calcRewardCny = (amount, coin) => {
    if (coin === 'etc') {
      return (rewardData['ETC'].rewardCny * parseFloat(amount)).toFixed(2);
    }
    if (coin === 'xmr') {
      return (rewardData['XMR'].rewardCny * 1e3 * parseFloat(amount)).toFixed(3);
    }
    return (rewardData[coin.toUpperCase()].share * rewardData['XMR'].rewardCny * 1e3 * parseFloat(amount)).toFixed(3)
  }

  const onChange = (e) => {
    const value = parseFloat(String(e.target.value)) || 0.00;
    const reward = calcReward(value, coin);
    const rewardCny = calcRewardCny(value, coin);
    setReward(reward);
    setRewardCny(rewardCny);
  }

  const toSuffix = (coin) => {
    if (coin === 'xmr' ||  coin === 'etc') {
      return coin.toUpperCase();
    }
    return 'XMR';
  }

  useEffect(() => {
    if (visible) getRewardFunc();
  }, [visible]);

  let suffix;
  const netNames = ['xmr', 'arq', 'qrl', 'zeph', 'xhv']
  if (coin === 'nim' || netNames.includes(coin)) {
    suffix = "K";
  } else {
    suffix = "M";
  } 

  return (
    <div className='calculator-home'>
      <Modal
        visible={visible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        className='calculator-home'
        title={intl.get('REWARDS_CALCULATOR')}
        onCancel={onCancel}
        style={{ top: '30vh' }}
      >
        <div className='calculator-input-row'>
          <Row>
            <Col lg={{span : 6}} md={{span : 6}} sm={{span: 12}}>
              <div className='calculator-input-con'>
                <div className='calculator-input-title'>
                  {intl.get('COIN')}
                </div>
                <div className='calculator-input-box'>
                  <Select defaultValue="etc" style={{width:'100%'}} onChange={handleChange}>
                    <Option value="etc">
                      <span className='select-img-con'>
                        <img className='select-img' alt='logo' src={etcLogo} />
                      </span>
                      ETC
                    </Option>
                    <Option value="xmr">
                      <span className='select-img-con'>
                        <img className='select-img' alt='logo' src={xmrLogo} />
                      </span>
                      XMR
                    </Option>
                    <Option value="arq">
                      <span className='select-img-con'>
                        <img className='select-img' alt='logo' src={arqLogo} />
                      </span>
                      ARQ
                    </Option>
                    <Option value="qrl">
                      <span className='select-img-con'>
                        <img className='select-img' alt='logo' src={qrlLogo} />
                      </span>
                      QRL
                    </Option>
                    <Option value="zeph">
                      <span className='select-img-con'>
                        <img className='select-img' alt='logo' src={zephLogo} />
                      </span>
                      ZEPH
                    </Option>
                    <Option value="xhv">
                      <span className='select-img-con'>
                        <img className='select-img' alt='logo' src={xhvLogo} />
                      </span>
                      XHV
                    </Option>
                  </Select>
                </div>
              </div>
            </Col>
            <Col lg={{span : 6}} md={{span : 6}} sm={{span: 12}}>
              <div className='calculator-input-con'>
                <div className='calculator-input-title'>
                  {intl.get('HASHRATE')}
                </div>
                <div className='calculator-input-box'>
                  <Input id='hashrate-box' placeholder={0} onChange={onChange} suffix={suffix} />
                </div>
              </div>
            </Col>
            <Col lg={{span : 3}} md={{span : 3}} sm={{span: 24}}>
              <div 
                style={{ color: 'transparent', userSelect: 'none' }} 
                className='calculator-input-title'
              >
                #
              </div>
              <div
                style={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  height: '32px',      
                  alignItems: 'center'
                }}
                className='calculator-input-box'
              >
                <SwapOutlined className='calc-swap' />
              </div>
            </Col>
            <Col lg={{span : 9}} md={{span : 9}} sm={{span: 24}}>
              <div className='calculator-input-con'>
                <div className='calculator-input-title'>
                  {intl.get('REWARD')}
                </div>
                <div style={{position: 'relative'}} className='calculator-input-box'>
                  <Input 
                    value={reward} 
                    readOnly='readonly' 
                    suffix={`${toSuffix(coin)} / ${intl.get('DAY')}`} 
                  />
                  <div className='calculator-hanging-box'>
                    { reward !== 0 && `≈ ${rewardCny} RMB / ${intl.get('DAY')}` }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='addition-line'>
          * 1 MH/s = 1000 KH/s
        </div>
        <div className='comment-box'>
          {intl.get('CALC_COMMENT')}
        </div>
      </Modal>
    </div>
  )
}
import { Row, Col, Tooltip } from 'antd';
import {
  DatabaseOutlined,
  ShareAltOutlined,
  WalletOutlined,
  QuestionCircleFilled
} from '@ant-design/icons';
import intl from 'react-intl-universal';
import memoryUtils from '../../utils/memoryUtils';

export default function AccountDataBoard ({ global, onlineCount, offlineCount, avgHashrate, avgHashrateRaw }) {

  let exchange = 1;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  const rewardXmr = global.reward * avgHashrateRaw;

  return (
    <section className='data-sec'>
      <Row align='middle'>
        <Col sm={{span: 24}} lg={{span: 4}}>
          <div className='data-block'>
            <div className='block-title'>
            <DatabaseOutlined style={{marginRight: '0.5rem'}} />{intl.get('MACHINE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('ONLINE')}</div>
                <div style={{color: 'green'}} className='lower-figure'>
                  {onlineCount}
                </div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('OFFLINE')}</div>
                <div style={{color: 'red'}} className='lower-figure'>
                  {offlineCount}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 13}}>
          <div className='data-block'>
            <div className='block-title'>
            <ShareAltOutlined style={{marginRight: '0.5rem'}} />{intl.get('HASHRATE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} lg={{ span: 7}} sm={{ span: 12 }}>
                <div className='lower-title'>
                  {intl.get('CURRENT_HASHRATE')} 
                  <Tooltip placement="bottom" title={intl.get('CURR_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>{global.hash}</div>
              </Col>
              <Col  className='lg-right-border' lg={{ span: 7 }} sm={{ span: 12 }}>
                <div className='lower-title'>
                  {intl.get('24_HASHRATE')}
                  <Tooltip placement="bottom" title={intl.get('24_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>{ avgHashrate }</div>
              </Col>
              <div className='updown-divider sm-show'></div>
              <Col lg={{ span: 10 }} sm={{ span: 24 }}>
                <div className='lower-title'>
                  {intl.get('DAILY_REWARD')} 
                  <Tooltip placement="bottom" title={intl.get('D_REWARD_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>
                  {rewardXmr.toFixed(6)}{` XMR`}
                  <span className='unit'>
                    {` ≈ `}{crySign}
                    { (global.price * rewardXmr / exchange).toFixed(2) }
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 7}}>
          <div className='data-block'>
            <div className='block-title'>
            <WalletOutlined style={{marginRight: '0.5rem'}} />{intl.get('PERSONAL')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('WALLET_BALANCE')}</div>
                <div className='lower-figure'>{global.amountDue}{' XMR'}</div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('HAS_TRANSFERED')}</div>
                <div className='lower-figure'>{global.amountPaid}{' XMR'}</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  )
}
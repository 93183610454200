import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import CommonHeader from '../../components/mainCommon/commonHeader';
import CommonFooter from '../../components/mainCommon/commonFooter';
import AccountInfo from '../../components/searchCpu/accountInfo';
import CoinInfo from '../../components/searchCpu/coinInfo';
import announceHelpers from '../../components/announce/announce.jsx';

export default function CpuPage ({ location }) {

  const [ announceList, setAnnounceList ] = useState([]);
  const [ change, setChange ] = useState(0);

  const changeCry = () => setChange(change + 1);

  useEffect(() => {
    if (change !== 0) return;
    const en = intl.get('MENU') === 'Menu';
    announceHelpers.getAnnounce(en, 'cpu', setAnnounceList);
    // eslint-disable-next-line
  }, [change])

  return (
    <div className='search-page'> 
      <CommonHeader changeCry={changeCry} />
      { announceHelpers.showAnnounce(announceList) }
      <div className='search-container'>
        {location.hash && 
        <AccountInfo change={change} address={location.hash.slice(1)} />}
        {!location.hash &&
        <CoinInfo change={change} />}
      </div>
      <CommonFooter />
    </div>
  )
}
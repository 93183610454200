import rvnLogo from '../../assets/ravencoin-rvn-logo.svg';
import intl from 'react-intl-universal';
import CoinIntro from '../searchCommon/coinIntro';
import SearchBar from '../searchBar/searchBar';
import ServerList from '../searchRvn/serverList';
import DataBoard from '../searchRvn/dataBoard';
import GraphBoard from '../searchRvn/graphBoard';

export default function CoinInfo ({ change }) {

  const coinIcon = <img alt='rvn' src={rvnLogo} />;
  const coinName = 'Ravencoin';
  const coinUrl = '/search/rvn';
  const coinIntro = 'Raven Coin 中文名渡鸦币，Raven Coin 是基于X16R算法的POW数字货币， 代币称为RVN。于2018年1月3日发布，是一个真正的开源项目。 Ravencoin旨在部署一个区块链应用， 该应用专门对将通证等资产从一个持有者转移到另一个持有者的用例进行了优化。';  
  const coinIntroEn = 'Raven Coin is a POW digital currency based on X16R algorithm. It is published on January 3rd, 2018 and is a real open source project. The target of Ravencoin is to deploy a blockchain application that optimizes the process of asset transfer.'

  return (
    <>
    <CoinIntro coinIcon={coinIcon} coinName={coinName} coinIntro={ intl.options.currentLocale === 'en-US' ? coinIntroEn : coinIntro } coinUrl={coinUrl}
    originalSite={"https://rvn.skypool.org/"} explorer={"https://ravencoin.network/"} tutorial={"https://www.yuque.com/skypool/cpu/up804g"}/>
    <SearchBar type='rvn' />
    <ServerList />
    <DataBoard change={change} />
    <GraphBoard />
    </>
  )
}
import { useState, useEffect } from 'react';
import { CommonUtils } from '../../utils/commonUtils';
import { Row, Col, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import memoryUtils from '../../utils/memoryUtils';
import {
  DatabaseOutlined,
  ShareAltOutlined,
  WalletOutlined,
  QuestionCircleFilled
} from '@ant-design/icons';

export default function AccountDataBoard ({stats, account, priceData}) {

  const [ info, setInfo ] = useState({});

  let exchange = 6.7;
  if (memoryUtils.currency === 'USD') {
    exchange = memoryUtils.USD_CNY;
  }
  const crySign = memoryUtils.currency === 'USD' ? '$' : '¥';

  const processData = () => {
    const balance = (account.stats.balance / 1e9).toFixed(4);
    const hashrate = CommonUtils.toHashrate(account.currentHashrate);
    const hashrate24 = CommonUtils.toHashrate(account.hashrate); // 实际4小时，后端 largeWindow
  
    let onlineCount = account.workersOnline || 0;
    let offlineCount = account.workersOffline || 0;
    
    const etcRewardPerBlock = 2.56;
    const reward = (etcRewardPerBlock * 8.64e10 / Number(stats.nodes[0].difficulty)).toFixed(6); // 1M
    const yourReward = reward * account.hashrate / 1e6;

    setInfo({
      balance,
      hashrate,
      hashrate24,
      reward,
      onlineCount,
      offlineCount,
      yourReward,
      yourRewardCny: yourReward * priceData['ETC'].CNY,
    })
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line
  }, []);

  if (Object.keys(info).length === 0) return <div></div>;

  return (
    <section className='data-sec'>
      <Row align='middle'>
        <Col sm={{span: 24}} lg={{span: 5}} >
          <div className='data-block'>
            <div className='block-title'>
            <DatabaseOutlined style={{marginRight: '0.5rem'}} />{intl.get('MACHINE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('ONLINE')}</div>
                <div style={{color: 'green'}} className='lower-figure'>{info.onlineCount}</div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('OFFLINE')}</div>
                <div style={{color: 'red'}} className='lower-figure'>{info.offlineCount}</div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 12}} >
          <div className='data-block'>
            <div className='block-title'>
            <ShareAltOutlined style={{marginRight: '0.5rem'}} />{intl.get('HASHRATE')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} sm={{span: 12}} lg={{span: 8}}>
                <div className='lower-title'>
                  {intl.get('CURRENT_HASHRATE')} 
                  <Tooltip placement="bottom" title={intl.get('CURR_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>{info.hashrate}</div>
              </Col>
              <Col className='lg-right-border' sm={{span: 12}} lg={{span: 8}}>
                <div className='lower-title'>
                  {intl.get('4_HASHRATE')}
                  <Tooltip placement="bottom" title={intl.get('4_HR_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>{info.hashrate24}</div>
              </Col>
              <div className='updown-divider sm-show'></div>
              <Col  sm={{span: 24}} lg={{span: 8}}>
                <div className='lower-title'>
                  {intl.get('DAILY_REWARD')} 
                  <Tooltip placement="bottom" title={intl.get('D_REWARD_TITLE')}>
                    <QuestionCircleFilled style={{cursor: 'pointer', color: 'var(--color6)', fontSize: '1.1rem', marginLeft: '0.4rem'}} />
                  </Tooltip>
                </div>
                <div className='lower-figure'>
                  {info.yourReward.toFixed(6)}{` ETC`}
                  <span className='unit'>{` ≈ `}{crySign}{crySign === '$' ? (info.yourRewardCny / exchange).toFixed(2) : (info.yourRewardCny).toFixed(2)}</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={{span: 24}} lg={{span: 7}} >
          <div className='data-block'>
            <div className='block-title'>
            <WalletOutlined style={{marginRight: '0.5rem'}} />{intl.get('PERSONAL')}
            </div>
            <Row>
              <Col style={{borderRight: '1px solid rgb(224, 224, 224)'}} span={12}>
                <div className='lower-title'>{intl.get('WALLET_BALANCE')}</div>
                <div className='lower-figure'>{info.balance}{' ETC'}</div>
              </Col>
              <Col span={12}>
                <div className='lower-title'>{intl.get('COIN_REWARD')}</div>
                <div className='lower-figure'>
                  {info.reward} <span className='unit'> ETC/M</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  )
}
import { useState } from 'react';
import BlockTable from './blockTable';
import intl from 'react-intl-universal';

export default function BlockBoard ({ poolStats, config, network }) {

  const [ coin ] = useState('erg');

  return (
    <>
    <div className='sites-sec'>
      <section style={{marginBottom: '0px'}} className='etc-btns-sec'>
        <div className='sites-sec-title'>
          {intl.get('BLOCKS_FOUND')}
        </div>
      </section>
      <section className='coin-table-sec'>
        <BlockTable coin={coin} poolStats={poolStats} config={config} network={network} />
      </section>
    </div>
    </>
  )
}
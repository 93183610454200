/* ** 公告系统的通用代码 ** */
import { getAnnounce as getAnnounceReq } from '../../api/ajaxMain';
import store from 'store';
import intl from 'react-intl-universal';
import { Modal, Alert } from 'antd';

/* 调用获取公告的 api, type = 'rvn' | 'nim' | 'main' | 'cpu' */
const getAnnounceWrap = async (type) => {
  try {
    const res = await getAnnounceReq(type);
    return res.data;
  } catch (e) {
  }
}

/* 如果点击知道了, 将存储在 local storage, 下次不会弹出 */
const storeHasRead = (title, type) => {
  /* 统一用中文 title 做验证 */
  store.set(`announcement:${type}`, title);
}

/* 解析 api 拿到的 announcement 数组, 并处理. en 为 true 表示英文, 中文可以不传 */
const getAnnounceFunc = async (en, type) => {
  en = !!en;
  const res = await getAnnounceWrap(type);
  const arr = res?.success ? res.data : [];
  const announceList = [];
  for (const announce of arr) {
    if (announce.type === 'modal') {
      /* 类型为弹窗公告 */
      const readTitle = await store.get(`announcement:${type}`);
      if (announce.title === readTitle) {
        continue;
      }
      const config = {
        title: en ? announce.titleEn : announce.title,
        content: (
          <div 
            style={{padding: `25px 0 0px`}} 
            dangerouslySetInnerHTML={{__html: en ? announce.contentEn : announce.content }} 
          />
        ),
        okText: intl.get('UNDERSTOOD'),
        onOk: () => storeHasRead(en ? announce.titleEn : announce.title, type),
        className: 'announce-modal'
      };
      Modal.info(config);
    } else {
      /* 非弹窗公告都加入要渲染的公告的数组 */
      announceList.push(
        <Alert
          message={`${en ? announce.titleEn : announce.title}: ${en ? announce.contentEn: announce.content}`}
          type={announce.type}
          closable
        />
      );
    }
  }
  return announceList;
}

/* 解析公告数组的包装 */
const getAnnounce = async (en, type, setList) => {
  const list = await getAnnounceFunc(en, type);
  setList(list);
}

/* 显示公告 */
const showAnnounce = (list) => {
  return list.map((item, key) => {
    return <div key={key}>{item}</div>;
  })
}

/* exports */
const announceHelpers = {
  getAnnounce,
  showAnnounce
}

export default announceHelpers;
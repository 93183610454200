import { message } from 'antd';
import { useState, useEffect } from 'react';
import { CommonUtils } from '../../utils/commonUtils';
import {
  getAllWorkers,
  getOfflineWorkers,
  getGraphData,
  getTransactions,
  getGlobalData,
  getPrice,
  getReward
} from '../../api/ajaxMain';
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';
import {
  CopyOutlined,
} from '@ant-design/icons';
import AccountDataBoard from './accountDataBoard';
import TransferBoard from './transferBoard';
import AccountGraphBoard from './accountGraphBoard';
import AccountTableBoard from './accountTableBoard';
import xmrLogo from '../../assets/monero-xmr-logo.svg';
import { withRouter } from 'react-router';
import FreshBtn from '../searchCommon/freshBtn';

function AccountInfo ({ address, history }) {

  const [ global, setGlobal ] = useState({});
  const [ onlineCount, setOnlineCount ] = useState(0);
  const [ onlineList, setOnlineList ] = useState([]);
  const [ offlineCount, setOfflineCount ] = useState(0);
  const [ offlineList, setOfflineList ] = useState([]);
  const [ graphData, setGraphData ] = useState([]);
  const [ avgHashrate, setAvgHashrate ] = useState('');
  const [ avgHashrateRaw, setAvgHashrateRaw ] = useState(0);
  const [ transList, setTransList ] = useState([]);
  const [ freshNum, setFreshNum ] = useState(-1);

  const getGlobalDataFunc = async () => {
    try {
      const globalData = (await getGlobalData(address))?.data;
      return globalData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GLOBAL_DATA'));
    }
  }

  const getPriceFunc = async () => {
    try {
      const response = await getPrice();
      return response?.data?.data || {};
    } catch (e) {
      message.error('获取价格失败');
    }
  }

  const getRewardFunc = async () => {
    try {
      const response = await getReward();
      return response?.data?.data || {};
    } catch (e) {
      message.error('获取收益失败');
    }
  }

  const processGlobal = async () => {
    const globalData = (await getGlobalDataFunc()) || {};
    const priceData = (await getPriceFunc()) || {};
    const rewardData = (await getRewardFunc()) || {};
    setGlobal({
      totalHashes: CommonUtils.fmoney(globalData.totalHashes),
      hash: CommonUtils.toHashrate(globalData.hash2),
      amountDue: (globalData.amtDue / 1e12).toFixed(6),
      amountPaid: (globalData.amtPaid / 1e12).toFixed(6),
      reward: rewardData['XMR']?.reward,
      price: priceData['XMR']?.CNY
    });
  } 

  const getOnlineData =  async () => {
    try {
      const onlineData = (await getAllWorkers(address)).data;
      return onlineData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_MACHINE_LIST'));
    }
  }

  const processOnline = async () => {
    const onlineData = (await getOnlineData()) || {};
    let onlineCount = 0;
    let onlineList = [];
    for (const identifier in onlineData) {
      if (identifier === 'global') {
        continue;
      }
      onlineCount = onlineCount + 1;
      const record = onlineData[identifier];
      onlineList.push({
          name: identifier,
          power: record.hash2,
          power1: Math.floor(record.hash1h),
          power24: Math.floor(record.hash24h),
          update: (Date.now() / 1000) - record.lts
      })
    }
    setOnlineList(onlineList);
    setOnlineCount(onlineCount);
  }

  const getOfflineData =  async () => {
    try {
      const offlineData = (await getOfflineWorkers(address)).data;
      return offlineData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_MACHINE_LIST'));
    }
  }

  const processOffline = async () => {
    const offlineData = (await getOfflineData()) || {};
    let offlineList = [];
    let offlineCount = 0;
    for (const identifier in offlineData) {
      if (identifier === 'global') {
        continue;
      }
      offlineCount = offlineCount + 1;
      const record = offlineData[identifier];
      offlineList.push({
        name: identifier,
        power: record.hash2,
        power1: Math.floor(record.hash1h),
        power24: Math.floor(record.hash24h),
        update: (Date.now() / 1000) - record.lts
      })
    }
    setOfflineCount(offlineCount);
    setOfflineList(offlineList);
  }

  const getGraphDataFunc = async () => {
    try {
      const graphData = (await getGraphData(address)).data;
      return graphData;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_GRAPH'));
    }
  }

  const processGraphData = async () => {
    const graphData = (await getGraphDataFunc()) || [];
    let graphList = [];
    const len = graphData.length;
    let totalValue = 0;
    for (let i = len - 1; i >= 0; i--) {
      const item = graphData[i];
      const DateObj = new Date(item.ts);
      const time = CommonUtils.formatDateShort(DateObj);
      graphList.push({
        time,
        value: item.hs2 / 1e3
      })
      totalValue += item.hs2;
    }
    setGraphData(graphList);
    setAvgHashrate(CommonUtils.toHashrate(totalValue / len));
    setAvgHashrateRaw(totalValue / len);
  }

  const getTransList = async () => {
    try {
      const res = (await getTransactions(address)).data;
      return res;
    } catch (e) {
      message.error(intl.get('FAIL_TO_GET_TX_LIST'));
    }
  }

  const processTransList = async () => {
    const transList = (await getTransList()) || [];
    setTransList(transList)
  }

  const processData = async () => {
    await processGlobal();
    await processOnline();
    await processOffline();
    await processGraphData();
    await processTransList();
    message.success(intl.get('SUCCESS_TO_GET_ACCOUNT_DATA'));
  }

  const refresh = (freshNum) => {
    setFreshNum(freshNum);
  }

  useEffect(() => {
    processData();
    // eslint-disable-next-line 
  }, [freshNum]);

  return (
    <>
    <div className='coin-type-box'>
      <img onClick={() => {history.push('/search/cpu')}} alt='xmr' src={xmrLogo} />
      <div onClick={() => {history.push('/search/cpu')}} className='coin-type-box-title'>
        XMR
      </div>
      <FreshBtn refresh={refresh} />
      
    </div>
    <section className='account-info'>
      <div className='address-info-con'>
        <div className='address-inner-con'>
          { window.innerWidth > 500 ? 
            `${address.slice(0, 25)}...${address.slice(-25)}` :
            `${address.slice(0, 15)}...${address.slice(-15)}` }
          <div className='copy-inner-con'>
            <CopyOutlined
              style={{marginLeft: '10px', cursor: 'pointer'}} 
              onClick={() => {copy(address); message.success(intl.get('COPIED_TO_CLIPBOARD'))}}
            />
          </div>
        </div>
      </div>
    </section>
    <AccountDataBoard 
      global={global} 
      onlineCount={onlineCount} 
      offlineCount={offlineCount} 
      avgHashrate={avgHashrate} 
      avgHashrateRaw={avgHashrateRaw}
    />
    <TransferBoard address={address} />
    <AccountGraphBoard graphData={graphData} />
    <AccountTableBoard 
      onlineCount={onlineCount} 
      offlineCount={offlineCount} 
      onlineList={onlineList} 
      offlineList={offlineList} 
      transList={transList} 
    />
    </>
  )
}

export default withRouter(AccountInfo);